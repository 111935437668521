import classNames from 'classnames';
import { Text } from 'components/common/ComponentLibrary';
import { DumpRow } from 'components/dumps/DumpList/index';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { sortDumps } from 'utilities/dumps/dumps.utils';
import { useDumpsContext } from '../DumpsContext';

interface IListSectionComponent {
  searchTerm: string;
  title: string;
  dumps: DumpInterface[];
}

const DumpList = ({ title, searchTerm, dumps }: IListSectionComponent) => {
  const { groupedAttachments, groupedCollaborators } = useDumpsContext();

  return (
    <div className={classNames('bg-transparent', 'list-container')}>
      <Text color='gray-500' transform='uppercase' size='m' spacing='s' tag='div'>
        {title}
      </Text>
      <ul className={`dump-list`}>
        {sortDumps(dumps).map((dump) => (
          <DumpRow
            key={dump.id}
            dump={dump}
            className='dump-list-row'
            searchTerm={searchTerm}
            dumpCollaborators={groupedCollaborators?.[dump.id]}
            attachments={groupedAttachments?.[dump.id]}
          />
        ))}
      </ul>
    </div>
  );
};

export default DumpList;

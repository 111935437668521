import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import {
  getLocalStorageLastSyncedSetting,
  setLocalStorageLastSyncedSetting,
} from 'models/application/services/LocalStorageService';
import { getAllUnsyncedSettings, markSettingSynced, upsertSetting } from './pouchDb/PouchDbSettingService';
import { SettingInterface } from '../interfaces/SettingInterface';
import { SupabaseSettingService } from './supabase/SupabaseSettingService';

export async function pushUnsyncedSetting(): Promise<boolean> {
  const unsyncedSettings = await getAllUnsyncedSettings();
  if (unsyncedSettings && unsyncedSettings.length > 0) {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    await Promise.all(
      unsyncedSettings.map(async (unsyncedSetting) => {
        try {
          const asSetting = {
            ...unsyncedSetting,
          };
          delete asSetting._id;
          delete asSetting._rev;
          delete asSetting.is_synced;

          const supabaseSettingService = new SupabaseSettingService(supabase);
          const result = await supabaseSettingService.updateSettings(unsyncedSetting.id, asSetting);
          if (!result) {
            return;
          }

          markSettingSynced(unsyncedSetting.id, { is_synced: true });
        } catch (e) {
          captureMessage(`pushUnsyncedSettings: userId[${currentProfileId}], settingId[${unsyncedSetting.id}].`);
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestSetting(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseSettingService = new SupabaseSettingService(supabase);
    const settings = await supabaseSettingService.getSettings(getLocalStorageLastSyncedSetting());
    if (!settings) {
      return false;
    }

    await Promise.all(
      settings.map(async (_setting) => {
        const setting: SettingInterface = {
          id: _setting.id,
          theme: _setting.theme,
          density: _setting.density,
          created_at: _setting.created_at,
          updated_at: _setting.updated_at,
          is_synced: true,
        };

        await upsertSetting(setting);
        setLocalStorageLastSyncedSetting(setting.updated_at);
      }),
    );

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

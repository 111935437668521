import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button, Hyperlink, Text } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import supabase from 'store/supabase/supabase';
import { useNavigate } from 'react-router-dom';
import { getUrlPrivacy, getUrlTerms, getUrlVerifyEmail } from 'utilities/urls.utils';
import {
  FIRSTNAME_MAX_LENGTH,
  FIRSTNAME_MIN_LENGTH,
  LASTNAME_MAX_LENGTH,
  LASTNAME_MIN_LENGTH,
  MINIMUM_PW_LENGTH,
} from 'constants/profiles.constants';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED } from 'constants/supabase.constants';
import { SIGNUPS_INVITE_ONLY } from 'constants/application.constants';
import { useTranslation } from 'react-i18next';

const SignUpForm = ({ email }: { email?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translations');

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .min(FIRSTNAME_MIN_LENGTH, t('signUp.validation.firstnameMin', { min: FIRSTNAME_MIN_LENGTH }))
      .max(FIRSTNAME_MAX_LENGTH, t('signUp.validation.firstnameMax', { max: FIRSTNAME_MAX_LENGTH }))
      .required(t('signUp.validation.firstnameRequired')),
    lastname: yup
      .string()
      .trim()
      .min(LASTNAME_MIN_LENGTH, t('signUp.validation.lastnameMin', { min: LASTNAME_MIN_LENGTH }))
      .max(LASTNAME_MAX_LENGTH, t('signUp.validation.lastnameMax', { max: LASTNAME_MAX_LENGTH }))
      .required(t('signUp.validation.lastnameRequired')),
    email: yup.string().email(t('signUp.validation.emailValid')).trim().required(t('signUp.validation.emailRequired')),
    password: yup
      .string()
      .trim()
      .min(MINIMUM_PW_LENGTH, t('signUp.validation.passwordMin', { min: MINIMUM_PW_LENGTH }))
      .required(t('signUp.validation.passwordRequired')),
  });

  return (
    <Formik
      onSubmit={async ({ email, password, firstname, lastname }) => {
        const { error } = await supabase.auth.signUp({
          email: email.toLowerCase(),
          password,
          options: {
            data: {
              full_name: `${firstname} ${lastname}`,
            },
          },
        });
        if (error) {
          notifyAboutError(
            error,
            true,
            error.message === SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED ? SIGNUPS_INVITE_ONLY : error.message,
          );
          return;
        }

        trackEvent(TRACKING_EVENT_ENUM.SIGN_UP, {
          provider: 'email',
        });

        navigate(getUrlVerifyEmail(email));
      }}
      initialValues={{ email: email || '', password: '', firstname: '', lastname: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-5 w-full'>
            <div className='flex md:space-x-5 space-y-5 md:space-y-0 flex-col md:flex-row'>
              <FormikInputField
                name='firstname'
                placeholder={t('signUp.firstnamePlaceholder')}
                type='text'
                autoFocus={true}
              />
              <FormikInputField name='lastname' placeholder={t('signUp.lastnamePlaceholder')} type='text' />
            </div>
            <FormikInputField type='email' name='email' placeholder={t('signUp.emailPlaceholder')} />
            <FormikInputField type='password' name='password' placeholder={t('signUp.passwordPlaceholder')} />
            <div className='mt-4'>
              <Text size='s' tag='div' color='gray-500'>
                {t('signUp.termsText')}{' '}
                <Hyperlink color='default' href={getUrlTerms()}>
                  {t('signUp.termsLink')}
                </Hyperlink>{' '}
                {t('signUp.and')}{' '}
                <Hyperlink color='default' href={getUrlPrivacy()}>
                  {t('signUp.privacyLink')}
                </Hyperlink>
                .
              </Text>
            </div>
            <div className='flex justify-end'>
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''} width='full'>
                {t('signUp.submitButton')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;

import PouchDB from 'pouchdb';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';

const dumpCollaboratorsDB = new PouchDB('dump_collaborators', { auto_compaction: true });

const activeChangeFeeds: Record<
  string,
  {
    changes: PouchDB.Core.Changes<any>;
    listeners: ((collaborators: DumpCollaboratorInterface[]) => void)[];
  }
> = {};

export async function createDumpCollaborator(dumpId: string, collaboratorId: string) {
  const newDumpCollaborator: DumpCollaboratorInterface = {
    _id: `${dumpId}_${collaboratorId}`,
    dump_id: dumpId,
    collaborator_id: collaboratorId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    _deleted: false,
    is_synced: false,
    is_to_be_deleted: false,
  };

  return await dumpCollaboratorsDB.put(newDumpCollaborator);
}

export async function getAllUnsyncedDumpCollaborators(): Promise<DumpCollaboratorInterface[] | null> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        is_synced: false,
      },
    });
    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => doc as DumpCollaboratorInterface);
  } catch (e) {
    return null;
  }
}

export async function markDumpCollaboratorSynced(
  dumpId: string,
  collaboratorId: string,
  additionalData: Partial<DumpCollaboratorInterface>,
) {
  const existing = await getDumpCollaborator(dumpId, collaboratorId);
  if (existing) {
    return await dumpCollaboratorsDB.put({
      ...existing,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function getDumpCollaborator(
  dumpId: string,
  collaboratorId: string,
): Promise<DumpCollaboratorInterface | null> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        dump_id: dumpId,
        collaborator_id: collaboratorId,
      },
    });
    if (!result.docs.length) {
      return null;
    }
    return result.docs[0] as DumpCollaboratorInterface;
  } catch (e) {
    return null;
  }
}

export async function getDumpCollaboratorByDumpIdAndUserId(
  dumpId: string,
  userId: string,
): Promise<DumpCollaboratorInterface | null> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        dump_id: dumpId,
        user_id: userId,
      },
    });
    if (!result.docs.length) {
      return null;
    }
    return result.docs[0] as DumpCollaboratorInterface;
  } catch (e) {
    return null;
  }
}

export async function upsertDumpCollaborator(collaborator: Partial<DumpCollaboratorInterface>) {
  const existing = await getDumpCollaborator(collaborator.dump_id!, collaborator.collaborator_id!);
  if (existing) {
    return await dumpCollaboratorsDB.put({
      ...existing,
      ...collaborator,
    });
  } else {
    if (collaborator._deleted) {
      return null;
    }
    return await dumpCollaboratorsDB.put({
      _id: `${collaborator.dump_id}_${collaborator.collaborator_id}`,
      ...collaborator,
    });
  }
}

export async function deleteDumpCollaborator(dumpId: string, collaboratorId: string) {
  try {
    const doc = await getDumpCollaborator(dumpId, collaboratorId);
    if (!doc) {
      return Promise.reject('No dump collaborator found');
    }

    return await dumpCollaboratorsDB.put({
      ...doc,
      is_synced: false,
      is_to_be_deleted: true,
      updated_at: getCurrentUTCDateFormatted(),
    });
  } catch (e: any) {
    if (e.code === 'CONFLICT') {
      return await deleteDumpCollaborator(dumpId, collaboratorId);
    }
    return Promise.reject(e);
  }
}

export async function getDumpCollaboratorsByDumpId(dumpId: string): Promise<DumpCollaboratorInterface[]> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        dump_id: dumpId,
      },
    });
    return result.docs.map((doc) => doc as DumpCollaboratorInterface);
  } catch (e) {
    return Promise.reject(e);
  }
}

export function listenToDumpDumpCollaborators(dumpId: string): Promise<DumpCollaboratorInterface[]> & {
  onChange: (callback: (collaborators: DumpCollaboratorInterface[]) => void) => void;
  cleanup: (callback: (collaborators: DumpCollaboratorInterface[]) => void) => void;
} {
  // Initial fetch of collaborators
  const promise = getDumpCollaboratorsByDumpId(dumpId);

  // Create or reuse changes feed for this dumpId
  if (!activeChangeFeeds[dumpId]) {
    activeChangeFeeds[dumpId] = {
      changes: dumpCollaboratorsDB
        .changes({
          since: 'now',
          live: true,
          include_docs: true,
        })
        .on('change', async (change) => {
          // Only notify if the changed document is related to our dumpId
          if ((change.doc as any as DumpCollaboratorInterface)?.dump_id === dumpId) {
            const collaborators = await getDumpCollaboratorsByDumpId(dumpId);
            activeChangeFeeds[dumpId].listeners.forEach((listener) => listener(collaborators));
          }
        }),
      listeners: [],
    };
  }

  // Return a promise that resolves with initial data, plus methods to manage the subscription
  return Object.assign(promise, {
    onChange: (callback: (collaborators: DumpCollaboratorInterface[]) => void) => {
      activeChangeFeeds[dumpId].listeners.push(callback);
    },
    cleanup: (callback: (collaborators: DumpCollaboratorInterface[]) => void) => {
      const feedInfo = activeChangeFeeds[dumpId];
      if (feedInfo) {
        feedInfo.listeners = feedInfo.listeners.filter((l) => l !== callback);
        if (feedInfo.listeners.length === 0) {
          feedInfo.changes.cancel();
          delete activeChangeFeeds[dumpId];
        }
      }
    },
  });
}

export async function getAllDumpCollaborators(): Promise<DumpCollaboratorInterface[]> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        _deleted: { $ne: true },
      },
      limit: 1500, // @todo-phil fix
    });
    return result.docs.map((doc) => {
      const { ...dumpCollaboratorData } = doc;
      return dumpCollaboratorData as DumpCollaboratorInterface;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export function listenToDumpCollaborators(): Promise<DumpCollaboratorInterface[]> & {
  onChange: (callback: (dumpCollaborators: DumpCollaboratorInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getAllDumpCollaborators();
  const listeners: ((dumpCollaborators: DumpCollaboratorInterface[]) => void)[] = [];

  const changes = dumpCollaboratorsDB
    .changes({
      since: 0,
      live: true,
      include_docs: true,
    })
    .on('change', async () => {
      const dumpCollaborators = await getAllDumpCollaborators();
      listeners.forEach((listener) => listener(dumpCollaborators));
    });

  return Object.assign(promise, {
    onChange: (callback: (dumpCollaborators: DumpCollaboratorInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export async function clearDumpCollaboratorsDB(): Promise<void> {
  await dumpCollaboratorsDB.destroy();
}

export async function getDumpCollaboratorsByUserId(userId: string): Promise<DumpCollaboratorInterface[]> {
  try {
    const result = await dumpCollaboratorsDB.find({
      selector: {
        user_id: userId,
        _deleted: { $ne: true },
      },
    });
    return result.docs.map((doc) => doc as DumpCollaboratorInterface);
  } catch (e) {
    return Promise.reject(e);
  }
}

import { getUrlHome } from 'utilities/urls.utils';
import { Hyperlink, Heading } from 'components/common/ComponentLibrary';
import Footer from 'components/application/Footer';
import { DumpItIcon } from 'components/common/SpecialIcons';
import { SignUp } from 'components/application/SignUp';

const CollaboratorInvitationAuth = () => (
  <div className='m-auto flex min-h-screen flex-col'>
    <div className='flex items-center text-center justify-center mt-5 mb-10'>
      <Hyperlink href={getUrlHome()}>
        <DumpItIcon className='w-24' />
      </Hyperlink>
    </div>
    <Heading size='xxl' textAlign='center'>
      You are invited to join Dump it!
    </Heading>
    <div className='m-auto mx-5 md:mx-auto max-w-2xl p-5 md:p-10 flex-1 mt-10 mb-20 backdrop-blur-md rounded-xl border border-gray-300'>
      <SignUp showLoginAction={true} />
    </div>
    <footer className='m-auto w-full max-w-7xl'>
      <Footer />
    </footer>
  </div>
);

export default CollaboratorInvitationAuth;

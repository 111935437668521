import { SupabaseClient } from '@supabase/supabase-js';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { v4 as uuidv4 } from 'uuid';

export const TABLE_NAME_COMMENTS = 'comments';

export class SupabaseCommentService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async createComment(
    text: string,
    dumpId: string,
    userId: string,
    commentRefId?: string | null,
  ): Promise<CommentInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_COMMENTS)
      .insert({
        id: uuidv4().toString(),
        text,
        dump_id: dumpId,
        user_id: userId,
        comment_ref: commentRefId,
      })
      .select('*')
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async upsert(comment: CommentInterface): Promise<CommentInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_COMMENTS)
      .upsert({
        ...comment,
      })
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async getAll(since?: string): Promise<CommentInterface[]> {
    let supabaseQuery = this.supabaseClient.from(TABLE_NAME_COMMENTS).select('*');

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }

  async getByDumpId(dumpId: string, page = 1, limit?: number): Promise<CommentInterface[]> {
    let supabaseQuery = this.supabaseClient.from(TABLE_NAME_COMMENTS).select('*').eq('dump_id', dumpId);

    if (limit) {
      const fromIndex = (page - 1) * limit;
      const toIndex = fromIndex + limit - 1;
      supabaseQuery = supabaseQuery.range(fromIndex, toIndex);
    }

    supabaseQuery = supabaseQuery.order('created_at', { ascending: false });

    const { data, error } = await supabaseQuery;
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async getCommentCount(dumpId: string): Promise<number> {
    const { count, error } = await this.supabaseClient
      .from(TABLE_NAME_COMMENTS)
      .select('*', { count: 'exact', head: true })
      .eq('dump_id', dumpId);

    if (error) {
      throw new Error(error.message);
    }

    return count ?? 0;
  }

  async updateComment(commentId: string, text: string): Promise<CommentInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_COMMENTS)
      .update({ text, updated_at: getCurrentUTCDateFormatted() })
      .eq('id', commentId)
      .select('*')
      .single();

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async deleteComment(commentId: string): Promise<CommentInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_COMMENTS)
      .update({ _deleted: true, updated_at: getCurrentUTCDateFormatted() })
      .eq('id', commentId)
      .select('*')
      .single();

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
}

import { AudioPreview, ImagePreview, GenericFilePreview } from '../Attachments';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { FieldArray, useField } from 'formik';
import SupabaseAttachment from 'components/common/SupabaseAttachment';
import { isFilePathAudio, isFilePathImage, isFilePathGeneric } from 'utilities/storage.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import SupabaseImageAttachment from 'components/common/SupabaseImageAttachment';
import { getAttachmentFilePath } from 'models/attachments/services/AttachmentService';

const DumpEditAttachments = () => {
  const [field] = useField('attachments');
  if (!field.value || field.value.length === 0) {
    return null;
  }

  return (
    <FieldArray
      name='attachments'
      render={(arrayHelpers) => (
        <div className='flex space-x-2'>
          {field.value &&
            field.value.length > 0 &&
            field.value.map((attachment: AttachmentInterface & IDraftAttachment, index: number) => {
              if (
                (attachment.file || attachment.file_data) &&
                isFilePathImage(attachment.file?.name || attachment.filename)
              ) {
                return (
                  <ImagePreview
                    key={attachment.file?.name || attachment.filename}
                    src={attachment.src || attachment.file_data}
                    previewSrc={attachment.src || attachment.file_data}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                );
              } else if (
                (attachment.file || attachment.file_data) &&
                isFilePathGeneric(attachment.file?.name || attachment.filename)
              ) {
                return (
                  <GenericFilePreview
                    key={attachment.src || attachment.filename}
                    filename={attachment.filename || attachment?.file?.name}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                );
              } else if (
                (attachment.file || attachment.file_data) &&
                isFilePathAudio(attachment.file?.name || attachment.filename)
              ) {
                return (
                  <AudioPreview
                    key={attachment.file?.name || attachment.filename}
                    src={attachment.src || attachment.file_data}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                );
              } else if (isFilePathImage(attachment.filename)) {
                return (
                  <SupabaseImageAttachment
                    key={attachment.id}
                    path={getAttachmentFilePath(
                      attachment.user_id,
                      attachment.dump_id,
                      attachment.id,
                      attachment.filename,
                    )}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ previewSrc, src }) => (
                      <ImagePreview
                        src={src || previewSrc}
                        previewSrc={previewSrc || src}
                        onRemove={() => arrayHelpers.remove(index)}
                      />
                    )}
                  </SupabaseImageAttachment>
                );
              } else if (isFilePathAudio(attachment.filename)) {
                return (
                  <SupabaseAttachment
                    key={attachment.id}
                    path={getAttachmentFilePath(
                      attachment.user_id,
                      attachment.dump_id,
                      attachment.id,
                      attachment.filename,
                    )}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ src }) => (
                      <AudioPreview
                        src={src}
                        onRemove={() => arrayHelpers.remove(index)}
                        duration={attachment.duration}
                      />
                    )}
                  </SupabaseAttachment>
                );
              } else if (isFilePathGeneric(attachment.filename)) {
                return (
                  <SupabaseAttachment
                    key={attachment.id}
                    path={getAttachmentFilePath(
                      attachment.user_id,
                      attachment.dump_id,
                      attachment.id,
                      attachment.filename,
                    )}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ src }) => (
                      <GenericFilePreview
                        filename={attachment.filename}
                        src={src}
                        onRemove={() => arrayHelpers.remove(index)}
                      />
                    )}
                  </SupabaseAttachment>
                );
              }
            })}
        </div>
      )}
    />
  );
};

export default DumpEditAttachments;

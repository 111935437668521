import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { v4 as uuidv4 } from 'uuid';

PouchDB.plugin(PouchDBFind);

const collaboratorsDB = new PouchDB('collaborators', { auto_compaction: true });

collaboratorsDB.createIndex({
  index: {
    fields: ['id', 'user_id', 'is_synced'],
  },
});

export async function inviteCollaborator(email: string, ownerUserId: string) {
  const newCollaborator: CollaboratorInterface = {
    id: uuidv4().toString(),
    email,
    owner_user_id: ownerUserId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    is_synced: false,
    is_to_be_deleted: false,
  };

  await collaboratorsDB.put({
    _id: newCollaborator.id,
    ...newCollaborator,
  });

  return newCollaborator;
}

export async function markCollaboratorSynced(collaboratorId: string, additionalData: Partial<CollaboratorInterface>) {
  const existing = await getCollaboratorById(collaboratorId);
  if (existing) {
    return await collaboratorsDB.put({
      ...existing,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function getCollaboratorById(collaboratorId: string): Promise<CollaboratorInterface | null> {
  try {
    const collaborator = await collaboratorsDB.get(collaboratorId);
    const { ...collaboratorData } = collaborator;
    return collaboratorData as CollaboratorInterface;
  } catch (e) {
    return null;
  }
}

export async function getAllCollaborators(): Promise<CollaboratorInterface[]> {
  try {
    const result = await collaboratorsDB.find({
      selector: {
        id: { $exists: true },
      },
      limit: 1500, // @todo-phil fix
    });
    return result.docs.map((doc) => {
      const { ...collaboratorData } = doc;
      return collaboratorData as CollaboratorInterface;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllUnsyncedCollaborators(): Promise<CollaboratorInterface[] | null> {
  try {
    const result = await collaboratorsDB.find({
      selector: {
        is_synced: false,
      },
    });

    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => {
      const { ...collaboratorData } = doc;
      return collaboratorData as CollaboratorInterface;
    });
  } catch (e) {
    return null;
  }
}

export async function upsertCollaborator(collaborator: Partial<CollaboratorInterface>) {
  if (!collaborator.id) {
    throw new Error('Collaborator ID is required');
  }

  try {
    const existing = await getCollaboratorById(collaborator.id);
    if (existing) {
      // Remove created_at from update data
      const { created_at, ...updateData } = collaborator;
      return await collaboratorsDB.put({
        ...existing,
        ...updateData,
      });
    } else {
      return await collaboratorsDB.put({
        _id: collaborator.id,
        ...collaborator,
      });
    }
  } catch (error: any) {
    if (error.status === 409 || error.name === 'conflict') {
      return await upsertCollaborator(collaborator);
    }
    throw error;
  }
}

export async function getByUserId(userId: string): Promise<CollaboratorInterface | null> {
  try {
    const result = await collaboratorsDB.find({
      selector: {
        user_id: userId,
      },
      limit: 1,
    });

    if (!result.docs.length) {
      return null;
    }

    const { ...collaboratorData } = result.docs[0];
    return collaboratorData as CollaboratorInterface;
  } catch (e) {
    return null;
  }
}

export async function clearCollaboratorsDB(): Promise<void> {
  await collaboratorsDB.destroy();
}

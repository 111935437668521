import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import {
  getLocalStorageLastSyncedProfile,
  setLocalStorageLastSyncedProfile,
} from 'models/application/services/LocalStorageService';
import { getAllUnsyncedProfiles, markProfileSynced, upsertProfile } from './pouchDb/PouchDbProfileService';
import { SupabaseProfileService } from './supabase/SupabaseProfileService';
import { ProfileInterface } from '../interfaces/ProfileInterface';
import { SupabaseSettingService } from 'models/settings/services/supabase/SupabaseSettingService';

export async function pushUnsyncedProfile(): Promise<boolean> {
  const unsyncedProfiles = await getAllUnsyncedProfiles();
  if (unsyncedProfiles && unsyncedProfiles.length > 0) {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    await Promise.all(
      unsyncedProfiles.map(async (unsyncedProfile) => {
        try {
          const asProfile = {
            ...unsyncedProfile,
          };
          delete asProfile._id;
          delete asProfile._rev;
          delete asProfile.is_synced;

          const supabaseProfileService = new SupabaseProfileService(supabase);
          const result = await supabaseProfileService.updateProfile(unsyncedProfile.id, asProfile);
          if (!result) {
            return;
          }

          markProfileSynced(unsyncedProfile.id, { is_synced: true });
        } catch (e) {
          captureMessage(`pushUnsyncedProfiles: userId[${currentProfileId}], profileId[${unsyncedProfile.id}].`);
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestProfile(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseProfileService = new SupabaseProfileService(supabase);
    const profiles = await supabaseProfileService.getProfiles(getLocalStorageLastSyncedProfile());
    if (!profiles) {
      return false;
    }

    await Promise.all(
      profiles.map(async (_profile) => {
        const profile: ProfileInterface = {
          id: _profile.id,
          first_name: _profile.first_name,
          last_name: _profile.last_name,
          image_path: _profile.image_path,
          phone: _profile.phone,
          email: _profile.email,
          created_at: _profile.created_at,
          updated_at: _profile.updated_at,
          is_synced: true,
          preferred_language: _profile.preferred_language || 'en-US',
        };

        await upsertProfile(profile);
        setLocalStorageLastSyncedProfile(profile.updated_at);
      }),
    );

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

import { SupabaseClient } from '@supabase/supabase-js';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';

const TABLE_NAME_DUMP_COLLABORATORS = 'dump_collaborators';

export class SupabaseDumpCollaboratorService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getAll(since?: string, page = 1): Promise<DumpCollaboratorInterface[]> {
    const fromIndex = (page - 1) * SUPABASE_MAX_PER_PAGE;
    const toIndex = fromIndex + SUPABASE_MAX_PER_PAGE - 1;

    let supabaseQuery = this.supabaseClient.from(TABLE_NAME_DUMP_COLLABORATORS).select();

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.range(fromIndex, toIndex).order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }

  async upsert(dump: DumpCollaboratorInterface): Promise<DumpCollaboratorInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_DUMP_COLLABORATORS)
      .upsert({
        ...dump,
      })
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async update(
    dumpId: string,
    collaboratorId: string,
    dump: DumpCollaboratorInterface,
  ): Promise<DumpCollaboratorInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_DUMP_COLLABORATORS)
      .update({
        ...dump,
      })
      .match({ dump_id: dumpId, collaborator_id: collaboratorId })
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
}

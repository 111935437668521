import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Avatar, Heading, Text } from 'components/common/ComponentLibrary';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { getInitials } from 'utilities/common/strings.utils';
import { useEffect, useState } from 'react';
import { getByUserId } from 'models/collaborators/services/pouchDb/PouchDbCollaboratorService';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { Color } from 'utilities/common/colors.utils';
import { getByDumpIds } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getDumpCollaboratorsByUserId } from 'models/collaborators/services/pouchDb/PouchDbDumpCollaboratorService';
import { getDumpsByUserId } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { DumpRow } from 'components/dumps/DumpList';
import classNames from 'classnames';
import { getLatestCommentByDumpId } from 'models/comments/services/pouchDb/PouchDbCommentService';

const CommentProfileDetailsModal = ({ profile, onDismiss }: { profile: ProfileInterface; onDismiss: () => void }) => {
  const [collaborator, setCollaborator] = useState<CollaboratorInterface | null>(null);
  const [userDumps, setUserDumps] = useState<DumpInterface[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      // @todo-phil clean up
      if (profile?.user_id) {
        // Fetch collaborator data
        const collaboratorData = await getByUserId(profile.user_id);
        setCollaborator(collaboratorData);

        // Fetch dump collaborators and associated dumps
        const dumpCollaborators = await getDumpCollaboratorsByUserId(profile.user_id);
        const collaboratorDumpIds = dumpCollaborators.map((dc) => dc.dump_id);

        // Fetch dumps owned by the user
        const ownedDumps = await getDumpsByUserId(profile.user_id);
        const ownedDumpIds = ownedDumps.map((dump) => dump.id);

        // Combine and deduplicate dump IDs
        const uniqueDumpIds = [...new Set([...collaboratorDumpIds, ...ownedDumpIds])];
        const dumps = await getByDumpIds(uniqueDumpIds);

        // Get latest comment dates for all dumps @todo-phil can we move this to the dump service?
        const dumpsWithDates = await Promise.all(
          dumps.map(async (dump) => {
            const latestComment = await getLatestCommentByDumpId(dump.id);
            return {
              ...dump,
              latest_comment_at: latestComment?.created_at || undefined,
            };
          }),
        );

        // Sort dumps by most recent date
        const sortedDumps = dumpsWithDates.sort((a, b) => {
          const getDumpDate = (dump: DumpInterface) => {
            const dates = [dump.sort_date, dump.latest_comment_at].filter(Boolean);
            return Math.max(...dates.map((date) => (date ? new Date(date).getTime() : 0)));
          };
          return getDumpDate(b) - getDumpDate(a);
        });

        setUserDumps(sortedDumps);
      }
    };
    fetchData();
  }, [profile]);

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6 min-w-[500px]'>
        <div className='flex flex-col items-center justify-center gap-4'>
          <Avatar
            src={profile?.image_path ? getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS) : null}
            alt={profile?.first_name || 'Collaborator'}
            size='xxl'
            initials={
              profile?.first_name && profile?.last_name
                ? getInitials(`${profile.first_name} ${profile.last_name}`)
                : undefined
            }
            backgroundColor={Color.flatModernColorFrom(profile?.user_id || '')}
          />
          <div className='flex flex-col items-center justify-center gap-1'>
            {profile?.first_name && (
              <Heading size='xl'>
                {profile?.first_name} {profile?.last_name}
              </Heading>
            )}
            {collaborator?.email && (
              <Text size='m' color='gray-400'>
                {collaborator?.email}
              </Text>
            )}
          </div>

          {userDumps.length > 0 && (
            <div className='w-full mt-10'>
              <div className={classNames('bg-transparent', 'list-container')}>
                <Text color='gray-500' transform='uppercase' size='m' spacing='s' tag='div'>
                  Common Dumps ({userDumps.length})
                </Text>
                <ul className='dump-list overflow-y-auto max-h-64 pr-1 dumpit-scrollbar'>
                  {userDumps.map((dump) => (
                    <DumpRow key={dump.id} dump={dump} className='dump-list-row' searchTerm='' />
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </BackdropComponent>
  );
};

export default CommentProfileDetailsModal;

import { Avatar } from 'components/common/ComponentLibrary';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getPublicUrl } from 'utilities/storage.utils';
import { formatUTCDateToLocal } from 'utilities/date.utils';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import CommentProfileDetailsModal from './CommentProfileDetailsModal';
import { useState, forwardRef, useEffect } from 'react';
import CommentContextMenu from './CommentContextMenu';
import { useContextMenu } from 'use-context-menu';
import CommentItemEdit from './CommentItemEdit';
import CommentItemReplyPreview from './CommentItemReplyPreview';
import { getInitials } from 'utilities/common/strings.utils';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Color } from 'utilities/common/colors.utils';
import clsx from 'clsx';

interface CommentItemProps {
  comment: CommentInterface;
  replyToComment?: CommentInterface;
  currentProfile: ProfileInterface;
  onReply: (commentId: string) => void;
  onReplyClick?: (commentId: string) => void;
}

const CommentItem = forwardRef<HTMLDivElement, CommentItemProps>(
  ({ comment, replyToComment, currentProfile, onReply, onReplyClick }, ref) => {
    const isOwnComment = comment.user_id === currentProfile?.id;

    const [commentText, setCommentText] = useState(comment.text);
    const [isVisible, setIsVisible] = useState(!comment._deleted);
    const [showProfileDetailsModal, setShowProfileDetailsModal] = useState(false);
    const [showEditComment, setShowEditComment] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);

    // Add effect to handle highlight animation
    useEffect(() => {
      if (isHighlighted) {
        const timer = setTimeout(() => {
          setIsHighlighted(false);
        }, 1500); // Duration of highlight effect

        return () => clearTimeout(timer);
      }
    }, [isHighlighted]);

    const { contextMenu, onContextMenu } = useContextMenu(
      <CommentContextMenu
        comment={comment}
        isOwnComment={isOwnComment}
        onEdit={() => setShowEditComment(true)}
        onDelete={() => setIsVisible(false)}
        onReply={onReply}
      />,
      {
        alignTo: 'left',
      },
    );

    if (showEditComment) {
      return (
        <CommentItemEdit
          comment={comment}
          onCancel={() => setShowEditComment(false)}
          onUpdate={(_text: string) => {
            setCommentText(_text);
            setShowEditComment(false);
          }}
        />
      );
    }

    if (!isVisible) {
      return null;
    }

    return (
      <div
        className={`group flex gap-2 pr-2 ${isOwnComment ? 'flex-row-reverse ml-auto' : ''} 
          ${isHighlighted ? 'animate-highlight' : ''} transition-colors duration-500`}
        onContextMenu={onContextMenu}
        data-comment-id={comment.id}
      >
        {contextMenu}
        {!isOwnComment && (
          <div
            className={comment.profile ? 'cursor-pointer' : ''}
            onClick={comment.profile ? () => setShowProfileDetailsModal(true) : undefined}
          >
            <Avatar
              src={
                comment.profile?.image_path
                  ? getPublicUrl(comment.profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                  : null
              }
              alt={comment.profile?.first_name || 'Dumper'}
              size='s'
              initials={
                comment.profile?.first_name && comment.profile?.last_name
                  ? getInitials(`${comment.profile.first_name} ${comment.profile.last_name}`)
                  : undefined
              }
              backgroundColor={Color.flatModernColorFrom(comment.profile?.user_id || '')}
            />
          </div>
        )}
        <div
          className={clsx(
            'rounded-xl p-2 max-w-[280px] ',
            commentText.length >= 40 && 'max-w-[80%]',
            isOwnComment ? 'bg-cyan/50 text-primary-foreground' : 'bg-white dark:bg-dark/30',
          )}
          ref={ref}
        >
          <div className='flex items-start gap-2'>
            <div className='flex-1'>
              {comment.profile?.first_name && !isOwnComment && (
                <div
                  className={`flex items-center justify-between mb-1 ${comment.profile ? 'cursor-pointer' : ''}`}
                  onClick={comment.profile ? () => setShowProfileDetailsModal(true) : undefined}
                >
                  <p className='text-sm font-semibold' style={{ color: Color.flatModernColorFrom(comment.user_id) }}>
                    {comment.profile?.first_name} {comment.profile?.last_name}
                  </p>
                </div>
              )}
              {comment.comment_ref && (
                <div className='pt-1' onClick={() => comment.comment_ref && onReplyClick?.(comment.comment_ref)}>
                  <CommentItemReplyPreview comment={replyToComment} />
                </div>
              )}
              <div
                className={clsx(
                  'flex',
                  commentText.length < 20 ? 'flex-row items-end justify-between gap-2' : 'flex-col min-w-18',
                )}
              >
                <p className='text-sm text-dark dark:text-white [word-break:break-word] [overflow-wrap:break-word] whitespace-pre-wrap'>
                  {commentText.split(/(\s+)/).map((part, index) => {
                    if (part.match(/^(https?:\/\/[^\s]+)/)) {
                      return (
                        <a
                          key={index}
                          href={part}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-blue-500 underline'
                        >
                          {part}
                        </a>
                      );
                    }
                    return part;
                  })}
                </p>
                <p
                  title={formatUTCDateToLocal(comment.created_at!, 'do MMMM, yyyy, HH:mm')}
                  className={clsx(
                    'text-xs -mb-1',
                    isOwnComment ? 'text-gray-400 dark:text-gray-300' : 'text-gray-400',
                    commentText.length >= 40 && 'w-full',
                    commentText.length >= 10 && 'text-right',
                  )}
                >
                  {formatUTCDateToLocal(comment.created_at!, 'HH:mm')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity'>
          <EllipsisVerticalIcon className='size-5 text-gray-400 mt-0 cursor-pointer' onClick={onContextMenu} />
        </div>
        {showProfileDetailsModal && (
          <CommentProfileDetailsModal profile={comment.profile!} onDismiss={() => setShowProfileDetailsModal(false)} />
        )}
      </div>
    );
  },
);

CommentItem.displayName = 'CommentItem';

export default CommentItem;

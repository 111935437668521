import { useEffect, useState } from 'react';
import { sortTags } from 'utilities/tags/tags.utils';
import { captureException } from '@sentry/react';
import { listenToTags } from 'models/tags/services/pouchDb/PouchDbTagService';
import { TagInterface } from 'models/dumps/interfaces/TagInterface';

const useTags = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);

    const tagsPromise = listenToTags();

    // Handle initial load
    tagsPromise
      .then((allTags) => {
        if (allTags) {
          const grouped = allTags.reduce((accumulator: { [key: string]: number }, obj: TagInterface) => {
            accumulator[obj.name] = (accumulator[obj.name] || 0) + 1;
            return accumulator;
          }, {});

          const sortedTags = sortTags(grouped);
          setTags(sortedTags);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        captureException(error);
        setIsLoading(false);
      });

    // Handle subsequent changes
    tagsPromise.onChange((allTags) => {
      const grouped = allTags.reduce((accumulator: { [key: string]: number }, obj: TagInterface) => {
        accumulator[obj.name] = (accumulator[obj.name] || 0) + 1;
        return accumulator;
      }, {});

      const sortedTags = sortTags(grouped);
      setTags(sortedTags);
    });

    return () => {
      tagsPromise.cleanup();
      setIsLoading(false);
    };
  }, []);

  return {
    tags,
    isLoading,
  };
};

export default useTags;

import { useEffect, useState } from 'react';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { listenToAllAttachments } from 'models/attachments/services/pouchDb/PouchDbAttachmentService';
import { captureException } from '@sentry/react';

const useAttachmentsListener = () => {
  const [attachments, setAttachments] = useState<AttachmentInterface[]>([]);
  const [groupedAttachments, setGroupedAttachments] = useState<Record<string, AttachmentInterface[]>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  const groupAttachmentsByDumpId = (attachments: AttachmentInterface[]) => {
    return attachments.reduce(
      (acc, attachment) => {
        if (!acc[attachment.dump_id]) {
          acc[attachment.dump_id] = [];
        }
        acc[attachment.dump_id].push(attachment);
        return acc;
      },
      {} as Record<string, AttachmentInterface[]>,
    );
  };

  useEffect(() => {
    let isMounted = true;
    let cleanup: (() => void) | undefined;

    setIsLoading(true);

    const setupAttachmentsListener = async () => {
      try {
        // Get the initial attachments and set up the listener
        const attachmentsListener = listenToAllAttachments();

        // Handle the initial promise resolution
        const initialAttachments = await attachmentsListener;

        if (isMounted) {
          const filteredAttachments = initialAttachments.filter(
            (attachment: AttachmentInterface) => attachment.is_to_be_deleted !== true,
          );
          const sortedAttachments = filteredAttachments.sort((a, b) => a.created_at.localeCompare(b.created_at));

          setAttachments(sortedAttachments);
          setGroupedAttachments(groupAttachmentsByDumpId(sortedAttachments));
          setIsLoading(false);

          // Set up the onChange handler to update state when attachments change
          attachmentsListener.onChange((updatedAttachments) => {
            if (isMounted) {
              const filteredAttachments = updatedAttachments.filter(
                (attachment: AttachmentInterface) => attachment.is_to_be_deleted !== true,
              );
              const sortedAttachments = filteredAttachments.sort((a, b) => a.created_at.localeCompare(b.created_at));

              setAttachments(sortedAttachments);
              setGroupedAttachments(groupAttachmentsByDumpId(sortedAttachments));
            }
          });

          // Store the cleanup function
          cleanup = attachmentsListener.cleanup;
        }
      } catch (err) {
        captureException(err);
        if (isMounted) {
          setError(err as Error);
          setIsLoading(false);
        }
      }
    };

    setupAttachmentsListener();

    // Cleanup function to cancel the listener when component unmounts
    return () => {
      isMounted = false;
      if (cleanup) {
        cleanup();
      }
    };
  }, []);

  return {
    attachments,
    groupedAttachments,
    isLoading,
    error,
  };
};

export default useAttachmentsListener;

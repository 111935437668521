import { format } from 'date-fns';
import { Text, Hyperlink } from 'components/common/ComponentLibrary';
import { getUrlCookiePolicy, getUrlImprint, getUrlPrivacy, getUrlTerms } from 'utilities/urls.utils';
import CommitDisplay from './CommitDisplay';

const Footer = () => (
  <div className='flex flex-col items-center justify-between py-5 px-5 md:flex-row'>
    <div className='flex flex-row items-center gap-4'>
      <Text color='gray-500' size='s'>{`© ${format(new Date(), 'yyyy')} Dump it!`}</Text>
      <CommitDisplay />
    </div>
    <div className='flex flex-col items-center md:flex-row space-y-1 md:space-y-0 md:space-x-5 mt-2 md:mt-0'>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlTerms()}>Terms</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlPrivacy()}>Privacy</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlCookiePolicy()}>Cookies</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlImprint()}>Imprint</Hyperlink>
      </Text>
    </div>
  </div>
);

export default Footer;

import { captureException } from '@sentry/react';
import { SUPABASE_EDGE_FUNCTION_INSERT_COLLABORATOR } from 'constants/application.constants';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';
import supabase from 'store/supabase/supabase';

export const addNewCollaborator = async (id: string, email: string): Promise<Partial<UserProfileInterface> | null> => {
  try {
    const result = await supabase.functions.invoke(SUPABASE_EDGE_FUNCTION_INSERT_COLLABORATOR, {
      body: {
        id,
        email,
      },
    });
    if (!result) {
      captureException(new Error('No result from addNewCollaborator'));
      return null;
    }

    if (result.error) {
      captureException(result.error);
      return null;
    }

    return result.data as Partial<UserProfileInterface>;
  } catch (error) {
    captureException(error);
    return null;
  }
};

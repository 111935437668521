import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { CollaboratorInterface } from '../interfaces/CollaboratorInterface';
import { updateDump } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { createDumpCollaborator, deleteDumpCollaborator } from './pouchDb/PouchDbDumpCollaboratorService';
import { getDumpCollaboratorsByDumpId } from './pouchDb/PouchDbDumpCollaboratorService';
import { inviteCollaborator } from './pouchDb/PouchDbCollaboratorService';

export async function shareDumpWithCollaborators(
  dumpId: string,
  selectedCollaborators: CollaboratorInterface[],
  emails: string[],
): Promise<boolean> {
  const currentProfileId = await getCurrentAuthenticatedProfileId();
  if (!currentProfileId) {
    throw new Error('Not authenticated');
  }

  // Get existing dump collaborators
  const existingDumpCollaborators = await getDumpCollaboratorsByDumpId(dumpId);

  // Delete collaborators that are no longer selected
  await Promise.all(
    existingDumpCollaborators.map(async (dumpCollaborator) => {
      const isStillSelected = selectedCollaborators.some(
        (selected) => selected.id === dumpCollaborator.collaborator_id,
      );

      if (!isStillSelected) {
        await deleteDumpCollaborator(dumpId, dumpCollaborator.collaborator_id);
      }
    }),
  );

  // Invite new collaborators from email list
  const invitedCollaborators = await Promise.all(
    emails.map(async (email: string) => {
      return await inviteCollaborator(email, currentProfileId);
    }),
  );

  // Create new dump collaborator entries for selected collaborators
  // Only create for those that don't already exist
  await Promise.all(
    selectedCollaborators.map(async (selectedCollaborator: CollaboratorInterface) => {
      const exists = existingDumpCollaborators.some((existing) => existing.collaborator_id === selectedCollaborator.id);

      if (!exists) {
        await createDumpCollaborator(dumpId, selectedCollaborator.id);
      }
    }),
  );

  // Create new dump collaborator entries for newly invited collaborators
  await Promise.all(
    invitedCollaborators.map(async (invitedCollaborator: CollaboratorInterface) => {
      await createDumpCollaborator(dumpId, invitedCollaborator.id);
    }),
  );

  await updateDump(dumpId, { is_synced: false });

  return true;
}

import Footer from 'components/application/Footer';
import React from 'react';
import { Hyperlink } from './ComponentLibrary';
import { getUrlHome } from 'utilities/urls.utils';
import { DumpItIcon } from './SpecialIcons';

const GeneralPageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className='m-auto flex min-h-screen flex-col'>
    <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32'>
      <Hyperlink href={getUrlHome()}>
        <DumpItIcon className='w-24' />
      </Hyperlink>
    </div>
    <div className='m-auto max-w-7xl px-4 md:px-0 flex-1'>{children}</div>
    <footer className='m-auto w-full max-w-7xl'>
      <Footer />
    </footer>
  </div>
);

export default GeneralPageContainer;

import { useEffect, useState } from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Avatar, Heading, Text, PersonItem } from 'components/common/ComponentLibrary';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDumpDumpCollaborators from 'hooks/collaborators/useDumpDumpCollaborators';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { UserGroupIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';
import { getInitials } from 'utilities/common/strings.utils';
import { getUserProfile } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';
import { getCurrentProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';
import { Color } from 'utilities/common/colors.utils';
import { useProfileStore } from 'store/profile.store';

const ViewCollaboratorsModal = ({ dump, onDismiss }: { dump: DumpInterface; onDismiss: () => void }) => {
  const { dumpCollaborators, isLoading } = useDumpDumpCollaborators(dump.id);
  const [dumpUserProfile, setDumpUserProfile] = useState<UserProfileInterface | undefined>(undefined);

  const profile = useProfileStore((state) => state.profile);

  // Sort collaborators - owner first, then current user, then by image and name
  const sortedCollaborators = [...dumpCollaborators].sort((a, b) => {
    // Owner of the dump comes first
    if (a.user_id === dump.user_id && b.user_id !== dump.user_id) return -1;
    if (b.user_id === dump.user_id && a.user_id !== dump.user_id) return 1;

    // Current user comes second
    if (a.user_id === profile?.id && b.user_id !== profile?.id) return -1;
    if (b.user_id === profile?.id && a.user_id !== profile?.id) return 1;

    // Sort by presence of image
    if (a.profile?.image_path && !b.profile?.image_path) return -1;
    if (!a.profile?.image_path && b.profile?.image_path) return 1;

    // Sort by first name
    if (!a.profile && !b.profile) return 0;
    if (!a.profile) return 1;
    if (!b.profile) return -1;

    return (a.profile.first_name || '').localeCompare(b.profile.first_name || '');
  });

  useEffect(() => {
    (async () => {
      const currentProfile = await getCurrentProfile();
      if (currentProfile?.id !== dump.user_id) {
        const dumpUserProfileMatch = await getUserProfile(dump.user_id!);
        if (dumpUserProfileMatch) {
          setDumpUserProfile(dumpUserProfileMatch);
        }
      }
    })();
  }, [dumpCollaborators]);

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6 min-w-[400px]'>
        <div className='flex items-center gap-2 justify-between w-full'>
          <div>
            <div className='flex items-center gap-4'>
              <UserGroupIcon className='size-8' />
              <Heading size='xl' textAlign='left'>
                Collaborators
              </Heading>
            </div>
            <Text color='gray-400' size='s'>
              The list shows all collaborators of this Dump.
            </Text>
          </div>
        </div>

        <div className='mt-7 flex flex-col gap-2'>
          {!isLoading && (
            <>
              {dumpUserProfile && (
                <div className='flex gap-3 items-center'>
                  <div className={'rounded-full border-2 border-transparent relative'}>
                    <Avatar
                      src={
                        dumpUserProfile.image_path
                          ? getPublicUrl(dumpUserProfile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                          : null
                      }
                      alt={dumpUserProfile.first_name || 'Dump Owner'}
                      size='m'
                      initials={
                        dumpUserProfile.first_name && dumpUserProfile.last_name
                          ? getInitials(`${dumpUserProfile.first_name} ${dumpUserProfile.last_name}`)
                          : undefined
                      }
                      backgroundColor={Color.flatModernColorFrom(dumpUserProfile.user_id || '')}
                    />
                  </div>
                  <div className='flex-grow'>
                    <Text fontWeight='semibold' size='m' lineClamp={1}>
                      {dumpUserProfile.first_name
                        ? `${dumpUserProfile.first_name} ${dumpUserProfile.last_name}`
                        : 'Dump Owner'}
                    </Text>
                  </div>
                </div>
              )}
              {sortedCollaborators.map((collaborator) => {
                if (collaborator.profile) {
                  return (
                    <PersonItem key={collaborator.collaborator_id}>
                      <PersonItem.Image
                        customElement={
                          <div className={'rounded-full border-2 border-transparent relative'}>
                            <Avatar
                              src={
                                collaborator.profile.image_path
                                  ? getPublicUrl(collaborator.profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                                  : null
                              }
                              alt={collaborator.profile.first_name || 'Collaborator'}
                              size='m'
                              initials={
                                collaborator.profile.first_name && collaborator.profile.last_name
                                  ? getInitials(`${collaborator.profile.first_name} ${collaborator.profile.last_name}`)
                                  : undefined
                              }
                              backgroundColor={Color.flatModernColorFrom(collaborator.profile.user_id || '')}
                            />
                          </div>
                        }
                      />
                      <PersonItem.Content
                        title={
                          collaborator.profile.first_name
                            ? `${collaborator.profile.first_name} ${collaborator.profile.last_name}${
                                collaborator.user_id === profile?.id ? ' (You)' : ''
                              }`
                            : 'Invited Dumper'
                        }
                      />
                    </PersonItem>
                  );
                } else {
                  return (
                    <PersonItem key={collaborator.collaborator_id}>
                      <PersonItem.Image
                        customElement={
                          <div className={'rounded-full border-2 border-transparent relative'}>
                            <div className='relative flex items-center justify-center shrink-0 overflow-hidden transition ease-in-out bg-zinc-200 dark:bg-zinc-500 rounded-full size-11'>
                              <EnvelopeIcon className='w-2/5 text-gray-400 dark:text-gray-300' />
                            </div>
                          </div>
                        }
                      />
                      <PersonItem.Content title='Invited Dumper' />
                    </PersonItem>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
    </BackdropComponent>
  );
};

export default ViewCollaboratorsModal;

import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { CommandLineIcon } from '@heroicons/react/24/outline';
import { isWindows } from 'react-device-detect';

const shortcuts = [
  {
    title: 'Attach an image or file to a dump',
    shortcuts: [isWindows ? 'windows' : '⌘', 'i'],
    alternateShortcuts: [isWindows ? 'windows' : '⌘', 'm'],
  },
  {
    title: 'Navigate between screens',
    shortcuts: ['←', '→'],
  },
  {
    title: 'Share Dump with another user',
    shortcuts: [isWindows ? 'windows' : '⌘', 'c'],
  },
  {
    title: 'Search your Dumps',
    shortcuts: [isWindows ? 'windows' : '⌘', 'k'],
  },
  {
    title: 'Submit or update a Dump',
    shortcuts: [isWindows ? 'windows' : '⌘', '↵'],
  },
  {
    title: 'Close an open modal/dialog',
    shortcuts: ['esc'],
  },
  {
    title: 'Show keyboard shortcuts',
    shortcuts: [isWindows ? 'windows' : '⌘', '/'],
  },
];

const KeyboardShortcutsModal = ({ onDismiss }: { onDismiss: () => void }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full max-w-2xl'>
      <div className='px-6 pt-4'>
        <h2 className='text-2xl font-semibold flex items-center gap-2'>
          <CommandLineIcon className='w-6 h-6' />
          Keyboard shortcuts
        </h2>
      </div>
      <div className='p-6'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          {shortcuts.map((item) => (
            <div key={item.title} className='flex flex-col justify-between group gap-2'>
              <div className='text-base text-gray-500 dark:text-zinc-400'>{item.title}</div>
              <div className='flex items-center gap-2 flex-wrap'>
                {item.shortcuts.map((key, index) => (
                  <kbd
                    key={index}
                    className='inline-flex items-center justify-center h-10 min-w-[2.5rem] px-3 text-xl font-mono font-medium text-gray-900 bg-gray-100 border-2 border-gray-300 rounded-lg shadow-md dark:bg-zinc-800 dark:border-zinc-700 dark:text-zinc-200'
                  >
                    {key}
                  </kbd>
                ))}
                {item.alternateShortcuts && (
                  <>
                    <span className='text-sm text-gray-400 px-2'>or</span>
                    {item.alternateShortcuts.map((key, index) => (
                      <kbd
                        key={index}
                        className='inline-flex items-center justify-center h-10 min-w-[2.5rem] px-3 text-xl font-mono font-medium text-gray-900 bg-gray-100 border-2 border-gray-300 rounded-lg shadow-md dark:bg-zinc-800 dark:border-zinc-700 dark:text-zinc-200'
                      >
                        {key}
                      </kbd>
                    ))}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </BackdropComponent>
);

export default KeyboardShortcutsModal;

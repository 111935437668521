import { useEffect, useState } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { shareDumpWithCollaborators } from 'models/collaborators/services/CollaboratorHelperService';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { toast } from 'react-toastify';
import { pushUnsyncedCollaborators } from 'models/collaborators/services/CollaboratorSyncService';
import {
  pullLatestDumpCollaboratorsWithProfiles,
  pushUnsyncedDumpCollaborators,
} from 'models/collaborators/services/DumpCollaboratorSyncService';
import useDumpDumpCollaborators from 'hooks/collaborators/useDumpDumpCollaborators';
import { pushUnsyncedDumps } from 'models/dumps/services/DumpSyncService';
import useCollaborators from 'hooks/collaborators/useCollaborators';
import CollaboratorsModal from './CollaboratorsModal';

const EditDumpCollaboratorsModal = ({ dump, onDismiss }: { dump: DumpInterface; onDismiss: () => void }) => {
  const { collaborators } = useCollaborators();
  const { dumpCollaborators, isLoading } = useDumpDumpCollaborators(dump.id);

  const [selectedCollaborators, setSelectedCollaborators] = useState<CollaboratorInterface[]>([]);

  useEffect(() => {
    if (!isLoading && dumpCollaborators && collaborators) {
      const existingCollaborators = dumpCollaborators
        .map((dumpCollab) => collaborators.find((c) => c.id === dumpCollab.collaborator_id))
        .filter((c): c is CollaboratorInterface => c !== undefined);
      setSelectedCollaborators(existingCollaborators);
    }
  }, [dumpCollaborators, collaborators, isLoading]);

  const handleShare = async (selectedCollaborators: CollaboratorInterface[], invitedEmails: string[]) => {
    try {
      await shareDumpWithCollaborators(dump.id, selectedCollaborators, invitedEmails);

      toast.success('Dump updated');

      onDismiss();

      await pushUnsyncedDumps();
      await pushUnsyncedCollaborators();
      await pushUnsyncedDumpCollaborators();

      await pullLatestDumpCollaboratorsWithProfiles();
    } catch (error) {
      notifyAboutError(error);
    }
  };

  return (
    <CollaboratorsModal
      collaborators={collaborators}
      onDismiss={onDismiss}
      onShare={handleShare}
      selectedCollaborators={selectedCollaborators}
      invitedEmails={[]}
    />
  );
};

export default EditDumpCollaboratorsModal;

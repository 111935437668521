import PouchDB from 'pouchdb';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';

const profilesDB = new PouchDB('profiles', { auto_compaction: true });

export async function getCurrentProfile(): Promise<ProfileInterface | null> {
  try {
    const result = await profilesDB.find({
      selector: {},
      limit: 1,
    });
    if (!result.docs.length) {
      return null;
    }

    const { _id, _rev, ...userData } = result.docs[0];
    return userData as ProfileInterface;
  } catch (e) {
    return null;
  }
}

export async function upsertProfile(profile: Partial<ProfileInterface>): Promise<ProfileInterface> {
  if (!profile.id) {
    throw new Error('Profile ID is required for upsert');
  }

  try {
    const existing = await profilesDB.get(profile.id);
    const updatedProfile = {
      ...existing,
      ...profile,
      _rev: existing._rev,
    };
    const response = await profilesDB.put(updatedProfile);
    const finalProfile = {
      ...updatedProfile,
      _rev: response.rev,
    };
    const { ...profileData } = finalProfile;
    return profileData as ProfileInterface;
  } catch (error: any) {
    if (error.status === 404) {
      const newProfile = {
        _id: profile.id,
        ...profile,
      };
      const response = await profilesDB.put(newProfile);
      const finalProfile = {
        ...newProfile,
        _rev: response.rev,
      };
      const { ...profileData } = finalProfile;
      return profileData as ProfileInterface;
    } else if (error.status === 409) {
      // Handle conflict by retrying the operation
      return upsertProfile(profile);
    }
    throw error;
  }
}

export async function getAllUnsyncedProfiles(): Promise<ProfileInterface[] | null> {
  try {
    const result = await profilesDB.find({
      selector: {
        is_synced: false,
      },
    });
    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => {
      const { ...profileData } = doc;
      return profileData as ProfileInterface;
    });
  } catch (e) {
    return null;
  }
}

export async function markProfileSynced(profileId: string, additionalData: Partial<ProfileInterface>) {
  const existingProfile = await profilesDB.get(profileId);
  if (existingProfile) {
    return await profilesDB.put({
      ...existingProfile,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function clearProfilesDB(): Promise<void> {
  await profilesDB.destroy();
}

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageNotFound from 'screens/not-found/PageNotFoundScreen';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { useAuthStore } from 'store/auth.store';
import CollaboratorInvitationDeviceChoice from 'components/collaborators/invitation/CollaboratorInvitationDeviceChoice';
import CollaboratorInvitationRedirect from 'components/collaborators/invitation/CollaboratorInvitationRedirect';
import CollaboratorInvitationAuth from 'components/collaborators/invitation/CollaboratorInvitationAuth';
import GeneralPageContainer from 'components/common/GeneralPageContainer';
import { isMobile } from 'react-device-detect';

const InvitationScreen = () => {
  useDocumentTitle('Invitation');

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const [shouldViewInBrowser, setShouldViewInBrowser] = useState<boolean>(!isMobile);

  const [searchParams] = useSearchParams();
  const collaboratorId = searchParams.get('collaborator_id');
  if (!collaboratorId) {
    return <PageNotFound />;
  }

  if (shouldViewInBrowser && !currentUser) {
    return <CollaboratorInvitationAuth />;
  }

  if (shouldViewInBrowser) {
    return <CollaboratorInvitationRedirect />;
  }

  return (
    <GeneralPageContainer>
      <CollaboratorInvitationDeviceChoice onContinue={() => setShouldViewInBrowser(true)} />
    </GeneralPageContainer>
  );
};

export default InvitationScreen;

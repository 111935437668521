import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Button, Heading, Text } from 'components/common/ComponentLibrary/';
import useDeleteUser from 'hooks/users/useDeleteUser';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const ProfileDeleteModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { isBusy, deleteUser } = useDeleteUser();
  const { t } = useTranslation('translations');

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='flex items-start flex-col w-full p-6'>
        <Heading size='xl' spacing='s'>
          {t('deleteAccountModal.title')}
        </Heading>
        <div className='flex items-center space-x-5 mt-4 mb-8'>
          <ShieldExclamationIcon className='w-16' />
          <div>
            <Text fontWeight='bold' size='l'>
              {t('deleteAccountModal.headline1')}
            </Text>
            <Text color='red'>
              <i>{t('deleteAccountModal.headline2')}</i>
            </Text>
          </div>
        </div>
        <div className='flex justify-between w-full'>
          <Button size='m' onClick={onDismiss} theme='ghost'>
            {t('deleteAccountModal.rejectAction')}
          </Button>
          <Button color='red' onClick={deleteUser} status={isBusy ? 'busy' : ''}>
            {t('deleteAccountModal.acceptAction')}
          </Button>
        </div>
      </div>
    </BackdropComponent>
  );
};

export default ProfileDeleteModal;

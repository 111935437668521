import React from 'react';

interface IAction {
  children: React.ReactNode;
}

const Action = ({ children }: IAction) => <div className='flex items-center justify-center'>{children}</div>;

Action.displayName = 'PersonItem.Action';

export default Action;

import { useEffect, useState } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { useProfileStore } from 'store/profile.store';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';
import { getUserProfile } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';
import { getCurrentProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';
import { getCollaboratorById } from 'models/collaborators/services/pouchDb/PouchDbCollaboratorService';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { sortCollaborators } from 'utilities/collaborators/collaborators.utils';
import { listenToDumpDumpCollaborators } from 'models/collaborators/services/pouchDb/PouchDbDumpCollaboratorService';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';

interface CollaboratorsForFacepile {
  id: string;
  user_id?: string;
  image_src?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

const useDumpCollaboratorsForFacepile = (
  dump: DumpInterface,
  dumpCollaborators: DumpCollaboratorInterface[],
  useRealtime = false,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collaboratorsForFacepile, setCollaboratorsForFacepile] = useState<CollaboratorsForFacepile[]>([]);
  const profile = useProfileStore((state) => state.profile);

  useEffect(() => {
    if (dump) {
      (async () => {
        try {
          // Get current profile and dump owner profile
          const currentProfile = await getCurrentProfile();
          let dumpOwnerProfile: UserProfileInterface | undefined;

          if (currentProfile?.id !== dump.user_id) {
            const ownerProfile = await getUserProfile(dump.user_id!);
            dumpOwnerProfile = ownerProfile || undefined;
          } else {
            dumpOwnerProfile = {
              user_id: profile?.id ?? '',
              image_path: profile?.image_path ?? '',
              first_name: profile?.first_name ?? '',
              last_name: profile?.last_name ?? '',
            };
          }

          // Handle collaborators processing
          const processCollaborators = async (dumpCollaborators: DumpCollaboratorInterface[]) => {
            const resolvedCollaborators = await Promise.all(
              dumpCollaborators.map(async (dumpCollaborator) => {
                const collaborator = await getCollaboratorById(dumpCollaborator.collaborator_id!);
                if (collaborator) {
                  const userProfile = await getUserProfile(collaborator.user_id!);
                  return {
                    id: dumpCollaborator.collaborator_id,
                    user_id: userProfile?.user_id,
                    image_src: userProfile?.image_path
                      ? getPublicUrl(userProfile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                      : undefined,
                    first_name: userProfile?.first_name,
                    last_name: userProfile?.last_name,
                    email: collaborator.email,
                  };
                }
                return undefined;
              }),
            );

            // Filter and combine collaborators
            const filteredCollaborators = resolvedCollaborators
              .filter(Boolean)
              .filter(
                (collab) => collab?.user_id !== dump.user_id && collab?.user_id !== currentProfile?.id,
              ) as CollaboratorsForFacepile[];

            const allCollaborators = [
              ...(dumpOwnerProfile
                ? [
                    {
                      id: dumpOwnerProfile.user_id!,
                      user_id: dumpOwnerProfile.user_id,
                      image_src: dumpOwnerProfile.image_path
                        ? getPublicUrl(dumpOwnerProfile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                        : undefined,
                      first_name: dumpOwnerProfile.first_name,
                      last_name: dumpOwnerProfile.last_name,
                    },
                  ]
                : []),
              ...(currentProfile && currentProfile.id !== dump.user_id
                ? [
                    {
                      id: currentProfile.id,
                      user_id: currentProfile.id,
                      image_src: profile?.image_path
                        ? getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                        : undefined,
                      first_name: profile?.first_name,
                      last_name: profile?.last_name,
                    },
                  ]
                : []),
              ...sortCollaborators(filteredCollaborators),
            ];

            setCollaboratorsForFacepile(allCollaborators);
            setIsLoading(false);
          };

          if (useRealtime) {
            // Set up realtime listener
            const subscription = listenToDumpDumpCollaborators(dump.id!);
            subscription.onChange(processCollaborators);

            // Handle initial load
            const initialCollaborators = await subscription;
            await processCollaborators(initialCollaborators);

            return () => {
              subscription.cleanup(processCollaborators);
            };
          } else {
            // One-time fetch
            await processCollaborators(dumpCollaborators);
          }
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }
  }, [dump, profile, useRealtime]);

  return {
    isLoading,
    items: collaboratorsForFacepile,
  };
};

export default useDumpCollaboratorsForFacepile;

import { SupabaseClient } from '@supabase/supabase-js';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';

export const TABLE_NAME = 'profiles';

export class SupabaseProfileService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getCurrentProfileId(): Promise<string | null> {
    const {
      data: { session },
    } = await this.supabaseClient.auth.getSession();
    if (!session || !session?.user?.id) {
      return null;
    }

    return session.user.id;
  }

  async getCurrentProfile(): Promise<ProfileInterface | null> {
    const {
      data: { session },
    } = await this.supabaseClient.auth.getSession();
    if (!session || !session?.user?.id) {
      return null;
    }

    return this.getById(session.user.id);
  }

  async getById(userId: string): Promise<ProfileInterface | null> {
    const { data, error } = await this.supabaseClient.from(TABLE_NAME).select().eq('id', userId).single();
    if (!data || error) {
      return null;
    }

    return data as ProfileInterface;
  }

  async updateProfile(userId: string, newData: Partial<ProfileInterface>): Promise<ProfileInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME)
      .update({
        ...newData,
        updated_at: getCurrentUTCDateFormatted(),
      })
      .eq('id', userId)
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async getProfiles(since?: string, page = 1): Promise<ProfileInterface[]> {
    const fromIndex = (page - 1) * 1;
    const toIndex = fromIndex + 1 - 1;

    let supabaseQuery = this.supabaseClient.from(TABLE_NAME).select();

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.range(fromIndex, toIndex).order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }
}

import { useState } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import Comments from './Comments';
import { useProfileStore } from 'store/profile.store';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Button, Heading } from 'components/common/ComponentLibrary';
import ProfileNameForm from 'components/profiles/ProfileName/ProfileNameForm';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/solid';

const CommentsSidebar = ({ dump, isOpen, onClose }: { dump: DumpInterface; isOpen: boolean; onClose: () => void }) => {
  const profile = useProfileStore((state) => state.profile);
  const [showProfileForm, setShowProfileForm] = useState(!profile?.first_name || !profile?.last_name);

  if (!isOpen) {
    return null;
  }

  if (showProfileForm) {
    return (
      <BackdropComponent dismiss={() => onClose()}>
        <div className='w-full p-6 max-w-[500px]'>
          <Heading size='l' textAlign='left' spacing='l'>
            Complete Your Profile to Comment
          </Heading>
          <ProfileNameForm
            onChanged={() => {
              setShowProfileForm(false);
            }}
            onCancel={() => onClose()}
          />
        </div>
      </BackdropComponent>
    );
  }

  return (
    <div className='w-full md:w-[425px] h-full'>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='z-10 fixed right-0 top-0 h-screen w-full md:w-[425px] bg-bright shadow-lg dark:bg-paperdark border-l border-dark/10 dark:border-white/25 flex flex-col'
          >
            <div className='px-8 pt-7 pb-2 flex items-center justify-between text-dark dark:text-white'>
              <Heading size='xl'>Comments</Heading>
              <Button theme='bare' size='s' color='default' width='square' onClick={() => onClose()}>
                <Button.Icon icon={<XMarkIcon className='h-8' />} />
              </Button>
            </div>

            <div className='pl-8 h-full'>
              <Comments dump={dump} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CommentsSidebar;

import React from 'react';
import { Avatar } from 'components/common/ComponentLibrary';
import { getInitials } from 'utilities/common/strings.utils';

interface IImage {
  src?: string | null;
  alt?: string;
  name?: string;
  size?: 's' | 'm' | 'xs' | 'xxs';
  customElement?: React.ReactNode;
  backgroundColor?: string;
}

const Image = ({ src, alt, name, size = 's', customElement, backgroundColor }: IImage) => (
  <div className={'rounded-full border-2 border-transparent relative'}>
    {customElement || (
      <Avatar
        src={src}
        alt={alt || name || 'Person'}
        size={size}
        initials={name ? getInitials(name) : undefined}
        backgroundColor={backgroundColor}
      />
    )}
  </div>
);

Image.displayName = 'PersonItem.Image';

export default Image;

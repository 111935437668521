import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { createTags, deleteTagsByDumpId } from 'models/tags/services/pouchDb/PouchDbTagService';
import { extractTags } from 'utilities/dumps/dumps.utils';

PouchDB.plugin(PouchDBFind);

const dumpsDB = new PouchDB('dumps', { auto_compaction: true });

dumpsDB.createIndex({
  index: {
    fields: ['sort_date'],
    ddoc: 'sort_date_index',
    name: 'sort_date_index',
  },
});

dumpsDB.createIndex({
  index: {
    fields: ['id', 'created_at'],
  },
});

export async function createDump(dump: DumpInterface): Promise<DumpInterface> {
  const newDump = {
    _id: dump.id,
    ...dump,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    sort_date: getCurrentUTCDateFormatted(),
  };

  await dumpsDB.put(newDump);

  await createTags(extractTags(newDump.text), newDump.id);

  return newDump;
}

export async function updateDump(dumpId: string, dump: Partial<DumpInterface>, updateLastEditedAt = true) {
  try {
    const existingDump = await dumpsDB.get(dumpId);
    if (!existingDump) {
      return Promise.reject('No dump found');
    }

    const updatedDoc = {
      ...existingDump,
      ...dump,
      updated_at: getCurrentUTCDateFormatted(),
    };

    if (updateLastEditedAt) {
      updatedDoc.last_edited_at = getCurrentUTCDateFormatted();
      updatedDoc.sort_date = getCurrentUTCDateFormatted();
    }

    await dumpsDB.put(updatedDoc);

    await deleteTagsByDumpId(existingDump._id);
    if (!updatedDoc._deleted) {
      await createTags(extractTags(updatedDoc.text), updatedDoc._id);
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function updateDumpLatestCommentDate(dumpId: string, date: string) {
  try {
    const existingDump = (await dumpsDB.get(dumpId)) as DumpInterface;
    if (!existingDump) {
      return Promise.reject('No dump found');
    }

    const dates = [existingDump.last_edited_at, existingDump.created_at, date].filter((date): date is string =>
      Boolean(date),
    );

    const mostRecentDate = dates.reduce((latest, current) => (current > latest ? current : latest));

    const updatedDoc = {
      ...existingDump,
      sort_date: mostRecentDate,
      latest_comment_at: date,
    };

    await dumpsDB.put(updatedDoc);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function markDumpSynced(dumpId: string, additionalData: Partial<DumpInterface>) {
  const existingDump = await getDumpById(dumpId);
  if (existingDump) {
    return await dumpsDB.put({
      ...existingDump,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function getDumpById(dumpId: string): Promise<DumpInterface | null> {
  try {
    const dump = await dumpsDB.get(dumpId);
    const { ...dumpData } = dump;
    return dumpData as DumpInterface;
  } catch (e) {
    return null;
  }
}

export async function getAllDumps(skip: number = 0, limit: number = 25): Promise<DumpInterface[]> {
  try {
    const result = await dumpsDB.find({
      selector: {
        sort_date: { $exists: true },
      },
      sort: [{ sort_date: 'desc' }],
      skip,
      limit,
    });
    return result.docs.map((doc) => {
      const { ...dumpData } = doc;
      return dumpData as DumpInterface;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllUnsyncedDumps(): Promise<DumpInterface[] | null> {
  try {
    const result = await dumpsDB.find({
      selector: {
        is_synced: false,
      },
    });

    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => {
      const { ...dumpData } = doc;
      return dumpData as DumpInterface;
    });
  } catch (e) {
    return null;
  }
}

export async function upsertDump(dump: Partial<DumpInterface>): Promise<boolean> {
  if (!dump.id) {
    throw new Error('Dump ID is required');
  }

  let isNewDump = false;
  try {
    const existing = await getDumpById(dump.id);
    if (existing) {
      const dates = [
        existing.last_edited_at,
        existing.created_at,
        existing.latest_comment_at,
        dump.last_edited_at,
      ].filter((date): date is string => Boolean(date));

      const mostRecentDate = dates.reduce((latest, current) => (current > latest ? current : latest));

      const { created_at, ...updateData } = dump;

      await dumpsDB.put({
        ...existing,
        ...updateData,
        sort_date: mostRecentDate,
      });
      isNewDump = false;
    } else {
      const dates = [dump.last_edited_at, dump.created_at, dump.latest_comment_at].filter((date): date is string =>
        Boolean(date),
      );

      const mostRecentDate = dates.reduce((latest, current) => (current > latest ? current : latest));
      await dumpsDB.put({
        _id: dump.id,
        ...dump,
        sort_date: mostRecentDate,
      });
      isNewDump = true;
    }

    // Handle tags for both new and updated dumps
    await deleteTagsByDumpId(dump.id);
    if (!dump._deleted) {
      await createTags(extractTags(dump.text || ''), dump.id);
    }

    return isNewDump;
  } catch (error: any) {
    if (error.status === 409 || error.name === 'conflict') {
      return await upsertDump(dump);
    }
    throw error;
  }
}

export function listenToDumps(
  skip: number = 0,
  limit: number = 25,
): Promise<DumpInterface[]> & {
  onChange: (callback: (dumps: DumpInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getAllDumps(skip, limit);
  const listeners: ((dumps: DumpInterface[]) => void)[] = [];

  const changes = dumpsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
    })
    .on('change', async () => {
      const dumps = await getAllDumps(skip, limit);
      listeners.forEach((listener) => listener(dumps));
    });

  return Object.assign(promise, {
    onChange: (callback: (dumps: DumpInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export async function clearDumpsDB(): Promise<void> {
  await dumpsDB.destroy();
}

export function listenToDump(dumpId: string): Promise<DumpInterface | null> & {
  onChange: (callback: (dump: DumpInterface | null) => void) => void;
  cleanup: () => void;
} {
  const promise = getDumpById(dumpId);
  const listeners: ((dump: DumpInterface | null) => void)[] = [];

  const changes = dumpsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
      selector: { _id: dumpId },
    })
    .on('change', async () => {
      const dump = await getDumpById(dumpId);
      listeners.forEach((listener) => listener(dump));
    });

  return Object.assign(promise, {
    onChange: (callback: (dump: DumpInterface | null) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export async function getByDumpIds(dumpIds: string[]): Promise<DumpInterface[]> {
  try {
    const result = await dumpsDB.find({
      selector: {
        _id: { $in: dumpIds },
        _deleted: { $ne: true },
      },
    });

    return result.docs.map((doc) => {
      const { ...dumpData } = doc;
      return dumpData as DumpInterface;
    });
  } catch (e) {
    return [];
  }
}

export async function getDumpsByUserId(userId: string): Promise<DumpInterface[]> {
  try {
    const result = await dumpsDB.find({
      selector: {
        user_id: userId,
        _deleted: { $ne: true },
      },
    });

    return result.docs.map((doc) => {
      const { ...dumpData } = doc;
      return dumpData as DumpInterface;
    });
  } catch (e) {
    return [];
  }
}

export async function getTotalDumpsCount(): Promise<number> {
  try {
    const result = await dumpsDB.find({
      selector: {
        _deleted: { $ne: true },
      },
      fields: ['_id'],
      limit: 10000,
    });
    return result.docs.length;
  } catch (e) {
    return 0;
  }
}

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import LazyTippy from './LazyTippy';
import { Instance } from 'tippy.js';

interface TooltipInterface {
  children: React.ReactElement;
  target: React.ReactElement;
  spacing?: 'none' | 's' | 'm' | 'l';
  size?: 'none' | 's' | 'm' | 'l';
  width?: 'auto' | 'full';
  isInteractive?: boolean;
  hideOnClick?: boolean;
  isPlain?: boolean;
  scope?: 'local' | 'global';
  placement?: 'top' | 'bottom';
  trigger?: 'mouseenter focus' | 'click';
  onShow?: () => void;
  onHidden?: () => void;
  onMount?: (instance: Instance) => void;
}

const Tooltip = ({
  children,
  spacing = 'm',
  target,
  size = 'm',
  width = 'auto',
  placement = 'bottom',
  isInteractive = false,
  hideOnClick = false,
  isPlain = false,
  scope = 'local',
  trigger = 'mouseenter focus',
  onShow,
  onHidden,
  onMount,
}: TooltipInterface) => {
  const [body, setBody] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setBody(document.body);
  }, []);

  const className = classNames({
    'relative bg-bright text-dark dark:text-white dark:bg-paperdark border-1 border-dark/25 dark:border-white/25 -mt-2':
      !isPlain,

    'p-0': spacing === 'none',
    'px-4 py-2': spacing === 's',
    'p-4': spacing === 'm',
    'p-6': spacing === 'l',

    'rounded-lg max-w-xxs min-w-xxs shadow-lg': size === 's',
    'rounded-xl max-w-xxs shadow-xl': size === 'm',
    'rounded-xl max-w-sm shadow-xl': size === 'l',

    'w-full': width === 'full',
  });

  return (
    <LazyTippy
      content={
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={className}>
            {children}
          </motion.div>
        </AnimatePresence>
      }
      maxWidth='none'
      placement={placement}
      interactive={isInteractive}
      appendTo={scope === 'global' && body ? body : 'parent'}
      trigger={trigger}
      zIndex={20}
      onShow={onShow}
      onHidden={onHidden}
      onMount={(instance) => {
        onMount?.(instance);
        if (hideOnClick) {
          document?.querySelector('[data-tippy-root]')?.addEventListener('click', (event) => {
            instance.hide();
          });
        }
      }}
    >
      {target}
    </LazyTippy>
  );
};

export default Tooltip;

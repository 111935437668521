import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { v4 as uuidv4 } from 'uuid';
import { captureException } from '@sentry/react';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { getEnrichedComments } from '../CommentHelperService';

// Register the find plugin
PouchDB.plugin(PouchDBFind);

// Create PouchDB instance with auto compaction
export const commentsDB = new PouchDB('comments', { auto_compaction: true });

// Create index for querying
commentsDB.createIndex({
  index: {
    fields: ['dump_id', 'created_at'],
  },
});

export async function createComment(text: string, dumpId: string, userId: string, commentRef?: string) {
  const newComment: CommentInterface = {
    id: uuidv4().toString(),
    text,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    comment_ref: commentRef || undefined,
    is_synced: false,
  };

  return await commentsDB.put({
    _id: newComment.id,
    ...newComment,
  });
}

export async function markCommentSynced(commentId: string, additionalData: Partial<CommentInterface>) {
  const existing = await commentsDB.get(commentId);
  if (existing) {
    return await commentsDB.put({
      ...existing,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function getCommentById(commentId: string): Promise<CommentInterface | null> {
  try {
    const doc = await commentsDB.get(commentId);
    const { _id, _rev, ...commentData } = doc;
    return commentData as CommentInterface;
  } catch (error) {
    return null;
  }
}

export async function getAllUnsyncedComments(): Promise<CommentInterface[] | null> {
  try {
    const result = await commentsDB.find({
      selector: {
        is_synced: false,
      },
    });
    return result.docs.map((doc) => {
      const { _id, _rev, ...commentData } = doc;
      return commentData as CommentInterface;
    });
  } catch (error) {
    return null;
  }
}

export async function deleteComment(commentId: string) {
  const existing = await commentsDB.get(commentId);
  if (existing) {
    await commentsDB.put({
      ...existing,
      is_synced: false,
      is_to_be_deleted: true,
      updated_at: getCurrentUTCDateFormatted(),
    });
  }
}

export async function updateComment(commentId: string, comment: Partial<CommentInterface>) {
  const existing = await commentsDB.get(commentId);
  if (existing) {
    await commentsDB.put({
      ...existing,
      ...comment,
      is_synced: false,
      updated_at: getCurrentUTCDateFormatted(),
    });
  }
}

export async function upsertComment(comment: Partial<CommentInterface>) {
  try {
    if (!comment.id) {
      throw new Error('Comment ID is required for upsert');
    }

    try {
      const existing = await commentsDB.get(comment.id);
      const { created_at, ...updateData } = comment;
      return await commentsDB.put({
        ...existing,
        ...updateData,
      });
    } catch (error: any) {
      if (error.status === 404) {
        return await commentsDB.put({
          _id: comment.id,
          ...comment,
        });
      }
      throw error;
    }
  } catch (error: any) {
    if (error.status === 409) {
      return await upsertComment(comment);
    }
    throw error;
  }
}

export async function getByDumpId(dumpId: string): Promise<CommentInterface[]> {
  try {
    const result = await commentsDB.find({
      selector: {
        dump_id: dumpId,
      },
      sort: [{ dump_id: 'desc' }, { created_at: 'desc' }],
      limit: 1000, // @todo-phil fix
    });
    return result.docs?.map((doc) => {
      const { _id, _rev, ...commentData } = doc;
      return commentData as CommentInterface;
    });
  } catch (error) {
    return [];
  }
}

export async function getLatestCommentByDumpId(dumpId: string): Promise<CommentInterface | null> {
  try {
    const result = await commentsDB.find({
      selector: {
        dump_id: dumpId,
      },
      sort: [{ dump_id: 'desc' }, { created_at: 'desc' }],
      limit: 1,
    });

    // Filter out deleted documents
    const nonDeletedDocs = result.docs.filter((doc: any) => !doc._deleted);
    if (nonDeletedDocs.length === 0) {
      return null;
    }

    const { _id, _rev, ...commentData } = nonDeletedDocs[0];
    return commentData as CommentInterface;
  } catch (error) {
    return null;
  }
}

export function listenToComments(dumpId: string): Promise<CommentInterface[]> & {
  onChange: (callback: (comments: CommentInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getEnrichedComments(dumpId);
  const listeners: ((comments: CommentInterface[]) => void)[] = [];

  const changes = commentsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
    })
    .on('change', async () => {
      const enrichedComments = await getEnrichedComments(dumpId);
      listeners.forEach((listener) => listener(enrichedComments));
    });

  return Object.assign(promise, {
    onChange: (callback: (comments: CommentInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export async function clearCommentsDB(): Promise<void> {
  await commentsDB.destroy();
}

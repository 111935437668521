import { useEffect, useState } from 'react';
import { useVisibilityChange } from 'use-visibility-change';
import { captureException } from '@sentry/react';
import { pullLatestProfile } from 'models/profiles/services/ProfileSyncService';
import { pushUnsyncedProfile } from 'models/profiles/services/ProfileSyncService';
import { pullLatestSetting } from 'models/settings/services/SettingSyncService';
import { pushUnsyncedSetting } from 'models/settings/services/SettingSyncService';

const useSyncProfile = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const isVisible = useVisibilityChange();

  useEffect(() => {
    if (isVisible) {
      (async () => {
        if (isBusy) {
          return;
        }

        setIsBusy(true);
        try {
          await Promise.all([pushUnsyncedProfile(), pushUnsyncedSetting()]);
          await Promise.all([pullLatestProfile(), pullLatestSetting()]);
        } catch (error) {
          captureException(error);
        }
        setIsBusy(false);
      })();
    }
  }, [isVisible]);
};

export default useSyncProfile;

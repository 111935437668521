import { useRef, useEffect } from 'react';
import { Button, Textarea } from 'components/common/ComponentLibrary';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import CommentReplyPreview from './CommentReplyPreview';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { createCommentDraft, deleteByDumpId } from 'models/comments/services/pouchDb/PouchDbCommentDraftService';
import debounce from 'lodash/debounce';

interface CommentReplyProps {
  newComment: string;
  setNewComment: (comment: string) => void;
  replyToComment: CommentInterface | null | undefined;
  setReplyTo: (commentId: string | null) => void;
  onSubmitComment: () => void;
  dumpId: string;
}

const CommentReply = ({
  newComment,
  setNewComment,
  replyToComment,
  setReplyTo,
  onSubmitComment,
  dumpId,
}: CommentReplyProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const selectionRef = useRef<{ start: number; end: number } | null>(null);

  const debouncedCreateDraft = useRef(
    debounce((dumpId: string, comment: string) => {
      createCommentDraft(dumpId, comment);
    }, 500),
  ).current;

  useEffect(() => {
    if (replyToComment) {
      textareaRef.current?.focus();
    }
  }, [replyToComment]);

  useEffect(() => {
    if (newComment) {
      debouncedCreateDraft(dumpId, newComment);
    } else {
      deleteByDumpId(dumpId);
    }

    return () => {
      debouncedCreateDraft.cancel();
    };
  }, [newComment, dumpId, debouncedCreateDraft]);

  return (
    <div className='py-4 mt-auto'>
      <div className='flex items-end gap-2 pr-8'>
        <Textarea
          ref={textareaRef}
          rows={1}
          name='comment'
          placeholder='Add a comment...'
          value={newComment}
          onChange={(e) => {
            const target = e.target as HTMLTextAreaElement;
            selectionRef.current = {
              start: target.selectionStart,
              end: target.selectionEnd,
            };

            setNewComment(e.target.value);

            requestAnimationFrame(() => {
              if (selectionRef.current && textareaRef.current) {
                textareaRef.current.setSelectionRange(selectionRef.current.start, selectionRef.current.end);
              }
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && (e.metaKey || e.ctrlKey) && newComment.length > 0) {
              e.preventDefault();
              onSubmitComment();
            }
          }}
          autoFocus={true}
        />
        {newComment.length > 0 && (
          <Button size='l' onClick={onSubmitComment}>
            <Button.Icon icon={<PaperAirplaneIcon className='size-6' />} />
          </Button>
        )}
      </div>

      {replyToComment && <CommentReplyPreview replyToComment={replyToComment} onClose={() => setReplyTo(null)} />}
    </div>
  );
};

export default CommentReply;

import { useState } from 'react';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { useAuthStore } from 'store/auth.store';
import { v4 as uuidv4 } from 'uuid';
import { Dump } from 'models/dumps/entities/Dump';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { createDumpAudioAttachment, createDumpGenericAttachment } from 'utilities/dumps/dumpAttachment.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import { pushUnsyncedDumps } from 'models/dumps/services/DumpSyncService';
import { pushUnsyncedAttachments } from 'models/attachments/services/AttachmentSyncService';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { pushUnsyncedCollaborators } from 'models/collaborators/services/CollaboratorSyncService';
import { shareDumpWithCollaborators } from 'models/collaborators/services/CollaboratorHelperService';
import { pushUnsyncedDumpCollaborators } from 'models/collaborators/services/DumpCollaboratorSyncService';
import { createDump } from 'models/dumps/services/pouchDb/PouchDbDumpService';

const useCreateDump = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const handleCreateDump = async (
    text: string,
    attachments: IDraftAttachment[],
    audio: File[],
    collaborators: {
      selectedCollaborators: CollaboratorInterface[];
      invitedEmails: string[];
    },
  ) => {
    if (!currentUser) {
      return;
    }

    setIsBusy(true);

    const newDump = new Dump({
      id: uuidv4().toString(),
      text,
      user_id: currentUser.id,
      created_at: getCurrentUTCDateFormatted(),
      updated_at: getCurrentUTCDateFormatted(),
      is_synced: false,
      is_pinned: false,
    });

    await createDump(newDump);

    if (attachments && attachments.length > 0) {
      await Promise.all(
        attachments.map(async (attachment: IDraftAttachment) => {
          await createDumpGenericAttachment(newDump.id, attachment.file, currentUser.id);
        }),
      );
    }

    if (audio && audio.length > 0) {
      await Promise.all(
        audio.map(async (audioBlob: File) => {
          await createDumpAudioAttachment(newDump.id, audioBlob, currentUser.id);
        }),
      );
    }

    if (collaborators?.selectedCollaborators.length > 0 || collaborators?.invitedEmails.length > 0) {
      await shareDumpWithCollaborators(newDump.id, collaborators.selectedCollaborators, collaborators.invitedEmails);
    }

    await pushUnsyncedDumps();
    pushUnsyncedAttachments();
    await pushUnsyncedCollaborators();
    pushUnsyncedDumpCollaborators();

    trackEvent(TRACKING_EVENT_ENUM.DUMP_CREATED, {
      hasAttachments: (attachments && attachments?.length > 0) || (audio && audio?.length > 0),
      hasImage: attachments && attachments.some((attachment) => isFilePathImage(attachment.file.name)),
      hasDocument: attachments && attachments.some((attachment) => isFilePathGeneric(attachment.file.name)),
      hasAudio: audio && audio.length > 0,
    });

    setIsBusy(false);
  };

  return {
    createDump: handleCreateDump,
    isBusy,
  };
};

export default useCreateDump;

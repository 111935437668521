import { DumpCollaboratorInterface } from '../interfaces/DumpCollaboratorInterface';
import { getCollaboratorById } from './pouchDb/PouchDbCollaboratorService';
import { getDumpCollaboratorsByDumpId } from './pouchDb/PouchDbDumpCollaboratorService';
import { getAllUserProfiles } from './pouchDb/PouchDbUserProfileService';

export async function getEnrichedDumpCollaboratorsByDumpId(dumpId: string): Promise<DumpCollaboratorInterface[]> {
  const docs = await getDumpCollaboratorsByDumpId(dumpId);
  const fetchedCollaborators = docs.filter((collab) => !collab.is_to_be_deleted && !collab._deleted);

  const userProfiles = await getAllUserProfiles();

  return Promise.all(
    fetchedCollaborators.map(async (collaborator) => {
      const _collaborator = await getCollaboratorById(collaborator.collaborator_id);
      return {
        ...collaborator,
        profile: userProfiles.find((profile) => profile.user_id === _collaborator?.user_id),
      };
    }),
  );
}

export async function enrichedDumpCollaborators(
  dumpCollaborators: DumpCollaboratorInterface[],
): Promise<DumpCollaboratorInterface[]> {
  const fetchedCollaborators = dumpCollaborators.filter((collab) => !collab.is_to_be_deleted && !collab._deleted);

  const userProfiles = await getAllUserProfiles();

  return Promise.all(
    fetchedCollaborators.map(async (collaborator) => {
      const _collaborator = await getCollaboratorById(collaborator.collaborator_id);
      return {
        ...collaborator,
        profile: userProfiles.find((profile) => profile.user_id === _collaborator?.user_id),
      };
    }),
  );
}

import PouchDB from 'pouchdb';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';

const userProfilesDB = new PouchDB('user_profiles', { auto_compaction: true });

export async function getUserProfile(userId: string): Promise<UserProfileInterface | null> {
  try {
    const result = await userProfilesDB.find({
      selector: {
        user_id: userId,
      },
    });

    if (!result.docs.length) {
      return null;
    }

    const { ...userData } = result.docs[0];
    return userData as UserProfileInterface;
  } catch (e) {
    return null;
  }
}

export async function upsertUserProfile(userProfile: Partial<UserProfileInterface>) {
  if (!userProfile.user_id) {
    throw new Error('User ID is required');
  }

  try {
    await getUserProfile(userProfile.user_id);
    // Document exists, update it
    const updatedDoc = {
      _id: userProfile.user_id,
      ...userProfile,
    };
    return await userProfilesDB.put(updatedDoc);
  } catch (e) {
    // Document doesn't exist, create new one
    const newDoc = {
      _id: userProfile.user_id,
      ...userProfile,
    };
    return await userProfilesDB.put(newDoc);
  }
}

export async function getUserProfilesByUserIds(userIds: string[]): Promise<UserProfileInterface[]> {
  try {
    const result = await userProfilesDB.find({
      selector: {
        user_id: {
          $in: userIds,
        },
      },
    });

    return result.docs.map((doc) => {
      const { ...userData } = doc;
      return userData as UserProfileInterface;
    });
  } catch (e) {
    return [];
  }
}

// @todo-phil can we just pass in the users we want
export async function getAllUserProfiles(): Promise<UserProfileInterface[]> {
  try {
    const result = await userProfilesDB.find({
      selector: {
        user_id: { $exists: true },
      },
      limit: 1500, // @todo-phil fix
    });
    return result.docs.map((doc) => {
      const { ...userProfileData } = doc;
      return userProfileData as UserProfileInterface;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function clearUserProfilesDB(): Promise<void> {
  await userProfilesDB.destroy();
}

import { captureException } from '@sentry/react';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { getByDumpId, getCommentById } from './pouchDb/PouchDbCommentService';
import { getUserProfilesByUserIds } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';

export async function getEnrichedComments(dumpId: string): Promise<CommentInterface[]> {
  try {
    const docs = await getByDumpId(dumpId);
    const activeComments = docs.filter((comment) => !comment._deleted && !comment.is_to_be_deleted);

    // Get unique user IDs from comments
    const uniqueUserIds = [...new Set(activeComments.map((comment) => comment.user_id))];

    // Fetch user profiles
    const userProfiles = await getUserProfilesByUserIds(uniqueUserIds);

    // Create a map for quick profile lookup
    const profileMap = Object.fromEntries(userProfiles.map((profile) => [profile.user_id, profile]));

    // Enrich comments with user profiles
    return activeComments.map((comment) => ({
      ...comment,
      profile: profileMap[comment.user_id] as CommentInterface['profile'],
    }));
  } catch (error) {
    captureException(error);
    return [];
  }
}

export async function getEnrichedComment(commentId: string): Promise<CommentInterface | null> {
  try {
    const comment = await getCommentById(commentId);
    if (!comment || comment._deleted || comment.is_to_be_deleted) {
      return null;
    }

    // Fetch user profile
    const userProfiles = await getUserProfilesByUserIds([comment.user_id]);

    // Return enriched comment with profile
    return {
      ...comment,
      profile: userProfiles[0] as CommentInterface['profile'],
    };
  } catch (error) {
    captureException(error);
    return null;
  }
}

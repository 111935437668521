import DumpTagList from 'components/dumps/DumpTagList';
import { useNavigate } from 'react-router-dom';
import { IRoute } from 'screens/routes';
import { getUrlDumps } from 'utilities/urls.utils';
import { useSearchStore } from 'store/search.store';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { useEffect, useState } from 'react';
import { extractTags } from 'utilities/dumps/dumps.utils';

const DumpTags = ({ dump }: { dump: DumpInterface }) => {
  const navigate = useNavigate();
  const toggleTag = useSearchStore((state) => state.toggleTag);

  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (dump) {
      setTags(extractTags(dump.text) || []);
    }
  }, [dump]);

  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <div className='flex items-center mb-2'>
      {tags?.length > 0 && (
        <DumpTagList
          tags={tags}
          searchTerm=''
          onToggleTag={(tag) => {
            toggleTag(tag);
            navigate(getUrlDumps(), {
              state: { previousRoute: 'dump_detail' as IRoute },
            });
          }}
        />
      )}
    </div>
  );
};

export default DumpTags;

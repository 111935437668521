export const DATABASE_NAME_INDEX_DB = 'dumpit';

export const NODE_ENVIRONMENT_LOCALHOST = 'LOCALHOST';
export const NODE_ENVIRONMENT_DEVELOPMENT = 'DEV';
export const NODE_ENVIRONMENT_PRODUCTION = 'PRODUCTION';
export const NODE_ENVIRONMENT_STAGING = 'STAGING';
export const NODE_ENVIRONMENT_FEAT = 'FEAT';

export const SUPABASE_EDGE_FUNCTION_DELETE_USER = 'user-self-deletion';
export const SUPABASE_EDGE_FUNCTION_VERIFY_NEW_DEVICE = 'verify-new-device';
export const SUPABASE_EDGE_FUNCTION_NEW_DEVICE_VERIFICATION_CHALLENGE = 'new-device-verification-challenge';

export const SIGNUPS_INVITE_ONLY = 'Sign-ups are currently invite-only';

export const SUPABASE_FUNCTION_GET_DUMP_COLLABORATORS_WITH_PROFILES = 'get_dump_collaborators_with_profiles';

export const SUPABASE_EDGE_FUNCTION_INSERT_COLLABORATOR = 'collaborator-new';

import React from 'react';

const LandingMocks = () => (
  <div className='md:px-0 px-5'>
    <div className='relative flex flex-col items-center md:flex-row md:-ml-28 mt-10'>
      <div className='relative'>
        <img alt='Start writing' src={`${process.env.PUBLIC_URL}/examples/list-macbook.png`} className='w-[1200px]' />
      </div>
      <div>
        <img
          alt='Dumps list'
          src={`${process.env.PUBLIC_URL}/examples/create-iphone.png`}
          className='w-[150px] md:w-[350px] md:absolute md:right-5 md:top-36 md:rotate-12 mt-20 md:mt-0'
        />
      </div>
    </div>
  </div>
);

export default LandingMocks;

import { motion } from 'framer-motion';

const LoadingScreenIndicator = () => (
  <div className='relative flex flex-1 flex-col h-screen items-center justify-center'>
    <motion.div
      className='-mt-20'
      animate={{ opacity: 0.25 }}
      transition={{ ease: 'linear', duration: 1, repeat: Infinity, repeatType: 'reverse' }}
    >
      <img
        alt='loading...'
        src={`${process.env.PUBLIC_URL}/logo/logo1024-transparent_big.png`}
        className='w-[200px] aspect-square object-contain'
      />
    </motion.div>
    <h1 className='flex flex-col justify-center items-center'>
      <span className='font-sans text-5xl leading-tight tracking-tighter font-normal text-zinc-600 dark:text-white line-clamp-none'>
        Think it.
      </span>
      <span className='font-sans text-6xl leading-tight tracking-tighter text-cyan line-clamp-none'>Dump it!</span>
    </h1>
  </div>
);

export default LoadingScreenIndicator;

import React from 'react';

import findChildByRole from 'utilities/findChildByRole';
import Content from './subComponents/Content';
import Footer from './subComponents/Footer';
import Top from './subComponents/Top';
import classNames from 'classnames';

interface ContentLayoutInterface {
  children: React.ReactNode;
  hasHScreen?: boolean;
}

const ContentLayout = ({ children, hasHScreen }: ContentLayoutInterface) => {
  const top = findChildByRole(children, 'ContentLayout.Top');
  const content = findChildByRole(children, 'ContentLayout.Content');
  const footer = findChildByRole(children, 'ContentLayout.Footer');

  const className = classNames(
    'flex flex-col bg-bright px-5 md:px-10 dark:bg-dark w-full sm:max-sm:w-full md:max-md:w-[768px] lg:w-[1024px] md:m-auto',
    {
      'h-screen': hasHScreen,
    },
  );

  return (
    <div className={className}>
      {top && (
        <div className='md:pt-5'>
          <div className='m-auto'>{top}</div>
        </div>
      )}
      <main className='flex-1 py-3 md:py-6 md:pt-2 h-full flex flex-col flex-grow'>{content}</main>
      {footer && <footer className='m-auto w-full'>{footer}</footer>}
    </div>
  );
};

export default Object.assign(ContentLayout, { Top, Content, Footer });

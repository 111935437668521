import Big from 'big.js';

export class Color {
  constructor(
    public r: number,
    public g: number,
    public b: number,
  ) {}

  static flatModernColorFrom(str: string): string {
    let hash = new Big(216613261); // FNV-1a hash offset
    const prime = new Big(16777619);
    const maxUint32 = new Big(2).pow(32); // Keep values within 32-bit range

    for (let i = 0; i < str.length; i++) {
      // Ensure XOR result is treated as an unsigned 32-bit integer
      const intHash = (hash.toNumber() ^ str.charCodeAt(i)) >>> 0; // Force unsigned
      hash = new Big(intHash).times(prime).mod(maxUint32);
    }

    const hue = hash.mod(360).toNumber(); // Ensure hue is within valid range

    return `hsl(${hue}, 50%, 55%)`;
  }

  toString(): string {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }
}

import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { OWNERSHIP_FILTER, TAG_STATE } from '../constants/tag.constants';

export type ISearchStore = {
  selectedTag: string | null;
  ownershipFilter: OWNERSHIP_FILTER;
  tags: Record<string, TAG_STATE>;
  users: Record<string, TAG_STATE>;
  setSelectedTag: (selectedTags: string | null) => void;
  setOwnershipFilter: (ownershipFilter: OWNERSHIP_FILTER) => void;
  setTags: (newTags: string[]) => void;
  setUsers: (newUsers: string[]) => void;
  toggleTag: (tag: string) => void;
  toggleUser: (user: string) => void;
};

export const useSearchStore = create<ISearchStore>((set) => ({
  selectedTag: null,
  ownershipFilter: OWNERSHIP_FILTER.ALL,
  tags: {},
  users: {},
  setSelectedTag: (selectedTag) =>
    set(() => ({
      selectedTag,
    })),
  setOwnershipFilter: (ownershipFilter) =>
    set(() => ({
      ownershipFilter,
    })),
  toggleTag: (tag: string) =>
    set((state) => {
      const currentStatus = state.tags[tag];

      let newState;
      if (currentStatus === TAG_STATE.SELECTED) {
        newState = TAG_STATE.UNSELECTED;
      } else if (currentStatus === TAG_STATE.UNSELECTED) {
        newState = TAG_STATE.DEFAULT;
      } else {
        newState = TAG_STATE.SELECTED;
      }

      return {
        tags: { ...state.tags, [tag]: newState },
      };
    }),
  toggleUser: (user: string) =>
    set((state) => {
      const currentStatus = state.users[user];

      let newState;
      if (currentStatus === TAG_STATE.SELECTED) {
        newState = TAG_STATE.UNSELECTED;
      } else if (currentStatus === TAG_STATE.UNSELECTED) {
        newState = TAG_STATE.DEFAULT;
      } else {
        newState = TAG_STATE.SELECTED;
      }

      return {
        users: { ...state.users, [user]: newState },
      };
    }),
  setTags: (newTags) =>
    set((state) => {
      const tags = newTags.reduce(
        (acc, tag) => {
          acc[tag] = state.tags?.[tag] || TAG_STATE.DEFAULT;
          return acc;
        },
        {} as Record<string, TAG_STATE>,
      );
      return { tags };
    }),
  setUsers: (newUsers) =>
    set((state) => {
      const users = newUsers.reduce(
        (acc, user) => {
          acc[user] = state.users?.[user] || TAG_STATE.DEFAULT;
          return acc;
        },
        {} as Record<string, TAG_STATE>,
      );
      return { users };
    }),
}));

mountStoreDevtool('SearchStore', useSearchStore);

import { DevicePhoneMobileIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { Button, Heading } from 'components/common/ComponentLibrary';
import { useSearchParams } from 'react-router-dom';
import { getUrlCollaboratorInvitationApp } from 'utilities/urls.utils';

const CollaboratorInvitationDeviceChoice = ({ onContinue }: { onContinue: () => void }) => {
  const [searchParams] = useSearchParams();
  const collaboratorId = searchParams.get('collaborator_id');

  const handleOpenInApp = () => {
    window.location.replace(getUrlCollaboratorInvitationApp(collaboratorId!));
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='max-w-xl w-full p-5 space-y-10 border-1 border-dark/10 dark:border-bright/10 text-dark dark:text-white bg-bright dark:bg-white/5 rounded-2xl'>
        <Heading size='xxl' textAlign='center'>
          Collaborate on Dump it!
        </Heading>
        <div className='space-y-10 flex flex-col items-center justify-center'>
          <Button onClick={handleOpenInApp} size='l'>
            <Button.Icon icon={<DevicePhoneMobileIcon className='size-6' />} />
            Open in Mobile App
          </Button>

          <Button onClick={onContinue} size='l'>
            <Button.Icon icon={<GlobeAltIcon className='size-6' />} />
            Continue in Browser
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorInvitationDeviceChoice;

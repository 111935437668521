import isUrl from 'is-url';

export const isValidUrl = (url: string) => {
  if (!url) return false;

  return isUrl(cleanUrl(url));
};

export const cleanUrl = (url: string) => {
  if (!url) return '';
  if (!url.includes('http')) {
    url = `https://${url.trim()}`;
  }

  return url;
};

export const isString = (string: any) => typeof string === 'string';

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const shortenString = (string: string, length: number) =>
  string.length > length ? `${string.slice(0, length - 1)}...` : string;

export const getInitials = (input?: string): string => {
  if (!input) return '';

  // Remove email domain if present
  input = input.split('@')[0];

  // Remove anything inside parentheses
  input = input.replace(/\([^)]*\)/g, '').trim();

  // Normalize separators (replace _, -, . with spaces)
  input = input.replace(/[_\-.]+/g, ' ').trim();

  // Split into words, filtering out empty parts
  const words = input.split(/\s+/).filter(Boolean);

  // Get initials from first and last meaningful words
  if (words.length >= 2) {
    return (words[0][0] + words[words.length - 1][0]).toUpperCase();
  }

  // If only one word, take the first two letters
  return words[0]?.slice(0, 2).toUpperCase() || '';
};

export const truncateString = (string: string, length: number, separator = '…') => {
  if (string.length <= length) return string;

  separator = separator || '…';

  const sepLen = separator.length;
  const charsToShow = length - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return string.substring(0, frontChars) + separator + string.substring(string.length - backChars);
};

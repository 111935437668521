import React, { useRef, useState } from 'react';
import { Button } from 'components/common/ComponentLibrary';
import DumpTags from 'components/dumps/DumpTags';
import DumpEditAttachments from './DumpEditAttachments';
import { Form, useField, useFormikContext } from 'formik';
import { FormikDumpTextareaField } from 'components/common/Formik';
import DumpEditActions from './DumpEditActions';
import { IDump } from 'models/dump.model';
import { useHotkeys } from 'react-hotkeys-hook';
import { useFileDragger } from 'hooks/common/useFileDragger';
import { MAX_ATTACHMENTS_ALLOWED, MAX_ATTACHMENTS_REACHED_MESSAGE } from 'constants/attachment.constants';
import { toast } from 'react-toastify';
import { getDraftAttachmentsFromFiles, handleFilesRejected } from 'utilities/dumps/dumpAttachment.utils';
import useDeleteDump from 'hooks/dumps/useDeleteDump';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { AudioRecorder } from 'components/common/AudioRecorder';
import { useFilePaste } from 'hooks/common/useFilePaste';
import { useNavigate } from 'react-router-dom';
import { getUrlDumps } from 'utilities/urls.utils';
import { useTranslation } from 'react-i18next';

const DumpEditForm = ({ dump, onSave, onCancel }: { dump: IDump; onSave: any; onCancel: any }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [fieldAttachments, _, helpersAttachments] = useField('attachments');
  const { t } = useTranslation('translations');

  const { dirty, isSubmitting, submitForm } = useFormikContext();

  const { deleteDump } = useDeleteDump(dump);

  const [fieldText] = useField('text');

  const isEmpty = fieldAttachments?.value?.length === 0 && fieldText?.value === '';

  const navigate = useNavigate();

  const validateAndAddAttachments = async (files: File[]) => {
    const currentAttachmentCount = fieldAttachments.value?.length || 0;
    const totalAttachments = currentAttachmentCount + files.length;

    if (totalAttachments > MAX_ATTACHMENTS_ALLOWED) {
      toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
      return false;
    }

    const newAttachments = await getDraftAttachmentsFromFiles(files);
    helpersAttachments.setValue([...fieldAttachments.value, ...newAttachments]);
    return true;
  };

  useHotkeys(
    'meta+enter',
    () => {
      if (isEmpty) {
        deleteDump();
        navigate(getUrlDumps());
        return;
      }

      if (dirty) {
        return submitForm();
      }
    },
    {
      enableOnFormTags: true,
    },
    [dirty],
  );

  const draggingContextRef = useRef(null);
  useFileDragger({
    contextRef: draggingContextRef,
    onFilesSuccessfullyDropped: async (droppedFiles) => {
      await validateAndAddAttachments(droppedFiles);
    },
    onFilesRejected: handleFilesRejected,
  });

  useFilePaste({
    onFileSuccessfullyPasted: async (file) => {
      await validateAndAddAttachments([file]);
    },
    onFileRejected: handleFilesRejected,
  });

  return (
    <div
      ref={draggingContextRef}
      className='flex flex-1 flex-col md:justify-end md:items-center md:bg-bright md:dark:bg-dark'
    >
      <Form className='flex flex-1 flex-col w-full'>
        <DumpTags dump={dump} />

        <div className='relative w-full flex flex-1 flex-col md:dark:bg-paperdark md:border-1 md:border-t-1 border-gray-200 md:p-5 dark:border-bright/25 md:bg-white rounded-xl'>
          <FormikDumpTextareaField name='text' placeholder={t('screens.dump.placeholder')} />

          <div className='flex flex-row space-x-2'>
            <DumpEditAttachments />
          </div>

          <div className='absolute right-4 bottom-2'>
            {isRecording && (
              <MotionEffectPopIn>
                <AudioRecorder
                  onDone={() => setIsRecording(false)}
                  onSave={async (audio) => {
                    await validateAndAddAttachments([audio]);
                  }}
                />
              </MotionEffectPopIn>
            )}
            {!isRecording && <DumpEditActions dump={dump} onRecord={() => setIsRecording(true)} />}
          </div>
        </div>
        <div className='flex w-full space-x-5 items-center mt-5'>
          <Button
            size='l'
            width='full'
            onClick={onCancel}
            color='red'
            status={!dirty || isSubmitting ? 'disabled' : ''}
          >
            Cancel
          </Button>
          {isEmpty && (
            <Button
              size='l'
              width='full'
              color='red'
              type='submit'
              onClick={() => {
                deleteDump();
                navigate(getUrlDumps());
              }}
            >
              Delete
            </Button>
          )}
          {!isEmpty && (
            <Button
              size='l'
              width='full'
              color='default'
              type='submit'
              status={!dirty || isSubmitting ? 'disabled' : ''}
              onClick={onSave}
            >
              Save
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default DumpEditForm;

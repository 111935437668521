import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { SupabaseCommentService } from './supabase/SupabaseCommentService';
import { CommentInterface } from '../interfaces/CommentInterface';
import {
  getLocalStorageLastSyncedComments,
  setLocalStorageLastSyncedComments,
} from 'models/application/services/LocalStorageService';
import { getAllUnsyncedComments, upsertComment } from './pouchDb/PouchDbCommentService';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { updateDumpLatestCommentDate } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';

export async function pushUnsyncedComments(): Promise<boolean> {
  const unsyncedComments = await getAllUnsyncedComments();
  if (unsyncedComments && unsyncedComments.length > 0) {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    await Promise.all(
      unsyncedComments.map(async (unsyncedComment) => {
        try {
          const asComment = {
            ...unsyncedComment,
            updated_at: getCurrentUTCDateFormatted(),
            _deleted: unsyncedComment.is_to_be_deleted,
          };

          await upsertComment(asComment);

          delete asComment.is_synced;
          delete asComment.is_to_be_deleted;

          const supabaseCommentService = new SupabaseCommentService(supabase);
          const result = await supabaseCommentService.upsert(asComment);
          if (!result) {
            return;
          }

          await updateDumpLatestCommentDate(unsyncedComment.dump_id, asComment.updated_at);
        } catch (e) {
          captureMessage(`pushUnsyncedComments: userId[${currentProfileId}], commentId[${unsyncedComment.id}].`);
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestComments(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseCommentService = new SupabaseCommentService(supabase);
    let hasMoreComments = true;
    let lastSyncedAt = getLocalStorageLastSyncedComments();

    while (hasMoreComments) {
      const comments = await supabaseCommentService.getAll(lastSyncedAt);
      if (!comments || comments.length === 0) {
        break;
      }

      await Promise.all(
        comments.map(async (_comment: CommentInterface) => {
          const comment: CommentInterface = {
            id: _comment.id,
            dump_id: _comment.dump_id,
            text: _comment.text,
            user_id: _comment.user_id,
            created_at: _comment.created_at,
            updated_at: _comment.updated_at,
            comment_ref: _comment.comment_ref,
            _deleted: _comment._deleted,
            is_synced: true,
          };

          await upsertComment(comment);

          // Only update lastSyncedAt if this comment's updated_at is more recent
          if (!lastSyncedAt || comment.updated_at > lastSyncedAt) {
            lastSyncedAt = comment.updated_at;
            setLocalStorageLastSyncedComments(comment.updated_at);
          }

          await updateDumpLatestCommentDate(comment.dump_id, comment.updated_at);
        }),
      );

      hasMoreComments = comments.length >= SUPABASE_MAX_PER_PAGE;
    }

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

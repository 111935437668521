import { Link, useLocation } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import { Text } from 'components/common/ComponentLibrary';
import { OWNERSHIP_FILTER } from 'constants/tag.constants';

const EmptyDumpsList = ({ currentOwnershipFilter }: { currentOwnershipFilter: OWNERSHIP_FILTER }) => {
  const location = useLocation();
  return (
    <div className='flex flex-col flex-1 justify-center'>
      <Text size='xxl' color='gray-500' textAlign='center' spacing='s'>
        {currentOwnershipFilter === OWNERSHIP_FILTER.SHARED
          ? 'No Dumps have been shared with you'
          : 'Your Dumps will be listed here'}
      </Text>
      {currentOwnershipFilter !== OWNERSHIP_FILTER.SHARED && (
        <Link
          to={getUrlHome()}
          state={{ previousRoute: location.pathname }}
          className='flex flex-col gap-5 items-center justify-center'
        >
          <Text size='xxl' color='cyan' textAlign='center'>
            Start writing...
          </Text>
        </Link>
      )}
    </div>
  );
};

export default EmptyDumpsList;

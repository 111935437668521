import { Formik } from 'formik';
import { Button } from 'components/common/ComponentLibrary';
import { FormikTextareaField } from 'components/common/Formik';
import { Form } from 'formik';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import * as yup from 'yup';
import { pushUnsyncedComments } from 'models/comments/services/CommentSyncService';
import { updateComment } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { useTranslation } from 'react-i18next';

interface CommentItemProps {
  comment: CommentInterface;
  onCancel: () => void;
  onUpdate: (text: string) => void;
}

const CommentItemEdit = ({ comment, onCancel, onUpdate }: CommentItemProps) => {
  const { t } = useTranslation('translations');

  const validationSchema = yup.object().shape({
    text: yup.string().trim().required(t('comments.validation.required')),
  });

  return (
    <div className='flex gap-2 pr-2 flex-row-reverse ml-auto'>
      <div className='bg-cyan/50 text-primary-foreground rounded-xl p-5'>
        <Formik
          onSubmit={async ({ text }) => {
            onUpdate(text);
            updateComment(comment.id, { text });
            pushUnsyncedComments();
          }}
          initialValues={{ text: comment.text }}
          validateOnBlur={true}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className='space-y- w-full'>
                <FormikTextareaField name='text' placeholder={t('comments.enterComment')} />
                <div className='flex justify-between'>
                  <Button size='s' onClick={onCancel} theme='bare' color='white'>
                    {t('comments.cancel')}
                  </Button>
                  <Button size='s' type='submit' status={isSubmitting ? 'busy' : ''} color='white'>
                    {t('comments.update')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CommentItemEdit;

import { Avatar, Text } from 'components/common/ComponentLibrary';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { useProfileStore } from 'store/profile.store';
import { Color } from 'utilities/common/colors.utils';
import { getInitials } from 'utilities/common/strings.utils';
import { getPublicUrl } from 'utilities/storage.utils';

const CollaboratorsManageListPreview = ({
  selectedCollaborators,
  invitedEmails,
}: {
  selectedCollaborators?: CollaboratorInterface[];
  invitedEmails?: string[];
}) => {
  if (!selectedCollaborators?.length && !invitedEmails?.length) {
    return null;
  }

  const profile = useProfileStore((state) => state.profile);

  return (
    <div className='flex flex-col gap-2'>
      <Text size='s' fontWeight='semibold'>
        Manage access
      </Text>

      <div className='flex items-center gap-2'>
        <div title='You'>
          <Avatar
            src={profile?.image_path ? getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS) : null}
            alt={profile?.first_name || 'You'}
            size='s'
            initials={
              profile?.first_name && profile?.last_name
                ? getInitials(`${profile.first_name} ${profile.last_name}`)
                : undefined
            }
            backgroundColor={Color.flatModernColorFrom(profile?.id || '')}
          />
        </div>
        {selectedCollaborators?.map((collaborator) => (
          <div title={collaborator.profile?.first_name || collaborator.email} key={collaborator.id}>
            {collaborator.profile?.first_name ? (
              <Avatar
                src={
                  collaborator.profile?.image_path
                    ? getPublicUrl(collaborator.profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                    : null
                }
                alt={collaborator.profile?.first_name || 'Dumper'}
                size='s'
                initials={
                  collaborator.profile?.first_name && collaborator.profile?.last_name
                    ? getInitials(`${collaborator.profile.first_name} ${collaborator.profile.last_name}`)
                    : undefined
                }
                backgroundColor={Color.flatModernColorFrom(collaborator.user_id || '')}
              />
            ) : (
              <Avatar
                src={undefined}
                alt={collaborator.email}
                size='s'
                initials={getInitials(collaborator.email)}
                backgroundColor={Color.flatModernColorFrom(collaborator.email || '')}
              />
            )}
          </div>
        ))}
        {invitedEmails?.map((invitedEmail) => (
          <div key={invitedEmail} title={invitedEmail}>
            <Avatar
              src={undefined}
              alt={invitedEmail}
              size='s'
              initials={getInitials(invitedEmail)}
              backgroundColor={Color.flatModernColorFrom(invitedEmail || '')}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollaboratorsManageListPreview;

import { v4 as uuidv4 } from 'uuid';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { TagInterface } from 'models/dumps/interfaces/TagInterface';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

// Register the find plugin
PouchDB.plugin(PouchDBFind);

// Create PouchDB instance with auto compaction
const tagsDB = new PouchDB('tags', { auto_compaction: true });

export async function createTags(tags: string[], dumpId: string): Promise<void> {
  if (!tags || tags.length === 0) {
    return;
  }

  for (const tag of tags) {
    const id = uuidv4().toString();
    const newTag: TagInterface = {
      id,
      name: tag,
      dump_id: dumpId,
      created_at: getCurrentUTCDateFormatted(),
      updated_at: getCurrentUTCDateFormatted(),
    };

    await tagsDB.put({
      _id: id,
      ...newTag,
    });
  }
}

export async function deleteTagsByDumpId(dumpId: string): Promise<void> {
  // Find all docs with matching dump_id
  const result = await tagsDB.find({
    selector: {
      dump_id: dumpId,
    },
  });

  // Delete each matching document
  for (const doc of result.docs) {
    await tagsDB.remove(doc);
  }
}

export async function getAllTags(): Promise<TagInterface[]> {
  try {
    const result = await tagsDB.find({
      selector: {
        id: { $exists: true },
        name: { $exists: true },
        dump_id: { $exists: true },
      },
      limit: 500, // @todo-phil fix
    });

    return result.docs.map((doc) => {
      const { _id, _rev, ...tagData } = doc;
      return tagData as TagInterface;
    });
  } catch (error) {
    return [];
  }
}

export function listenToTags(): Promise<TagInterface[]> & {
  onChange: (callback: (tags: TagInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getAllTags();
  const listeners: ((tags: TagInterface[]) => void)[] = [];

  const changes = tagsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
    })
    .on('change', async () => {
      const tags = await getAllTags();
      listeners.forEach((listener) => listener(tags));
    });

  return Object.assign(promise, {
    onChange: (callback: (tags: TagInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

// Create index for querying by dump_id
tagsDB.createIndex({
  index: {
    fields: ['dump_id'],
  },
});

export async function clearTagsDB(): Promise<void> {
  await tagsDB.destroy();
}

import { useState, useEffect } from 'react';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import supabase from 'store/supabase/supabase';
import { captureException } from '@sentry/react';
import { pullLatestComments } from 'models/comments/services/CommentSyncService';
import { listenToComments } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { TABLE_NAME_COMMENTS } from 'models/comments/services/supabase/SupabaseCommentService';

export const useComments = (dumpId: string) => {
  const [comments, setComments] = useState<CommentInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const commentsPromise = listenToComments(dumpId);

    // Handle initial load
    commentsPromise
      .then((initialComments) => {
        setComments(initialComments);
        setIsLoading(false);
      })
      .catch((err) => {
        captureException(err);
        setIsLoading(false);
      });

    // Handle subsequent changes
    commentsPromise.onChange((updatedComments) => {
      setComments(updatedComments);
    });

    // Set up realtime subscription for Supabase
    const channel = supabase
      .channel('comments-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: TABLE_NAME_COMMENTS,
          filter: `dump_id=eq.${dumpId}`,
        },
        async () => {
          await pullLatestComments();
        },
      )
      .subscribe();

    return () => {
      commentsPromise.cleanup();
      supabase.removeChannel(channel);
      setIsLoading(false);
    };
  }, [dumpId]);

  return {
    comments,
    isLoading,
  };
};

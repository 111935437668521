import React, { createContext, useContext, ReactNode } from 'react';
import useDumpCollaboratorsListener from 'hooks/collaborators/useDumpCollaboratorsListener';
import useAttachmentsListener from 'hooks/dumps/useAttachmentsListener';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import useDumps from 'hooks/dumps/useDumps';
import { useAuthStore } from 'store/auth.store';
import { CurrentUser } from 'models/current-user.model';

interface DumpsContextType {
  dumps: DumpInterface[] | [];
  groupedAttachments: Record<string, AttachmentInterface[]>;
  groupedCollaborators: Record<string, DumpCollaboratorInterface[]>;
  isLoading: boolean;
  error: Error | undefined;
}

const DumpsContext = createContext<DumpsContextType | undefined>(undefined);

interface DumpsProviderProps {
  children: ReactNode;
}

export const DumpsProvider: React.FC<DumpsProviderProps> = ({ children }) => {
  const [currentUser] = useAuthStore((state: { currentUser: CurrentUser | null }) => [state.currentUser]);

  const { dumps, isLoading: isDumpsLoading, error: dumpsError } = useDumps();
  const {
    groupedCollaborators,
    isLoading: isCollaboratorsLoading,
    error: collaboratorsError,
  } = useDumpCollaboratorsListener();
  const { groupedAttachments, isLoading: isAttachmentsLoading, error: attachmentsError } = useAttachmentsListener();

  const isLoading = currentUser ? isDumpsLoading || isCollaboratorsLoading || isAttachmentsLoading : false;
  const error = currentUser ? dumpsError || collaboratorsError || attachmentsError : undefined;

  const value = {
    dumps: currentUser ? dumps : [],
    groupedAttachments: currentUser ? groupedAttachments : {},
    groupedCollaborators: currentUser ? groupedCollaborators : {},
    isLoading,
    error,
  };

  return <DumpsContext.Provider value={value}>{children}</DumpsContext.Provider>;
};

export const useDumpsContext = () => {
  const context = useContext(DumpsContext);
  if (context === undefined) {
    throw new Error('useDumpsContext must be used within a DumpsProvider');
  }
  return context;
};

import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { Color } from 'utilities/common/colors.utils';

const CommentItemReplyPreview = ({ comment }: { comment?: CommentInterface }) => {
  if (!comment) {
    return (
      <div className='mb-2 py-2 px-4 bg-zinc-100 dark:bg-neutral-600 rounded-lg'>
        <span className='text-sm italic text-gray-400 dark:text-gray-400'>Comment was deleted</span>
      </div>
    );
  }

  return (
    <div
      className='[word-break:break-word] [overflow-wrap:break-word] whitespace-pre-wrap mb-2 py-2 px-4 bg-zinc-100 dark:bg-neutral-600 rounded-lg flex items-start justify-between cursor-pointer hover:bg-zinc-200 dark:hover:bg-neutral-500 transition-colors'
      style={{
        borderLeft: `5px solid ${Color.flatModernColorFrom(comment.profile?.user_id || '')}`,
      }}
    >
      <div className='flex flex-col'>
        {comment.profile?.first_name && (
          <span
            className='text-sm font-semibold'
            style={{ color: Color.flatModernColorFrom(comment.profile?.user_id || '') }}
          >
            {comment.profile?.first_name} {comment.profile?.last_name}
          </span>
        )}
        <span className='text-sm text-gray-500 dark:text-gray-300 line-clamp-2'>{comment.text}</span>
      </div>
    </div>
  );
};

export default CommentItemReplyPreview;

import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { pushUnsyncedDumps } from './DumpSyncService';
import { pushUnsyncedDumpCollaborators } from 'models/collaborators/services/DumpCollaboratorSyncService';
import { DumpInterface } from '../interfaces/DumpInterface';
import { getLatestCommentByDumpId } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { getDumpById, getAllDumps as pouchdbGetAllDumps, updateDump } from './pouchDb/PouchDbDumpService';
import {
  deleteDumpCollaborator,
  getDumpCollaboratorByDumpIdAndUserId,
} from 'models/collaborators/services/pouchDb/PouchDbDumpCollaboratorService';
import { getCurrentProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';

export async function getAllDumps(): Promise<DumpInterface[]> {
  const dumpDocuments = await pouchdbGetAllDumps();
  const dumps = await Promise.all(
    dumpDocuments.map(async (dump) => {
      const latestComment = await getLatestCommentByDumpId(dump.id);
      return {
        ...dump,
        latest_comment_at: latestComment ? latestComment.created_at : undefined,
      };
    }),
  );

  return dumps;
}

export async function deleteDump(dumpId: string) {
  const existingDump = await getDumpById(dumpId);

  if (existingDump && existingDump) {
    const currentProfile = await getCurrentProfile();
    const dump = existingDump;
    const isMyDump = dump.user_id === currentProfile?.id;

    try {
      if (isMyDump) {
        await updateDump(dumpId, {
          is_synced: false,
          is_to_be_deleted: true,
          updated_at: getCurrentUTCDateFormatted(),
        });
        await pushUnsyncedDumps();
      } else {
        if (!currentProfile?.id) {
          return;
        }

        const dumpCollaborator = await getDumpCollaboratorByDumpIdAndUserId(dumpId, currentProfile.id);
        if (dumpCollaborator) {
          await deleteDumpCollaborator(dumpId, dumpCollaborator.collaborator_id!);
          await pushUnsyncedDumpCollaborators();
        }
        await updateDump(dumpId, {
          _deleted: true,
          updated_at: getCurrentUTCDateFormatted(),
        });
      }
    } catch (e) {
      await updateDump(dumpId, {
        is_synced: false,
        updated_at: getCurrentUTCDateFormatted(),
      });
    }
  }
}

export async function pinDump(dumpId: string, isPinned: boolean) {
  const existingDump = await getDumpById(dumpId);
  if (existingDump) {
    try {
      await updateDump(dumpId, {
        is_pinned: isPinned,
        updated_at: getCurrentUTCDateFormatted(),
        is_synced: false,
      });

      await pushUnsyncedDumps();
    } catch (e) {
      await updateDump(dumpId, {
        is_synced: false,
        updated_at: getCurrentUTCDateFormatted(),
      });
    }
  }
}

import { useEffect, useState } from 'react';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';
import { getEnrichedDumpCollaboratorsByDumpId } from 'models/collaborators/services/DumpCollaboratorHelperService';

const useDumpDumpCollaborators = (dumpId: string) => {
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dumpCollaborators, setDumpCollaborators] = useState<DumpCollaboratorInterface[]>([]);

  // Initial data fetch
  useEffect(() => {
    const fetchDumpCollaborators = async () => {
      setIsLoading(true);
      try {
        setDumpCollaborators(await getEnrichedDumpCollaboratorsByDumpId(dumpId));
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDumpCollaborators();
  }, [dumpId]);

  return {
    isLoading,
    error,
    dumpCollaborators,
  };
};

export default useDumpDumpCollaborators;

import React, { ComponentPropsWithoutRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, PhotoIcon, MicrophoneIcon, DocumentIcon, EyeIcon } from '@heroicons/react/24/outline';
import { getUrlDump } from 'utilities/urls.utils';
import { Text, Tooltip } from 'components/common/ComponentLibrary';
import pluralize from 'pluralize';
import { useContextMenu } from 'use-context-menu';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import JoinChildren from 'components/common/JoinChildren';
import DumpRowContextMenu from 'components/dumps/DumpList/DumpRowContextMenu';
import DumpRowContents from './DumpRowContents';
import { useProfileStore } from 'store/profile.store';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getAttachmentFilePath } from 'utilities/common/file.utils';
import { DumpRowCollaboratorsFacepile } from '../DumpRowCollaboratorsFacepile';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import './DumpRow.css';
import 'use-context-menu/styles.css';

type Props = {
  searchTerm: string;
  dump: DumpInterface;
  dumpCollaborators?: DumpCollaboratorInterface[];
  attachments?: AttachmentInterface[];
} & ComponentPropsWithoutRef<'li'>;

const DumpRow: React.FC<Props> = (props) => {
  const { dump, searchTerm, style, dumpCollaborators, attachments, ...listItemProps } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { contextMenu, onContextMenu } = useContextMenu(<DumpRowContextMenu dump={dump} />);
  const profile = useProfileStore((state) => state.profile);

  return (
    <li
      onContextMenu={onContextMenu}
      {...listItemProps}
      style={
        isHovered
          ? {
              ...style,
              backgroundColor: 'rgba(92,202,190,0.25)',
            }
          : style
      }
    >
      {contextMenu}
      <Link
        to={getUrlDump(dump)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        className={isHovered ? 'active' : ''}
      >
        <div className='row-link-container'>
          <div className='flex flex-col space-y-2'>
            <DumpRowContents dump={dump} attachments={attachments} searchTerm={searchTerm} />
            {((attachments && attachments?.length > 0) ||
              (dumpCollaborators && dumpCollaborators.length > 0) ||
              dump.latest_comment_at) && (
              <>
                <JoinChildren
                  separator={
                    <span key='separator' className='text-gray-400 dark:text-gray-600'>
                      &middot;
                    </span>
                  }
                >
                  {dumpCollaborators && dumpCollaborators.length > 0 && (
                    <DumpRowCollaboratorsFacepile dump={dump} dumpCollaborators={dumpCollaborators} />
                  )}
                  {dump.latest_comment_at && (
                    <Tooltip
                      size='s'
                      spacing='s'
                      target={<ChatBubbleLeftIcon className='w-5 text-gray-400 dark:text-gray-600' />}
                      scope='local'
                    >
                      <Text>Comments</Text>
                    </Tooltip>
                  )}
                  {dump.user_id !== profile?.id && (
                    <>
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<EyeIcon className={`text-gray-400 dark:text-gray-600 w-5`} />}
                        scope='local'
                      >
                        <Text>This is a view only, shared Dump</Text>
                      </Tooltip>
                    </>
                  )}
                  {dump.text?.trim() &&
                    attachments &&
                    attachments?.filter((attachment) => isFilePathImage(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<PhotoIcon className='w-5 text-gray-400 dark:text-gray-600' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Images',
                          attachments.filter((attachment) => isFilePathImage(getAttachmentFilePath(attachment))).length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {dump.text?.trim() &&
                    attachments &&
                    attachments?.filter((attachment) => isFilePathAudio(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<MicrophoneIcon className='w-5 text-gray-400 dark:text-gray-600' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Audio clip',
                          attachments.filter((attachment) => isFilePathAudio(getAttachmentFilePath(attachment))).length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {dump.text?.trim() &&
                    attachments &&
                    attachments?.filter((attachment) => isFilePathGeneric(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<DocumentIcon className='w-5 text-gray-400 dark:text-gray-600' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Documents',
                          attachments.filter((attachment) => isFilePathGeneric(getAttachmentFilePath(attachment)))
                            .length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                </JoinChildren>
              </>
            )}
          </div>
          <div className='w-6'>
            <ChevronRightIcon className='w-6 text-black dark:text-white' />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default DumpRow;

import { DevicePhoneMobileIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { Button, Heading } from 'components/common/ComponentLibrary';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMobileApp, getUrlHome } from 'utilities/urls.utils';

const SharedDumpDeviceChoice = ({ onContinue }: { onContinue: () => void }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { dumpId } = useParams();

  if (!dumpId && !searchParams.get('collaborator_id')) {
    navigate(getUrlHome());
    return;
  }

  const openMobileApp = () => {
    if (dumpId) {
      const mobileAppUrl = getMobileApp('dumps', { id: dumpId });
      window.location.href = mobileAppUrl;
      return;
    }

    const collaboratorId = searchParams.get('collaborator_id');

    if (collaboratorId) {
      const mobileAppUrl = getMobileApp('invitation', { collaborator_id: collaboratorId });
      window.location.href = mobileAppUrl;
      return;
    }

    throw new Error('No dump id or collaborator id found');
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='p-5 space-y-10 border-1 border-dark/10 dark:border-bright/10 text-dark dark:text-white bg-bright dark:bg-white/5 rounded-2xl'>
        <Heading size='xxl' textAlign='center'>
          Continue to Dump
        </Heading>
        <div className='space-y-10 flex flex-col items-center justify-center m-auto w-full'>
          <Button onClick={openMobileApp} size='l'>
            <Button.Icon icon={<DevicePhoneMobileIcon className='size-6' />} />
            Open in Mobile App
          </Button>

          <Button onClick={onContinue} size='l'>
            <Button.Icon icon={<GlobeAltIcon className='size-6' />} />
            Continue in Browser
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SharedDumpDeviceChoice;

import React from 'react';
import { Text, Input, PersonItem } from 'components/common/ComponentLibrary';
import { useAuthStore } from 'store/auth.store';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { isValidEmail } from 'utilities/common/validation.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getPublicUrl } from 'utilities/storage.utils';
import { Color } from 'utilities/common/colors.utils';

interface CollaboratorsListProps {
  collaborators: CollaboratorInterface[];
  selectedCollaborators: CollaboratorInterface[];
  onToggleCollaborator: (collaborator: CollaboratorInterface) => void;
  onInvite: (email: string) => void;
}

const CollaboratorsList = ({
  collaborators,
  selectedCollaborators,
  onToggleCollaborator,
  onInvite,
}: CollaboratorsListProps) => {
  const [currentUser] = useAuthStore((state) => [state.currentUser]);
  const [searchQuery, setSearchQuery] = React.useState('');

  const sortedCollaborators = [
    ...collaborators.filter(
      (collaborator) =>
        collaborator.user_id !== currentUser?.id &&
        !selectedCollaborators.some((c) => c.id === collaborator.id) &&
        collaborator.user_id,
    ),
    ...collaborators.filter(
      (collaborator) =>
        collaborator.user_id !== currentUser?.id &&
        !selectedCollaborators.some((c) => c.id === collaborator.id) &&
        !collaborator.user_id,
    ),
  ];

  // Add filter logic
  const filteredCollaborators = sortedCollaborators.filter((collaborator) => {
    const searchTerm = searchQuery.toLowerCase();
    if (collaborator.user_id) {
      return (
        collaborator.profile?.first_name?.toLowerCase().includes(searchTerm) ||
        collaborator.profile?.last_name?.toLowerCase().includes(searchTerm) ||
        collaborator.email?.toLowerCase().includes(searchTerm)
      );
    }
    return collaborator.email?.toLowerCase().includes(searchTerm);
  });

  const showInviteOption =
    searchQuery &&
    isValidEmail(searchQuery) &&
    !filteredCollaborators.length &&
    !collaborators.some((collaborator) => collaborator.email?.toLowerCase() === searchQuery.toLowerCase());

  return (
    <div className='w-full'>
      <div className='mb-4'>
        <Input
          size='s'
          type='text'
          placeholder='Search or invite by email'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && showInviteOption) {
              onInvite(searchQuery);
              setSearchQuery('');
            }
          }}
          autoFocus={true}
        />
      </div>
      <div className='flex flex-col gap-2 overflow-y-auto min-h-64 max-h-64 pr-1 dumpit-scrollbar'>
        {showInviteOption && (
          <div
            className='hover:bg-dark/10 dark:hover:bg-paperdark p-2 rounded-2xl cursor-pointer w-full'
            title={searchQuery}
          >
            <PersonItem
              onClick={() => {
                onInvite(searchQuery);
                setSearchQuery('');
              }}
            >
              <PersonItem.Image
                alt={searchQuery}
                src={undefined}
                size='s'
                name={searchQuery}
                backgroundColor={Color.flatModernColorFrom(searchQuery || '')}
              />
              <PersonItem.Content title={searchQuery} subtitle='Select to invite' />
              <PersonItem.Action>
                <div className='size-8 flex items-center justify-center'>
                  <span className='text-zinc-500 text-sm'>Invite</span>
                </div>
              </PersonItem.Action>
            </PersonItem>
          </div>
        )}

        {!showInviteOption && filteredCollaborators.length === 0 && (
          <div className='h-full flex flex-col items-center justify-center text-center p-4'>
            <Text size='l' fontWeight='semibold' color='gray-500'>
              No collaborators
            </Text>
            <Text size='s' color='gray-400'>
              Invite collaborators by email
            </Text>
          </div>
        )}

        {filteredCollaborators.map((collaborator) =>
          collaborator.user_id ? (
            <div
              className='hover:bg-dark/10 dark:hover:bg-paperdark p-2 rounded-2xl cursor-pointer'
              key={collaborator.id}
            >
              <PersonItem onClick={() => onToggleCollaborator(collaborator)}>
                <PersonItem.Image
                  alt={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : undefined
                  }
                  src={
                    collaborator.profile?.image_path
                      ? getPublicUrl(collaborator.profile?.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                      : undefined
                  }
                  size='s'
                  name={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : undefined
                  }
                  backgroundColor={Color.flatModernColorFrom(collaborator.user_id || '')}
                />
                <PersonItem.Content
                  title={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : 'Collaborator'
                  }
                  subtitle={collaborator.email}
                />
                <PersonItem.Action>
                  <div className='size-8 flex items-center justify-center'>
                    <span className='text-zinc-500 text-sm'>Add</span>
                  </div>
                </PersonItem.Action>
              </PersonItem>
            </div>
          ) : (
            <div
              className='hover:bg-dark/10 dark:hover:bg-paperdark p-2 rounded-2xl cursor-pointer'
              key={collaborator.id}
            >
              <PersonItem onClick={() => onToggleCollaborator(collaborator)}>
                <PersonItem.Image
                  alt={collaborator.email}
                  src={undefined}
                  size='s'
                  name={collaborator.email}
                  backgroundColor={Color.flatModernColorFrom(collaborator.email || '')}
                />
                <PersonItem.Content title={collaborator.email} subtitle='Pending Invitation' />
                <PersonItem.Action>
                  <div className={`size-8 flex items-center justify-center`}>
                    <span className='text-zinc-500 text-sm'>Add</span>
                  </div>
                </PersonItem.Action>
              </PersonItem>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default CollaboratorsList;

import React from 'react';
import Facepile from 'components/common/Facepile';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDumpCollaboratorsForFacepile from 'hooks/collaborators/useDumpCollaboratorsForFacepile';

interface DumpCollaboratorsFacepileProps {
  dump: DumpInterface;
}

export const DumpCollaboratorsFacepile: React.FC<DumpCollaboratorsFacepileProps> = ({ dump }) => {
  const { items, isLoading } = useDumpCollaboratorsForFacepile(dump, [], true);
  if (isLoading || !items.length || items.length <= 1) {
    return null;
  }

  return (
    <div className='flex space-x-1'>
      <div className='flex items-center -space-x-2'>
        <Facepile items={items} size='s' maxItems={3} />
      </div>
    </div>
  );
};

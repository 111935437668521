import { useEffect } from 'react';
import { Button, Heading, Text, PersonItem } from 'components/common/ComponentLibrary';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { useProfileStore } from 'store/profile.store';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { Color } from 'utilities/common/colors.utils';

const CollaboratorsManageList = ({
  selectedCollaborators = [],
  invitedEmails = [],
  onBack,
  onToggleCollaborator,
  onRemoveInvitedEmail,
}: {
  selectedCollaborators?: CollaboratorInterface[];
  invitedEmails?: string[];
  onBack?: () => void;
  onToggleCollaborator: (collaborator: CollaboratorInterface) => void;
  onRemoveInvitedEmail: (email: string) => void;
}) => {
  const profile = useProfileStore((state) => state.profile);

  // Sort collaborators
  const sortedCollaborators = [...selectedCollaborators].sort((a, b) => {
    // Owner first
    if (a.owner_user_id === a.user_id) return -1;
    if (b.owner_user_id === b.user_id) return 1;

    // Current user second
    if (a.user_id === profile?.id) return -1;
    if (b.user_id === profile?.id) return 1;

    // Users without profiles last
    if (!a.profile && b.profile) return 1;
    if (a.profile && !b.profile) return -1;
    if (!a.profile && !b.profile) return 0;

    // Sort by whether they have an image and name
    const aHasImage = !!a.profile?.image_path;
    const bHasImage = !!b.profile?.image_path;
    if (aHasImage && !bHasImage) return -1;
    if (!aHasImage && bHasImage) return 1;

    // Sort by first name
    const aName = a.profile?.first_name || '';
    const bName = b.profile?.first_name || '';
    return aName.localeCompare(bName);
  });

  useEffect(() => {
    if (selectedCollaborators.length === 0 && invitedEmails.length === 0 && onBack) {
      onBack();
    }
  }, [selectedCollaborators, invitedEmails, onBack]);

  return (
    <div className='min-w-96'>
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-col items-start'>
          <Heading size='xl' textAlign='left'>
            Manage Access
          </Heading>
          <Text color='gray-400' size='s'>
            Dumpers with access
          </Text>
        </div>
        <Button onClick={onBack} theme='solid' size='s'>
          Back
        </Button>
      </div>

      <div className='flex flex-col overflow-y-auto min-h-72 max-h-72 pr-1 dumpit-scrollbar py-4'>
        <div className='p-2'>
          <PersonItem>
            <PersonItem.Image
              alt={`${profile?.first_name} ${profile?.last_name} (You)`}
              src={profile?.image_path ? getPublicUrl(profile?.image_path, STORAGE_BUCKET_ENUM.AVATARS) : undefined}
              size='m'
              name={profile?.first_name ? `${profile?.first_name} ${profile?.last_name}` : undefined}
              backgroundColor={Color.flatModernColorFrom(profile?.id || '')}
            />
            <PersonItem.Content title={`${profile?.first_name} ${profile?.last_name} (You)`} />
          </PersonItem>
        </div>
        {sortedCollaborators?.map((collaborator) => (
          <div
            className='hover:bg-dark/10 dark:hover:bg-paperdark p-2 rounded-2xl cursor-pointer'
            key={collaborator.id}
            onClick={() => onToggleCollaborator(collaborator)}
          >
            {collaborator.profile?.first_name ? (
              <PersonItem>
                <PersonItem.Image
                  alt={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : undefined
                  }
                  src={
                    collaborator.profile?.image_path
                      ? getPublicUrl(collaborator.profile?.image_path, STORAGE_BUCKET_ENUM.AVATARS)
                      : undefined
                  }
                  size='m'
                  name={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : undefined
                  }
                  backgroundColor={Color.flatModernColorFrom(collaborator.user_id || '')}
                />
                <PersonItem.Content
                  title={
                    collaborator.profile?.first_name
                      ? `${collaborator.profile?.first_name} ${collaborator.profile?.last_name}`
                      : 'Collaborator'
                  }
                  subtitle={collaborator.email}
                />
                <PersonItem.Action>
                  <div className='flex items-center justify-center'>
                    <span className='text-zinc-500 text-sm'>Remove</span>
                  </div>
                </PersonItem.Action>
              </PersonItem>
            ) : (
              <PersonItem onClick={() => onRemoveInvitedEmail(collaborator.email)}>
                <PersonItem.Image
                  alt={collaborator.email}
                  size='m'
                  name={collaborator.email}
                  backgroundColor={Color.flatModernColorFrom(collaborator.email || '')}
                />
                <PersonItem.Content title={collaborator.email} subtitle='Pending Invitation' />
                <PersonItem.Action>
                  <div className='flex items-center justify-center'>
                    <span className='text-zinc-500 text-sm'>Remove</span>
                  </div>
                </PersonItem.Action>
              </PersonItem>
            )}
          </div>
        ))}
        {invitedEmails?.map((email) => (
          <div className='hover:bg-dark/10 dark:hover:bg-paperdark p-2 rounded-2xl cursor-pointer p-2' key={email}>
            <PersonItem onClick={() => onRemoveInvitedEmail(email)}>
              <PersonItem.Image
                alt={email}
                size='m'
                name={email}
                backgroundColor={Color.flatModernColorFrom(email || '')}
              />
              <PersonItem.Content title={email} subtitle='Pending Invitation' />
              <PersonItem.Action>
                <div className='flex items-center justify-center'>
                  <span className='text-zinc-500 text-sm'>Remove</span>
                </div>
              </PersonItem.Action>
            </PersonItem>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollaboratorsManageList;

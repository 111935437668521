import DumpTags from 'components/dumps/DumpTags';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import DumpReadModeAttachments from './DumpReadModeAttachments';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/common/ComponentLibrary';
import { useCommentsSidebar } from 'components/comments/CommentsContext';
import { isValidUrl } from 'utilities/common/strings.utils';

const DumpReadMode = ({ dump, attachments }: { dump: DumpInterface; attachments?: AttachmentInterface[] }) => {
  const { toggleComments, commentsSinceLastOpened } = useCommentsSidebar();

  // Function to convert text with URLs to JSX with clickable links
  const renderTextWithLinks = (text: string) => {
    if (!text) return null;

    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+\.[^\s]+)/g;

    // Split the text by URLs
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      // Check if this part is a URL
      if (isValidUrl(part)) {
        return (
          <a
            key={index}
            href={part.startsWith('www.') ? `https://${part}` : part}
            target='_blank'
            rel='noopener noreferrer'
            className='text-cyan hover:underline'
          >
            {part}
          </a>
        );
      }
      // Return regular text
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className='flex flex-1 flex-col md:justify-end md:items-center md:bg-bright md:dark:bg-dark'>
      <div className='flex flex-1 flex-col w-full'>
        <DumpTags dump={dump} />

        <div className='relative w-full flex flex-1 flex-col md:dark:bg-paperdark md:border-1 md:border-t-1 border-gray-200 md:p-5 dark:border-bright/25 md:bg-white rounded-xl'>
          <div className='grow outline-none resize-none bg-transparent tracking-wide h-full dark:text-white text-paperdark w-full [word-break:break-word] [overflow-wrap:break-word] whitespace-pre-wrap'>
            {renderTextWithLinks(dump.text || '')}
          </div>

          <div className='flex flex-row justify-between items-end space-x-2'>
            <div>
              <DumpReadModeAttachments attachments={attachments} />
            </div>
            <div className='flex justify-end relative'>
              <Button size='l' theme='bare' width='none' color='white' onClick={() => toggleComments()}>
                <Button.Icon
                  icon={
                    <div className='relative'>
                      <ChatBubbleLeftIcon className='size-8 text-cyan' />
                      {commentsSinceLastOpened > 0 && (
                        <div className='absolute top-0 -right-0 size-3 bg-red rounded-full' />
                      )}
                    </div>
                  }
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DumpReadMode;

import { Text } from 'components/common/ComponentLibrary';
import { BoltIcon, SparklesIcon, BookOpenIcon, UserGroupIcon } from '@heroicons/react/24/solid';

const LandingFeatures = () => (
  <div className='mb-20 px-5 m-auto max-w-[1500px]'>
    <h1 className='text-center text-4xl md:text-5xl leading-tight tracking-tighter font-light text-gray-600 line-clamp-none mb-10'>
      Effortlessly Capture Your Thoughts
    </h1>
    <div className='flex gap-10 flex-col md:flex-row'>
      <div className='relative flex flex-1 flex-col border-1 border-gray-200 p-5 bg-white rounded-xl'>
        <div>
          <div className='rounded-full inline-block p-3 bg-indigo-200 mb-3'>
            <BoltIcon className='text-indigo-400 w-8' />
          </div>
        </div>
        <Text size='l' fontWeight='semibold' spacing='xs'>
          Quick
        </Text>
        <Text>Dump your thoughts in a flash — distraction free!</Text>
      </div>
      <div className='relative flex flex-1 flex-col border-1 border-gray-200 p-5 bg-white rounded-xl'>
        <div>
          <div className='rounded-full inline-block p-3 bg-fuchsia-200 mb-3'>
            <BookOpenIcon className='text-fuchsia-400 w-8' />
          </div>
        </div>
        <Text size='l' fontWeight='semibold' spacing='xs'>
          Easy
        </Text>
        <Text>Instantly read your Dumps at a glance, without unnecessary clicking.</Text>
      </div>
      <div className='relative flex flex-1 flex-col border-1 border-gray-200 p-5 bg-white rounded-xl'>
        <div>
          <div className='rounded-full inline-block p-3 bg-emerald-200 mb-3'>
            <SparklesIcon className='text-emerald-500 w-8' />
          </div>
        </div>
        <Text size='l' fontWeight='semibold' spacing='xs'>
          Powerful
        </Text>
        <Text>Efficiently organize your Dumps with tagging and search.</Text>
      </div>
      <div className='relative flex flex-1 flex-col border-1 border-gray-200 p-5 bg-white rounded-xl'>
        <div>
          <div className='rounded-full inline-block p-3 bg-violet-200 mb-3'>
            <UserGroupIcon className='text-violet-400 w-8' />
          </div>
        </div>
        <Text size='l' fontWeight='semibold' spacing='xs'>
          Collaborative
        </Text>
        <Text>Share and discuss your Dumps with others.</Text>
      </div>
    </div>
  </div>
);

export default LandingFeatures;

import { SupabaseClient } from '@supabase/supabase-js';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';

const TABLE_NAME_COLLABORATOR_PROFILES = 'collaborator_profiles';

export class SupabaseCollaboratorProfileService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getAll(since?: string, page = 1): Promise<UserProfileInterface[]> {
    const fromIndex = (page - 1) * SUPABASE_MAX_PER_PAGE;
    const toIndex = fromIndex + SUPABASE_MAX_PER_PAGE - 1;

    let supabaseQuery = this.supabaseClient.from(TABLE_NAME_COLLABORATOR_PROFILES).select();

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.range(fromIndex, toIndex).order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }

  async getByIds(ids: string[]): Promise<UserProfileInterface[]> {
    if (!ids.length) return [];

    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_COLLABORATOR_PROFILES)
      .select()
      .in('user_id', ids);
    if (error) {
      return [];
    }

    return data;
  }
}

import { captureException, captureMessage } from '@sentry/react';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { addNewCollaborator } from './supabase/SupabaseEdgeFunctionCollaboratorService';
import { upsertUserProfile } from './pouchDb/PouchDbUserProfileService';
import { getAllUnsyncedCollaborators, markCollaboratorSynced } from './pouchDb/PouchDbCollaboratorService';
import { CollaboratorInterface } from '../interfaces/CollaboratorInterface';

export async function pushUnsyncedCollaborators(): Promise<boolean> {
  const currentProfileId = await getCurrentAuthenticatedProfileId();
  if (!currentProfileId) {
    return false;
  }

  const unsyncedCollaborators = await getAllUnsyncedCollaborators();
  if (unsyncedCollaborators && unsyncedCollaborators.length > 0) {
    await Promise.all(
      unsyncedCollaborators.map(async (unsyncedCollaborator: CollaboratorInterface) => {
        try {
          const result = await addNewCollaborator(unsyncedCollaborator.id, unsyncedCollaborator.email);
          if (!result) {
            return;
          }

          if (result.user_id) {
            await upsertUserProfile({
              user_id: result.user_id,
              first_name: result.first_name,
              last_name: result.last_name,
              image_path: result.image_path,
            });
          }

          await markCollaboratorSynced(unsyncedCollaborator.id, { user_id: result.user_id });
        } catch (e) {
          captureMessage(
            `pushUnsyncedCollaborators: userId[${currentProfileId}], collaboratorId[${unsyncedCollaborator.id}].`,
          );
          captureException(e);
        }
      }),
    );
  }

  return true;
}

import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getUrlAccount,
  getUrlAndroidAppStore,
  getUrlDumps,
  getUrlIOSAppStore,
  getUrlSettings,
} from 'utilities/urls.utils';
import { Avatar, Button, ContentLayout, Text, Tooltip } from 'components/common/ComponentLibrary';
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { SettingRow } from 'components/settings/SettingRow';
import { useProfileStore } from 'store/profile.store';
import ThemeSelector from 'components/settings/ThemeSelector/ThemeSelector';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import Footer from 'components/application/Footer';
import FeedbackModal from 'components/feedback/FeedbackModal';
import { useTranslation } from 'react-i18next';
import { DensitySelector } from 'components/settings/DensitySelector';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { LanguageSelector } from 'components/settings/LanguageSelector';

const SettingsScreen = () => {
  const { t } = useTranslation('translations');
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

  useDocumentTitle('Settings');

  const navigate = useNavigate();

  useHotkeys(
    'left',
    () => {
      navigate(getUrlDumps(), { state: { previousRoute: getUrlSettings() } });
    },
    [],
  );
  useHotkeys('right', () => navigate(getUrlAccount(), { state: { previousRoute: getUrlSettings() } }), []);

  const [profile] = useProfileStore((state) => [state.profile]);

  const displayName = profile?.first_name ? `${profile?.first_name} ${profile?.last_name}`.trim() : undefined;

  const location = useLocation();
  const isBackwards = location.state === null || location.state.previousRoute === getUrlAccount();

  const getImageUrl = () => {
    if (profile?.image_path) {
      return `${getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)}?lastmod=${profile.updated_at}`;
    }

    return undefined;
  };

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout>
        <ContentLayout.Top>
          <Button size='l' theme='bare' width='none' href={getUrlDumps()}>
            <Button.Icon icon={<ChevronLeftIcon className='h-8' />} />
          </Button>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                {t('screens.settings.account.title')}
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow onClick={() => navigate(getUrlAccount())}>
                <SettingRow.Content>
                  <div className='flex items-center flex-row space-x-4'>
                    <div className='border-2 border-bright dark:border-white rounded-full'>
                      <Avatar src={getImageUrl()} alt='Profile image' size='m' />
                    </div>
                    <div>
                      <Text fontWeight='bold' size='l'>
                        {displayName ?? 'Your Profile'}
                      </Text>
                      <Text size='s'>{t('screens.settings.account.description')}</Text>
                    </div>
                  </div>
                </SettingRow.Content>
                <SettingRow.End>
                  <div className='flex gap-x-2 items-center'>
                    {!profile?.image_path && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<ExclamationCircleIcon className='w-6 text-red' />}
                        scope='local'
                      >
                        <Text>{t('screens.settings.account.missingImageTooltip')}</Text>
                      </Tooltip>
                    )}
                    <ChevronRightIcon className='w-6' />
                  </div>
                </SettingRow.End>
              </SettingRow>
            </ul>
          </div>

          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                {t('screens.settings.customization.title')}
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.densityTitle')}</SettingRow.Label>
                <SettingRow.Content>{t('screens.settings.customization.descriptionDensity')}</SettingRow.Content>
                <SettingRow.End>
                  <DensitySelector />
                </SettingRow.End>
              </SettingRow>

              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.themeTitle')}</SettingRow.Label>
                <SettingRow.End>
                  <ThemeSelector />
                </SettingRow.End>
              </SettingRow>

              {/* <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.languageTitle')}</SettingRow.Label>
                <SettingRow.End>
                  <LanguageSelector />
                </SettingRow.End>
              </SettingRow> */}
            </ul>
          </div>

          <ul className='flex flex-col pb-6'>
            <SettingRow onClick={() => setShowFeedbackModal(true)}>
              <SettingRow.Label>{t('screens.settings.other.feedbackTitle')}</SettingRow.Label>
              <SettingRow.Content>{t('screens.settings.other.feedbackDescription')}</SettingRow.Content>
            </SettingRow>
            {showFeedbackModal && <FeedbackModal onDismiss={() => setShowFeedbackModal(false)} />}
          </ul>

          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                Mobile App
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow onClick={() => window.open(getUrlIOSAppStore(), '_blank', 'noopener,noreferrer')}>
                <SettingRow.Content>
                  <div className='flex items-center flex-row space-x-4'>
                    <div>
                      <svg viewBox='0 0 384 512' width='30'>
                        <path
                          fill='currentColor'
                          d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'
                        />
                      </svg>
                    </div>
                    <div>
                      <Text fontWeight='bold' size='l'>
                        iOS App
                      </Text>
                      <Text size='s'>{t('screens.settings.mobileApp.downloadIOS')}</Text>
                    </div>
                  </div>
                </SettingRow.Content>
                <SettingRow.End>
                  <ArrowTopRightOnSquareIcon className='w-6' />
                </SettingRow.End>
              </SettingRow>
              <SettingRow onClick={() => window.open(getUrlAndroidAppStore(), '_blank', 'noopener,noreferrer')}>
                <SettingRow.Content>
                  <div className='flex items-center flex-row space-x-4'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        x='0px'
                        y='0px'
                        width='30'
                        height='30'
                        viewBox='0 0 50 50'
                      >
                        <path
                          fill='currentColor'
                          d='M 7.125 2 L 28.78125 23.5 L 34.71875 17.5625 L 8.46875 2.40625 C 8.03125 2.152344 7.5625 2.011719 7.125 2 Z M 5.3125 3 C 5.117188 3.347656 5 3.757813 5 4.21875 L 5 46 C 5 46.335938 5.070313 46.636719 5.1875 46.90625 L 27.34375 24.90625 Z M 36.53125 18.59375 L 30.1875 24.90625 L 36.53125 31.1875 L 44.28125 26.75 C 45.382813 26.113281 45.539063 25.304688 45.53125 24.875 C 45.519531 24.164063 45.070313 23.5 44.3125 23.09375 C 43.652344 22.738281 38.75 19.882813 36.53125 18.59375 Z M 28.78125 26.3125 L 6.9375 47.96875 C 7.300781 47.949219 7.695313 47.871094 8.0625 47.65625 C 8.917969 47.160156 26.21875 37.15625 26.21875 37.15625 L 34.75 32.25 Z'
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <Text fontWeight='bold' size='l'>
                        Android App
                      </Text>
                      <Text size='s'>{t('screens.settings.mobileApp.downloadAndroid')}</Text>
                    </div>
                  </div>
                </SettingRow.Content>
                <SettingRow.End>
                  <ArrowTopRightOnSquareIcon className='w-6' />
                </SettingRow.End>
              </SettingRow>
            </ul>
          </div>
        </ContentLayout.Content>
        <ContentLayout.Footer>
          <Footer />
        </ContentLayout.Footer>
      </ContentLayout>
    </motion.div>
  );
};

export default SettingsScreen;

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { getUrlDumps } from 'utilities/urls.utils';
import { Button, ContentLayout, Menu, Text, Tooltip } from 'components/common/ComponentLibrary';
import {
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { IRoute } from '../routes';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { formatDistanceToNowInUsersTimezone } from 'utilities/date.utils';
import { shortenString } from 'utilities/common/strings.utils';
import { DumpReadMode } from 'components/dumps/ReadMode';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useTranslation } from 'react-i18next';
import { DumpCopyAction, DumpDeleteAction } from 'components/dumps/Actions';
import { DumpCollaboratorsFacepile } from 'components/dumps/DumpCollaboratorsFacepile';
import { ViewCollaboratorsModal } from 'components/collaborators/CollaboratorsModal';
import { useProfileStore } from 'store/profile.store';
import CommentsSidebar from 'components/comments/CommentsSidebar';
import { useCommentsSidebar } from 'components/comments/CommentsContext';
import { Instance } from 'tippy.js';

const DumpDetailReadMode = ({ dump, attachments }: { dump: DumpInterface; attachments?: AttachmentInterface[] }) => {
  const { t } = useTranslation('translations');
  const profile = useProfileStore((state) => state.profile);

  const { isCommentsOpen, toggleComments } = useCommentsSidebar();
  const [isCollaboratorsModalOpen, setIsCollaboratorsModalOpen] = useState(false);
  const [tooltipInstance, setTooltipInstance] = useState<Instance | null>(null);

  const navigate = useNavigate();

  useDocumentTitle(dump?.text ? shortenString(dump.text, 20) : 'Shared Dump');

  useHotkeys('left', () => navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' } }), []);

  const handleGoBack = () => {
    navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' as IRoute } });
  };

  const location = useLocation();
  const isBackwards = location.state?.previousRoute === getUrlDumps();

  const handleToggleComments = () => {
    tooltipInstance?.hide();
    toggleComments();
  };

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <div className='flex flex-row align-top flex-1 h-screen'>
        <ContentLayout hasHScreen={true}>
          <ContentLayout.Top>
            <div className='flex items-center justify-between'>
              <Button
                size='l'
                theme='bare'
                width='none'
                onClick={() => {
                  handleGoBack();
                }}
              >
                <Button.Icon icon={<ChevronLeftIcon className='w-8' />} />
              </Button>

              <div className='flex flex-col items-center gap-2'>
                <div className='cursor-pointer' onClick={() => setIsCollaboratorsModalOpen(true)}>
                  <div className='flex gap-2 items-center'>
                    <DumpCollaboratorsFacepile dump={dump} />
                    {dump.user_id !== profile?.id && (
                      <div className='flex items-center gap-2'>
                        <span key='separator' className='text-gray-500'>
                          &middot;
                        </span>
                        <Tooltip size='s' spacing='s' target={<EyeIcon className='text-gray-500 w-8' />} scope='local'>
                          <Text>This is a view only, shared Dump</Text>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {isCollaboratorsModalOpen && (
                    <ViewCollaboratorsModal dump={dump} onDismiss={() => setIsCollaboratorsModalOpen(false)} />
                  )}
                </div>

                {dump.last_edited_at && (
                  <Text color='gray-500' textAlign='center' size='s'>
                    Last edited {formatDistanceToNowInUsersTimezone(dump.last_edited_at)}
                  </Text>
                )}
              </div>

              <div className='flex items-center gap-5'>
                <Tooltip
                  width='full'
                  size='m'
                  spacing='none'
                  target={
                    <Button size='l' theme='bare' width='none' color='white'>
                      <Button.Icon icon={<EllipsisVerticalIcon className='h-8 text-cyan' />} />
                    </Button>
                  }
                  isInteractive={true}
                  scope='global'
                  trigger='click'
                  onMount={(instance) => setTooltipInstance(instance)}
                >
                  <Menu>
                    <Menu.Item onClick={handleToggleComments} icon={<ChatBubbleLeftIcon className='h-5' />}>
                      <Text color='inherit'>Comments</Text>
                    </Menu.Item>
                    <DumpCopyAction dump={dump}>
                      {({ copyDump }: { copyDump: () => void }) => (
                        <Menu.Item onClick={copyDump} icon={<DocumentDuplicateIcon className='h-5' />}>
                          <Text color='inherit'>{t('screens.list.contextMenu.copy')}</Text>
                        </Menu.Item>
                      )}
                    </DumpCopyAction>
                    <DumpDeleteAction dump={dump}>
                      {({ deleteDump }: { deleteDump: () => void }) => (
                        <Menu.Item onClick={deleteDump} icon={<TrashIcon className='h-5' />}>
                          <Text color='inherit'>{t('screens.list.contextMenu.delete')}</Text>
                        </Menu.Item>
                      )}
                    </DumpDeleteAction>
                    <Menu.Item>
                      <Text color='gray-500' textAlign='center' size='s'>
                        Created {formatDistanceToNowInUsersTimezone(dump.created_at)}
                      </Text>
                    </Menu.Item>
                  </Menu>
                </Tooltip>
              </div>
            </div>
          </ContentLayout.Top>
          <ContentLayout.Content>
            <div className='flex flex-col flex-1'>
              <DumpReadMode dump={dump} attachments={attachments} />
            </div>
          </ContentLayout.Content>
        </ContentLayout>

        {isCommentsOpen && <CommentsSidebar dump={dump} isOpen={isCommentsOpen} onClose={() => toggleComments()} />}
      </div>
    </motion.div>
  );
};

export default DumpDetailReadMode;

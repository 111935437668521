import { useEffect, useState } from 'react';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { getAllCollaborators } from 'models/collaborators/services/pouchDb/PouchDbCollaboratorService';
import { getAllUserProfiles } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';
import { getCurrentProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';

const useCollaborators = () => {
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collaborators, setCollaborators] = useState<CollaboratorInterface[]>([]);

  useEffect(() => {
    const fetchCollaborators = async () => {
      setIsLoading(true);
      try {
        const currentProfile = await getCurrentProfile();
        const docs = await getAllCollaborators();
        const userProfiles = await getAllUserProfiles();

        const fetchedCollaborators = docs
          .filter((collaborator) => {
            return collaborator.is_to_be_deleted !== true && collaborator.owner_user_id === currentProfile?.id;
          })
          .map((collaborator) => ({
            ...collaborator,
            profile: userProfiles.find((profile) => profile.user_id === collaborator.user_id),
          }));

        setCollaborators(fetchedCollaborators);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCollaborators();
  }, []);

  // @todo-phil do we need?
  // useEffect(() => {
  //   subscription = dumpitDatabase.collaborators.$.subscribe(async (event) => {
  //     const userProfiles = await dumpitDatabase.user_profiles.queryAll();

  //     const newCollaborator: CollaboratorInterface = {
  //       id: event.documentId,
  //       email: event.documentData.email,
  //       user_id: event.documentData.user_id,
  //       owner_user_id: event.documentData.owner_user_id,
  //       updated_at: event.documentData.updated_at,
  //       created_at: event.documentData.created_at,
  //       _deleted: event.documentData._deleted,
  //       profile: userProfiles
  //         .find((profile) => profile.asUserProfile().user_id === event.documentData.user_id)
  //         ?.asUserProfile(),
  //     };

  //     switch (event.operation) {
  //       case 'INSERT':
  //       case 'UPDATE':
  //         setCollaborators([
  //           ...(event.operation === 'INSERT'
  //             ? collaborators
  //             : collaborators.filter(
  //                 (collaborator) => collaborator.id !== event.documentId || collaborator.is_to_be_deleted === true,
  //               )),
  //           ...(newCollaborator.is_to_be_deleted ? [] : [newCollaborator]),
  //           newCollaborator,
  //         ]);
  //         break;
  //       case 'DELETE':
  //         setCollaborators(collaborators.filter((collaborator) => collaborator.id !== event.documentId));
  //         break;
  //     }
  //     return () => {
  //       subscription?.unsubscribe();
  //     };
  //   });
  // }, [collaborators]);

  return {
    isLoading,
    error,
    collaborators,
  };
};

export default useCollaborators;

'use client';

import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { trackEvent } from 'models/application/services/TrackingService';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const UtmTracker = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utmParams: Record<string, string | null> = {
      utmMedium: searchParams.get('utm_medium'),
      utmSource: searchParams.get('utm_source'),
      utmCampaign: searchParams.get('utm_campaign'),
      utmTerm: searchParams.get('utm_term'),
      utmContent: searchParams.get('utm_content'),
    };

    const filteredUtmParams = Object.fromEntries(
      Object.entries(utmParams).filter(([, value]) => value !== null && value !== ''),
    );

    if (Object.keys(filteredUtmParams).length === 0) {
      return;
    }

    trackEvent(TRACKING_EVENT_ENUM.UTM_TRACKING, filteredUtmParams);
  }, [searchParams]);

  return null;
};

export default UtmTracker;

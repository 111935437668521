import React from 'react';
import Facepile from 'components/common/Facepile';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDumpCollaboratorsForFacepile from 'hooks/collaborators/useDumpCollaboratorsForFacepile';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';

interface DumpRowCollaboratorsFacepileProps {
  dump: DumpInterface;
  dumpCollaborators: DumpCollaboratorInterface[];
}

export const DumpRowCollaboratorsFacepile: React.FC<DumpRowCollaboratorsFacepileProps> = ({
  dump,
  dumpCollaborators,
}) => {
  const { items, isLoading } = useDumpCollaboratorsForFacepile(dump, dumpCollaborators, false);
  if (isLoading || !items.length) {
    return null;
  }

  return (
    <div className='flex space-x-1'>
      <div className='flex items-center -space-x-2 mr-[1px]'>
        <Facepile items={items} size='xs' maxItems={3} />
      </div>
    </div>
  );
};

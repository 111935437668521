import React, { useState } from 'react';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import AttachmentImageModal from './AttachmentImageModal';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import LoadingPlaceholder from './LoadingPlaceholder';

const ImagePreview = ({
  src,
  previewSrc,
  onRemove,
}: {
  src?: string | null;
  previewSrc?: string | null;
  onRemove?: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!src) {
    return <LoadingPlaceholder />;
  }

  return (
    <div className='relative inline-block group'>
      {onRemove && (
        <MinusCircleIcon
          className='w-6 text-red/60 cursor-pointer hidden group-hover:block absolute z-[500] -right-2 -top-2 hover:text-red rounded-2xl'
          onClick={onRemove}
        />
      )}
      <div onClick={() => setIsModalOpen(true)} className='cursor-pointer w-18 h-18'>
        <MotionEffectPopIn>
          <>
            {!previewSrc && <LoadingPlaceholder />}
            {previewSrc && (
              <div className='relative'>
                <div className='absolute hover:bg-cyan/30 rounded-xl w-full h-full' />
                <img
                  src={previewSrc}
                  alt='Preview'
                  width='100'
                  className='w-18 h-18 object-cover rounded-xl border-1 dark:border-bright/25'
                />
              </div>
            )}
          </>
        </MotionEffectPopIn>
      </div>
      {isModalOpen && <AttachmentImageModal src={src} onDismiss={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default ImagePreview;

import { Avatar, Tooltip, Text } from 'components/common/ComponentLibrary';
import { getInitials } from 'utilities/common/strings.utils';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { Color } from 'utilities/common/colors.utils';

const MAX_ITEMS = 10;

const Facepile = ({
  size = 'xs',
  items,
  maxItems = MAX_ITEMS,
}: {
  size?: 'xs' | 's';
  items: {
    id: string;
    user_id?: string;
    image_src?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
  }[];
  maxItems?: number;
}) => {
  return (
    <div className='flex -space-x-1'>
      {items.slice(0, maxItems).map((item) => {
        return (
          <Tooltip
            key={item.id}
            size='s'
            spacing='s'
            target={
              <div className='inline-block w-auto h-auto rounded-full ring-1 ring-bright dark:ring-paperdark z-10'>
                {item.user_id || item.email ? (
                  <Avatar
                    src={item.image_src}
                    alt={item.first_name || item.email || 'Invited Dumper'}
                    size={size}
                    initials={
                      item.first_name && item.last_name
                        ? getInitials(`${item.first_name} ${item.last_name}`)
                        : getInitials(item.email)
                    }
                    backgroundColor={Color.flatModernColorFrom(item.user_id || item.email || '')}
                  />
                ) : (
                  <div
                    className='relative flex items-center justify-center shrink-0 overflow-hidden bg-zinc-200 dark:bg-zinc-500 rounded-full'
                    style={{
                      width: size === 'xs' ? '24px' : '32px',
                      height: size === 'xs' ? '24px' : '32px',
                    }}
                  >
                    <EnvelopeIcon className='w-3/6 text-gray-400 dark:text-gray-300' />
                  </div>
                )}
              </div>
            }
            scope='local'
          >
            <Text>{item.first_name ? `${item.first_name} ${item.last_name}` : 'Invited Dumper'}</Text>
          </Tooltip>
        );
      })}
      {items.length > maxItems && (
        <Tooltip
          size='s'
          spacing='s'
          target={
            <div
              className='inline-flex items-center justify-center rounded-full ring-1 ring-bright dark:ring-paperdark z-10 bg-zinc-200 dark:bg-zinc-500'
              style={{
                width: size === 'xs' ? '24px' : '32px',
                height: size === 'xs' ? '24px' : '32px',
              }}
            >
              <span
                className={`${size === 'xs' ? 'text-[10px]' : 'text-[13px]'} text-gray-500 dark:text-paperdark font-medium`}
              >
                {items.length - maxItems > 9 ? '+' : `+${items.length - maxItems}`}
              </span>
            </div>
          }
          scope='local'
        >
          <Text>{`${items.length - maxItems} more`}</Text>
        </Tooltip>
      )}
    </div>
  );
};

export default Facepile;

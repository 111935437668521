import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAuthStore } from 'store/auth.store';
import AccountScreen from './account/AccountScreen';
import DumpListScreen from './dump-list/DumpListScreen';
import PageNotFound from './not-found/PageNotFoundScreen';
import SettingsScreen from './settings/SettingsScreen';
import VerifyEmailScreen from './verify-email/VerifyEmailScreen';
import ImpressumScreen from './impressum/ImpressumScreen';
import LostPasswordPage from './lost-password/LostPasswordScreen';
import ResetPasswordPage from './reset-password/ResetPasswordScreen';
import useTrackPageView from 'hooks/application/useTrackPageView';
import { DumpScreenSelectorScreen } from './dump-detail';
import EnterDumpScreen from './enter-dump/EnterDumpScreen';
import LandingScreen from './landing/LandingScreen';
import TermsScreen from './terms/TermsScreen';
import PrivacyScreen from './privacy/PrivacyScreen';
import CookiesScreen from './cookies/CookiesScreen';
import useSyncDumpsAndCollaborators from '../hooks/dumps/useSyncDumpsAndCollaborators';
import DumpShareScreen from './dump-share/DumpShareScreen';
import InvitationScreen from './invitation/InvitationScreen';
import useSyncProfile from 'hooks/users/useSyncProfile';

const AnimatedRouter = () => {
  const location = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const protectedPage = (page: JSX.Element) => (isAuthenticated ? page : <LandingScreen />);

  useTrackPageView(isAuthenticated);

  useSyncProfile();
  useSyncDumpsAndCollaborators();

  return (
    <AnimatePresence mode='sync'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={protectedPage(<EnterDumpScreen />)} />
        <Route path='/dumps' element={protectedPage(<DumpListScreen />)} />
        <Route path='/dumps/:dumpId' element={protectedPage(<DumpScreenSelectorScreen />)} />
        <Route path='/share/:dumpId' element={<DumpShareScreen />} />
        <Route path='/invitation' element={<InvitationScreen />} />
        <Route path='/settings' element={protectedPage(<SettingsScreen />)} />
        <Route path='/account' element={protectedPage(<AccountScreen />)} />
        <Route path='/verify-email' element={<VerifyEmailScreen />} />
        <Route path='/lost-password' element={<LostPasswordPage />} />
        <Route path='/reset-password' element={protectedPage(<ResetPasswordPage />)} />
        <Route path='/impressum' element={<ImpressumScreen />} />
        <Route path='/terms' element={<TermsScreen />} />
        <Route path='/privacy' element={<PrivacyScreen />} />
        <Route path='/cookies' element={<CookiesScreen />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRouter;

import classNames from 'classnames';
import { OWNERSHIP_FILTER } from 'constants/tag.constants';

interface ITagItem {
  children: any;
  onClick: () => void;
  ownershipFilter?: OWNERSHIP_FILTER;
}

const DumpFilterOwnership = ({ children, onClick, ownershipFilter }: ITagItem) => {
  const className = classNames('px-4 py-2 transition-all duration-200 ease-in-out rounded-xl text-base', {
    'text-dark dark:text-white bg-dark/10 dark:bg-white/10 hover:bg-dark/25 hover:dark:bg-white/25':
      ownershipFilter === OWNERSHIP_FILTER.ALL,
    'bg-cyan/10 hover:bg-cyan/25 text-cyan': ownershipFilter === OWNERSHIP_FILTER.YOURS,
    'bg-blue/10 hover:bg-blue/25 text-blue': ownershipFilter === OWNERSHIP_FILTER.SHARED,
  });

  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  );
};

export default DumpFilterOwnership;

import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { uploadFile } from 'utilities/images.utils';
import { AttachmentInterface } from '../interfaces/AttachmentInterface';
import { base64ToFile, getFileExtension, resizeFile } from 'utilities/common/file.utils';
import { IMAGE_PREVIEW_POSTFIX } from 'constants/image.constants';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import { SupabaseAttachmentService } from './supabase/SupabaseAttachmentService';
import supabase from 'store/supabase/supabase';
import { markAttachmentSynced } from './pouchDb/PouchDbAttachmentService';

export async function uploadAttachment(attachment: AttachmentInterface): Promise<boolean> {
  if (isFilePathImage(attachment.filename)) {
    const symmetricKey = await uploadAttachmentImage(attachment);
    if (!symmetricKey) {
      return false;
    }

    await markAttachmentSynced(attachment.id, {});
    return true;
  }

  if (isFilePathAudio(attachment.filename)) {
    const symmetricKey = await uploadAttachmentGeneric(attachment);
    if (!symmetricKey) {
      return false;
    }

    await markAttachmentSynced(attachment.id, {});
    return true;
  }

  if (isFilePathGeneric(attachment.filename)) {
    const symmetricKey = await uploadAttachmentGeneric(attachment);
    if (!symmetricKey) {
      return false;
    }

    await markAttachmentSynced(attachment.id, {});
    return true;
  }

  return false;
}

async function uploadAttachmentImage(attachment: AttachmentInterface): Promise<boolean> {
  if (attachment.file_data) {
    const file = base64ToFile(attachment.file_data!, attachment.filename);
    if (!file) {
      return false;
    }

    const resizedImageFile = await resizeFile(file);
    if (!resizedImageFile) {
      return false;
    }

    const result = await uploadFile(
      file,
      STORAGE_BUCKET_ENUM.ATTACHMENTS,
      getAttachmentFilePath(attachment.user_id, attachment.dump_id, attachment.id, file.name),
    );
    if (!result) {
      return false;
    }

    await uploadFile(
      resizedImageFile,
      STORAGE_BUCKET_ENUM.ATTACHMENTS,
      getAttachmentFilePath(
        attachment.user_id,
        attachment.dump_id,
        attachment.id,
        resizedImageFile.name,
        IMAGE_PREVIEW_POSTFIX,
      ),
    );
  }

  const supabaseAttachmentService = new SupabaseAttachmentService(supabase);
  await supabaseAttachmentService.upsert(attachment);

  return true;
}

async function uploadAttachmentGeneric(attachment: AttachmentInterface): Promise<boolean> {
  if (attachment.file_data) {
    const file = base64ToFile(attachment.file_data, attachment.filename);
    if (!file) {
      return false;
    }

    const result = await uploadFile(
      file,
      STORAGE_BUCKET_ENUM.ATTACHMENTS,
      getAttachmentFilePath(attachment.user_id, attachment.dump_id, attachment.id, file.name),
    );
    if (!result) {
      return false;
    }
  }

  const supabaseAttachmentService = new SupabaseAttachmentService(supabase);
  await supabaseAttachmentService.upsert(attachment);

  return true;
}

export function getAttachmentFilePath(
  userId: string,
  dumpId: string,
  attachmentId: string,
  filename: string,
  postfix = '',
) {
  return `${userId}/${dumpId}/${attachmentId}${postfix}.${getFileExtension(filename)}`;
}

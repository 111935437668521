import { useComments } from 'hooks/comments/useComments';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface CommentsContextType {
  isCommentsOpen: boolean;
  showComments: () => void;
  hideComments: () => void;
  toggleComments: () => void;
  comments: CommentInterface[];
  isLoading: boolean;
  commentsSinceLastOpened: number;
}

const CommentsContext = createContext<CommentsContextType | undefined>(undefined);

export function CommentsProvider({
  children,
  dumpId,
  isCommentsOpen: upstreamIsCommentsOpen,
}: {
  children: ReactNode;
  dumpId: string;
  isCommentsOpen: boolean;
}) {
  const [isCommentsOpen, setIsCommentsOpen] = useState(upstreamIsCommentsOpen);
  const [initialCommentsCount, setInitialCommentsCount] = useState<number | null>(null);
  const [commentsSinceLastOpened, setCommentsSinceLastOpened] = useState(0);
  const { comments, isLoading } = useComments(dumpId);

  useEffect(() => {
    if (window.location.hash === '#comments') {
      setIsCommentsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && initialCommentsCount === null) {
      setInitialCommentsCount(comments.length);
    }
  }, [isLoading, comments, initialCommentsCount]);

  useEffect(() => {
    if (isCommentsOpen) {
      setCommentsSinceLastOpened(0);
      setInitialCommentsCount(comments.length);
    } else if (initialCommentsCount !== null && comments.length > initialCommentsCount) {
      setCommentsSinceLastOpened(comments.length - initialCommentsCount);
    }
  }, [isCommentsOpen, comments.length, initialCommentsCount]);

  useEffect(() => {
    if (isCommentsOpen) {
      window.location.hash = 'comments';
    } else if (window.location.hash === '#comments') {
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  }, [isCommentsOpen]);

  const showComments = () => {
    setIsCommentsOpen(true);
  };

  const hideComments = () => {
    setIsCommentsOpen(false);
  };

  const toggleComments = () => {
    setIsCommentsOpen((prev) => !prev);
  };

  return (
    <CommentsContext.Provider
      value={{
        isCommentsOpen,
        showComments,
        hideComments,
        toggleComments,
        comments,
        isLoading,
        commentsSinceLastOpened,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
}

export function useCommentsSidebar() {
  const context = useContext(CommentsContext);
  if (context === undefined) {
    throw new Error('useCommentsSidebar must be used within a CommentsProvider');
  }

  return context;
}

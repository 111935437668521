import { SupabaseClient } from '@supabase/supabase-js';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';

export const TABLE_NAME = 'attachments';

export class SupabaseAttachmentService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getById(id: string): Promise<AttachmentInterface | null> {
    const { data, error } = await this.supabaseClient.from(TABLE_NAME).select().eq('id', id).single();
    if (!data || error) {
      return null;
    }

    return data as AttachmentInterface;
  }

  async getByDumpId(dumpId: string): Promise<AttachmentInterface[] | null> {
    const query = this.supabaseClient.from(TABLE_NAME).select(`*`).eq('dump_id', dumpId);
    const { data, error } = await query;
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async upsert(attachment: Partial<AttachmentInterface>): Promise<AttachmentInterface> {
    delete attachment.is_synced;
    delete attachment.file_data;

    const { data, error } = await this.supabaseClient.from(TABLE_NAME).upsert(attachment);
    if (error) {
      throw new Error(error.message);
    }

    return data as unknown as AttachmentInterface;
  }

  async deleteAttachment(id: string): Promise<void> {
    const { error } = await this.supabaseClient.from(TABLE_NAME).delete().eq('id', id);
    if (error) {
      throw new Error(error.message);
    }
  }

  async getAttachments(since?: string): Promise<AttachmentInterface[]> {
    let supabaseQuery = this.supabaseClient.from(TABLE_NAME).select();

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }
}

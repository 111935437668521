import { XMarkIcon } from '@heroicons/react/24/outline';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';

import { Color } from 'utilities/common/colors.utils';
interface CommentReplyPreviewProps {
  replyToComment: CommentInterface;
  onClose: () => void;
}

const CommentReplyPreview = ({ replyToComment, onClose }: CommentReplyPreviewProps) => (
  <div
    className='[word-break:break-word] [overflow-wrap:break-word] whitespace-pre-wrap mt-2 py-2 px-4 bg-zinc-100 dark:bg-neutral-600 rounded-lg flex items-start justify-between mr-8'
    style={{
      borderLeft: `5px solid ${Color.flatModernColorFrom(replyToComment.profile?.user_id || '')}`,
    }}
  >
    <div className='flex flex-col'>
      {replyToComment.profile?.first_name && (
        <span
          className='text-sm font-semibold'
          style={{ color: Color.flatModernColorFrom(replyToComment.profile?.user_id || '') }}
        >
          {replyToComment.profile?.first_name} {replyToComment.profile?.last_name}
        </span>
      )}
      <span className='text-sm text-gray-500 dark:text-gray-300 line-clamp-2'>{replyToComment.text}</span>
    </div>
    <button onClick={onClose} className='text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'>
      <XMarkIcon className='w-5 h-5' />
    </button>
  </div>
);

export default CommentReplyPreview;

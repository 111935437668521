export interface CollaboratorSortItem {
  id: string;
  user_id?: string;
  image_src?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  updated_at?: string;
  is_owner?: boolean;
}

export const sortCollaborators = (items: CollaboratorSortItem[]): CollaboratorSortItem[] => {
  return items.sort((a, b) => {
    // Owner always comes first
    if (a.is_owner) return -1;
    if (b.is_owner) return 1;

    // Items with images come first
    if (a.image_src && !b.image_src) return -1;
    if (!a.image_src && b.image_src) return 1;

    // Items with first_name come before those without
    if (a.first_name && !b.first_name) return -1;
    if (!a.first_name && b.first_name) return 1;

    // Sort by first_name or email
    const textA = a.first_name || a.email || '';
    const textB = b.first_name || b.email || '';
    if (textA !== textB) {
      return textA.localeCompare(textB, undefined, { numeric: true });
    }

    // If names/emails are equal, sort by updated_at desc
    if (a.updated_at && b.updated_at) {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    }

    return 0;
  });
};

import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { SettingRow } from 'components/settings/SettingRow';
import { ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { getUrlHome, getUrlSettings } from 'utilities/urls.utils';
import { Avatar, Button, ContentLayout, Heading, Text } from 'components/common/ComponentLibrary';
import { ProfileNameModal } from 'components/profiles/ProfileName';
import { ProfileImageUploader } from 'components/common/ImageUploader';
import { uploadFile } from 'utilities/images.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { useProfileStore } from 'store/profile.store';
import ProfileDeleteModal from 'components/profiles/ProfileDeleteModal';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { toast } from 'react-toastify';
import { getFileExtension, resizeFile } from 'utilities/common/file.utils';
import useNetworkStatus from 'hooks/application/useNetworkStatus';
import classNames from 'classnames';
import ConfirmationDialog from 'components/common/dialog/ConfirmationDialog.component';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { useTranslation } from 'react-i18next';
import useLogout from 'hooks/application/useLogout';
import { getPublicUrl } from 'utilities/storage.utils';

const AccountScreen = () => {
  const [showSignOutConfirmation, setShowSignOutConfirmation] = useState<boolean>(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false);
  const [showUpdateNameModal, setShowUpdateNameModal] = useState<boolean>(false);
  const { isOnline } = useNetworkStatus();

  useDocumentTitle('Account');

  const { t } = useTranslation('translations');

  const navigate = useNavigate();

  const { logout } = useLogout();
  const signOut = async () => {
    await logout();
    window.location.href = getUrlHome();
  };

  const [profile, updateProfile] = useProfileStore((state) => [state.profile, state.updateProfile]);
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>(undefined);

  const displayName = profile?.first_name ? `${profile?.first_name} ${profile?.last_name}`.trim() : undefined;

  useHotkeys('left', () => navigate(getUrlSettings(), { state: { previousRoute: '/account' } }), []);

  const handleChangeImage = async (data: { croppedImage: string; file: File }) => {
    if (data && profile?.id) {
      const resizedImageFile = await resizeFile(data.file);
      if (!resizedImageFile) {
        notifyAboutError(new Error('Resizing image failed'), true, 'Something went wrong.');
        return;
      }

      const result = await uploadFile(
        resizedImageFile,
        STORAGE_BUCKET_ENUM.AVATARS,
        `${profile.id}.${getFileExtension(resizedImageFile.name)}`,
      );
      if (result?.path) {
        await updateProfile({ image_path: result.path });
      }
    } else if (data === undefined) {
      await updateProfile({ image_path: null });
    }

    toast.success('Profile image updated!');

    setProfileImageUrl(data?.croppedImage);

    window.location.reload();
  };

  const getImageUrl = () => {
    if (profileImageUrl) {
      return;
    }

    if (profile?.image_path) {
      return `${getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS)}?lastmod=${profile.updated_at}`;
    }

    return undefined;
  };

  return (
    <motion.div
      custom={{ direction: ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout>
        <ContentLayout.Top>
          <Button size='l' theme='bare' width='none' href={getUrlSettings()}>
            <Button.Icon icon={<ChevronLeftIcon className='h-8' />} />
          </Button>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='flex flex-col items-center justify-center gap-y-4'>
            {isOnline ? (
              <ProfileImageUploader imageUrl={getImageUrl()} onFinish={handleChangeImage} />
            ) : (
              <Avatar src={getImageUrl()} alt='Profile Image' size='xxl' />
            )}

            {displayName && <Heading size='xl'>{displayName}</Heading>}
          </div>

          <ul className='flex flex-col pt-6 mt-4'>
            <SettingRow status='disabled'>
              <SettingRow.Label>Email</SettingRow.Label>
              <SettingRow.Content>{profile?.email}</SettingRow.Content>
            </SettingRow>

            {isOnline && (
              <SettingRow onClick={() => setShowUpdateNameModal(true)}>
                <SettingRow.Label>{t('screens.account.name.title')}</SettingRow.Label>
                <SettingRow.Content>
                  {displayName || <span className='text-gray-500 dark:text-gray-300 italic'>Add your name</span>}
                </SettingRow.Content>
                <SettingRow.End>
                  <div className='flex items-center gap-x-2'>
                    {!displayName && <ExclamationCircleIcon className='w-6 text-red' />}
                    <ChevronRightIcon className='w-6 -mr-2' />
                  </div>
                </SettingRow.End>
              </SettingRow>
            )}
            {showUpdateNameModal && <ProfileNameModal onDismiss={() => setShowUpdateNameModal(false)} />}
          </ul>

          <ul className='flex flex-col pt-4'>
            {isOnline && (
              <SettingRow onClick={() => setShowSignOutConfirmation(true)} color='red'>
                <SettingRow.Content>
                  <Text color='red' textAlign='center' size='l'>
                    {t('screens.account.logout')}
                  </Text>
                </SettingRow.Content>
              </SettingRow>
            )}

            {showSignOutConfirmation && (
              <BackdropComponent dismiss={() => setShowSignOutConfirmation(false)}>
                <ConfirmationDialog
                  title={t('logoutModal.title')}
                  leftItem={{
                    title: t('logoutModal.rejectAction'),
                    action: () => {
                      setShowSignOutConfirmation(false);
                    },
                  }}
                  rightItem={{
                    title: t('logoutModal.acceptAction'),
                    action: signOut,
                    className: classNames('hover:bg-red/10', 'focus-within:bg-red/10', 'text-red'),
                  }}
                />
              </BackdropComponent>
            )}
          </ul>

          {isOnline && (
            <ul className='flex flex-col items-center justify-center pt-12'>
              <Text size='s' tag='div' color='gray-500'>
                <a
                  className='text-sm cursor-pointer inline-flex border-b border-gray-500'
                  onClick={() => setShowDeleteAccountModal(true)}
                >
                  {t('screens.account.deleteAccount')}
                </a>
              </Text>
              {showDeleteAccountModal && <ProfileDeleteModal onDismiss={() => setShowDeleteAccountModal(false)} />}
            </ul>
          )}
        </ContentLayout.Content>
      </ContentLayout>
    </motion.div>
  );
};

export default AccountScreen;

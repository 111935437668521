import { CommentDraftInterface } from 'models/comments/interfaces/CommentDraftInterface';
import PouchDB from 'pouchdb';

const commentDraftsDB = new PouchDB('comment_drafts', { auto_compaction: true });

export async function createCommentDraft(dump_id: string, text: string): Promise<PouchDB.Core.Response | null> {
  const newCommentDraft: CommentDraftInterface = {
    _id: dump_id,
    dump_id,
    text,
  };

  try {
    try {
      const existing = await commentDraftsDB.get(dump_id);
      await commentDraftsDB.remove(existing);
    } catch (error) {
      // Document doesn't exist yet, which is fine
    }

    return await commentDraftsDB.put(newCommentDraft);
  } catch (error) {
    return null;
  }
}

export async function getByDumpId(dump_id: string): Promise<string | null> {
  try {
    const doc = await commentDraftsDB.get<CommentDraftInterface>(dump_id);
    return doc.text;
  } catch (error) {
    return null;
  }
}

export async function deleteByDumpId(dump_id: string): Promise<boolean> {
  try {
    const doc = await commentDraftsDB.get<CommentDraftInterface>(dump_id);
    await commentDraftsDB.remove(doc);
    return true;
  } catch (error) {
    return false;
  }
}

import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { logout } from 'models/application/services/AuthenticationService';
import { useAuthStore } from 'store/auth.store';

const useAuthState = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useEffect(() => {
    // Attempt to refresh session on mount
    const refreshSession = async () => {
      const { error } = await supabase.auth.refreshSession();
      if (error) {
        captureException(error);
        await logout();
      }
    };

    if (isAuthenticated) {
      refreshSession(); // Run refresh attempt when component mounts
    }
  }, [isAuthenticated]);

  return null;
};

export default useAuthState;

import React from 'react';
import { Text } from 'components/common/ComponentLibrary';

interface IContent {
  title: string;
  subtitle?: string;
}

const Content = ({ title, subtitle }: IContent) => (
  <div className='flex-grow'>
    <Text fontWeight='semibold' size='s' lineClamp={1}>
      {title}
    </Text>
    {subtitle && (
      <Text size='xs' color='gray-400' lineClamp={1}>
        {subtitle}
      </Text>
    )}
  </div>
);

Content.displayName = 'PersonItem.Content';

export default Content;

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import {
  FIRSTNAME_MAX_LENGTH,
  FIRSTNAME_MIN_LENGTH,
  LASTNAME_MAX_LENGTH,
  LASTNAME_MIN_LENGTH,
} from 'constants/profiles.constants';
import { useProfileStore } from 'store/profile.store';
import { useTranslation } from 'react-i18next';

const ProfileNameForm = ({ onChanged, onCancel }: { onChanged: () => void; onCancel: () => void }) => {
  const { t } = useTranslation('translations');
  const [profile, updateProfile] = useProfileStore((state) => [state.profile, state.updateProfile]);

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .min(FIRSTNAME_MIN_LENGTH, t('nameModal.validation.firstnameMin', { min: FIRSTNAME_MIN_LENGTH }))
      .max(FIRSTNAME_MAX_LENGTH, t('nameModal.validation.firstnameMax', { max: FIRSTNAME_MAX_LENGTH }))
      .required(t('nameModal.validation.firstnameRequired')),
    lastname: yup
      .string()
      .trim()
      .min(LASTNAME_MIN_LENGTH, t('nameModal.validation.lastnameMin', { min: LASTNAME_MIN_LENGTH }))
      .max(LASTNAME_MAX_LENGTH, t('nameModal.validation.lastnameMax', { max: LASTNAME_MAX_LENGTH }))
      .required(t('nameModal.validation.lastnameRequired')),
  });

  return (
    <Formik
      onSubmit={async ({ firstname, lastname }) => {
        await updateProfile({ first_name: firstname, last_name: lastname });
        onChanged();
      }}
      initialValues={{ firstname: profile?.first_name, lastname: profile?.last_name }}
      validateOnBlur={true}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-6 w-full'>
            <FormikInputField name='firstname' placeholder='Your first name' type='text' autoFocus={true} />
            <FormikInputField name='lastname' placeholder='Your last name' type='text' />
            <div className='flex justify-between gap-6'>
              <Button size='m' onClick={onCancel} theme='ghost'>
                {t('nameModal.rejectAction')}
              </Button>
              <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
                {t('nameModal.acceptAction')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileNameForm;

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikTextareaField } from 'components/common/Formik';
import { useProfileStore } from 'store/profile.store';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SupabaseFeedbackService } from 'models/feedback/services/supabase/SupabaseFeedbackService';
import supabase from 'store/supabase/supabase';

const createValidationSchema = (t: (key: string, options?: any) => string) =>
  yup.object().shape({
    email: yup.string().email('Enter a valid email').trim().nullable(),
    description: yup
      .string()
      .trim()
      .min(2, t('feedbackModal.validation.descriptionMin', { min: 2 }))
      .required(t('feedbackModal.validation.descriptionRequired')),
  });

const FeedbackForm = ({ onDone }: { onDone: () => void }) => {
  const { t } = useTranslation('translations');
  const [profile] = useProfileStore((state) => [state.profile]);

  return (
    <Formik
      onSubmit={async ({ description }) => {
        if (!profile?.id) {
          return;
        }

        const supabaseFeedbackService = new SupabaseFeedbackService(supabase);
        await supabaseFeedbackService.create(description, profile.id);
        toast.success(t('toaster.feedbackSuccess'));
        onDone();
      }}
      initialValues={{ description: '' }}
      validateOnBlur={true}
      validationSchema={createValidationSchema(t)}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-4 w-full'>
            <FormikTextareaField name='description' placeholder={t('feedbackModal.placeholder')} />
            <div className='flex justify-between'>
              <Button size='m' onClick={onDone} theme='ghost'>
                {t('feedbackModal.rejectAction')}
              </Button>
              <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
                {t('feedbackModal.acceptAction')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackForm;

import { formatDistanceToNow, isAfter, isValid, parseISO, format } from 'date-fns';

export const formatDistanceToNowInUsersTimezone = (date: string): string => {
  return formatDistanceToNow(new Date(`${date}Z`), { addSuffix: true });
};

export const formatUTCDateToLocal = (utcDate: string, formatString: string): string => {
  // Parse the UTC date string and create a Date object
  const date = new Date(`${utcDate}Z`);
  // Format the date in the user's local timezone
  return format(date, formatString);
};

export const getCurrentUTCDateFormatted = (): string => {
  const currentDate = new Date();

  // Get the ISO string representation of the date (in UTC)
  const isoString = currentDate.toISOString();

  // Remove the milliseconds and the trailing 'Z' to match the desired format
  return isoString.split('.')[0];
};

export const formatSeconds = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')}`;
};

export const isValidDate = (dateString: string): boolean => {
  const date = parseISO(dateString);

  return isValid(date);
};

export const isDateAMoreRecentThanDateB = (dateA: Date, dateB: Date): boolean => {
  if (!dateB) {
    return true;
  }

  return isAfter(dateA, dateB);
};

import React, { useState } from 'react';
import { useField } from 'formik';
import { toast } from 'react-toastify';
import DumpActions from '../DumpActions';
import { useFilePicker } from 'use-file-picker';
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from 'use-file-picker/validators';
import {
  MAX_ATTACHMENTS_ALLOWED,
  MAX_ATTACHMENTS_REACHED_MESSAGE,
  MAX_FILE_SIZE,
} from 'constants/attachment.constants';
import { getDraftAttachmentsFromFiles, handleFilesRejected } from 'utilities/dumps/dumpAttachment.utils';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';
import { captureMessage } from '@sentry/react';
import { ChatBubbleLeftIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/common/ComponentLibrary';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { EditDumpCollaboratorsModal } from 'components/collaborators/CollaboratorsModal';
import { useCommentsSidebar } from 'components/comments/CommentsContext';

const DumpEditActions = ({ onRecord, dump }: { onRecord: () => void; dump: DumpInterface }) => {
  const [isCollaboratorsModalOpen, setIsCollaboratorsModalOpen] = useState(false);
  const [fieldAttachments, , helpersAttachments] = useField('attachments');

  const { toggleComments, commentsSinceLastOpened } = useCommentsSidebar();

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ALLOWED_FILE_EXTENSIONS.map((extension) => `.${extension}`),
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 3 }),
      new FileTypeValidator(ALLOWED_FILE_EXTENSIONS),
      new FileSizeValidator({ maxFileSize: MAX_FILE_SIZE }),
    ],
    onFilesSuccessfullySelected: async (data) => {
      if (data.plainFiles.length + fieldAttachments.value.length > MAX_ATTACHMENTS_ALLOWED) {
        toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
        captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
        return;
      }

      const newAttachments = await getDraftAttachmentsFromFiles(data.plainFiles);
      helpersAttachments.setValue([...fieldAttachments.value, ...newAttachments]);
    },
    onFilesRejected: handleFilesRejected,
  });

  return (
    <>
      <Button size='l' theme='bare' width='none' color='white' onClick={() => toggleComments()}>
        <Button.Icon
          icon={
            <div className='relative'>
              <ChatBubbleLeftIcon className='size-8 text-cyan' />
              {commentsSinceLastOpened > 0 && <div className='absolute top-0 -right-0 size-3 bg-red rounded-full' />}
            </div>
          }
        />
      </Button>
      <div>
        <Button theme='bare' width='none' size='l' onClick={() => setIsCollaboratorsModalOpen(true)}>
          <Button.Icon icon={<UserPlusIcon className='size-8' />} />
        </Button>
        {isCollaboratorsModalOpen && (
          <EditDumpCollaboratorsModal dump={dump} onDismiss={() => setIsCollaboratorsModalOpen(false)} />
        )}
      </div>
      <DumpActions
        onImageUpload={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          openFilePicker();
        }}
        onFileUpload={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          openFilePicker();
        }}
        onRecord={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          onRecord();
        }}
      />
    </>
  );
};

export default DumpEditActions;

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Tooltip } from 'components/common/ComponentLibrary';
import {
  CameraIcon,
  MicrophoneIcon,
  PaperClipIcon,
  UserPlusIcon,
  XMarkIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import useNetworkStatus from 'hooks/application/useNetworkStatus';
import { useHotkeys } from 'react-hotkeys-hook';

interface IDumpActions {
  onImageUpload?: () => void;
  onFileUpload?: () => void;
  onRecord?: () => void;
}

const DumpActions = ({ onImageUpload, onFileUpload, onRecord }: IDumpActions) => {
  const { isOnline } = useNetworkStatus();
  const [targetIcon, setTargetIcon] = useState(<PaperClipIcon className='w-8' />);
  const [isOpen, setIsOpen] = useState(false);

  useHotkeys('meta+i', () => onImageUpload && onImageUpload(), {
    enableOnFormTags: true,
    preventDefault: true,
  });

  useHotkeys('meta+m', () => onFileUpload && onFileUpload(), {
    enableOnFormTags: true,
    preventDefault: true,
  });

  return (
    <Tooltip
      width='full'
      size='none'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='l'>
          <Button.Icon icon={targetIcon} />
        </Button>
      }
      isInteractive={true}
      isPlain={true}
      scope='local'
      trigger='click'
      placement='top'
      onShow={() => {
        setTargetIcon(<XMarkIcon className='w-8' />);
        setIsOpen(true);
      }}
      onHidden={() => {
        setTargetIcon(<PaperClipIcon className='w-8' />);
        setIsOpen(false);
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.3, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.3, y: 20 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
            className='flex flex-1 flex-col items-center justify-end gap-2 w-full bg-white dark:bg-paperdark p-2 rounded-2xl'
          >
            {onFileUpload && (
              <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }}>
                <Button width='none' size='m' theme='bare' onClick={onFileUpload} status={!isOnline ? 'disabled' : ''}>
                  <Button.Icon icon={<DocumentIcon className='w-7' />} />
                </Button>
              </motion.div>
            )}
            {onImageUpload && (
              <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.15 }}>
                <Button width='none' size='m' theme='bare' onClick={onImageUpload} status={!isOnline ? 'disabled' : ''}>
                  <Button.Icon icon={<CameraIcon className='w-7' />} />
                </Button>
              </motion.div>
            )}
            {onRecord && (
              <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.2 }}>
                <Button width='none' size='m' theme='bare' onClick={onRecord} status={!isOnline ? 'disabled' : ''}>
                  <Button.Icon icon={<MicrophoneIcon className='w-7' />} />
                </Button>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Tooltip>
  );
};

export default DumpActions;

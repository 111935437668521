import React from 'react';
import { Provider } from '@supabase/supabase-js';
import { Button, Heading } from 'components/common/ComponentLibrary';
import { useNavigate, useParams } from 'react-router-dom';
import { getRootUrl, getUrlHome, getUrlSharedDump } from 'utilities/urls.utils';
import LoginForm from 'components/application/Login/LoginForm';
import DividerText from 'components/common/DividerText';
import { GoogleIcon, AppleIcon } from 'components/common/SpecialIcons';
import { isIOS, isMobileSafari, isSafari, isMacOs } from 'react-device-detect';
import supabase, { PROVIDER_GOOGLE, PROVIDER_APPLE } from 'store/supabase/supabase';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { trackEvent } from 'models/application/services/TrackingService';

const SharedDumpLogin = ({ onLoggedIn }: { onLoggedIn: () => void }) => {
  const navigate = useNavigate();

  const { dumpId } = useParams();
  if (!dumpId) {
    navigate(getUrlHome());
    return;
  }

  const handleAuthenticateWith = (provider: Provider) => {
    supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: `${getRootUrl()}${getUrlSharedDump(dumpId)}`,
      },
    });

    trackEvent(TRACKING_EVENT_ENUM.SIGN_IN, {
      provider,
    });
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='md:px-20 p-5 md:py-10 border-1 border-dark/10 dark:border-bright/10 text-dark dark:text-white bg-bright dark:bg-white/5 rounded-2xl'>
        <div className='flex flex-col space-y-7 md:w-96'>
          <Heading size='xxl'>Sign in</Heading>

          <LoginForm onLoggedIn={onLoggedIn} />

          <DividerText>or</DividerText>

          <div className='flex flex-col gap-5'>
            <Button size='l' color='white' onClick={() => handleAuthenticateWith(PROVIDER_GOOGLE)}>
              <Button.Icon icon={<GoogleIcon className='h-7 aspect-square' />} />
              Sign in with Google
            </Button>
            {(isIOS || isMobileSafari || isSafari || isMacOs) && (
              <Button size='l' color='white' onClick={() => handleAuthenticateWith(PROVIDER_APPLE)}>
                <Button.Icon icon={<AppleIcon className='h-7 aspect-square' />} />
                Sign in with Apple
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedDumpLogin;

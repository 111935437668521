import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { getUrlDumps } from 'utilities/urls.utils';
import { Button, ContentLayout, Menu, Text, Tooltip } from 'components/common/ComponentLibrary';
import {
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { IRoute } from '../routes';
import { DumpCopyAction, DumpDeleteAction, DumpPinAction } from 'components/dumps/Actions';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import ConfirmationDialog from 'components/common/dialog/ConfirmationDialog.component';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { DumpEditForm } from 'components/dumps/Edit';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { DumpItIcon, PinIcon } from 'components/common/SpecialIcons';
import { formatDistanceToNowInUsersTimezone } from 'utilities/date.utils';
import useUpdateDump from 'hooks/dumps/useUpdateDump';
import { shortenString } from 'utilities/common/strings.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useTranslation } from 'react-i18next';
import { DumpCollaboratorsFacepile } from 'components/dumps/DumpCollaboratorsFacepile';
import { EditDumpCollaboratorsModal } from 'components/collaborators/CollaboratorsModal';
import CommentsSidebar from 'components/comments/CommentsSidebar';
import { useCommentsSidebar } from 'components/comments/CommentsContext';
import { Instance } from 'tippy.js';

const DumpDetailEditModeScreen = ({
  dump,
  attachments,
}: {
  dump: DumpInterface;
  attachments?: AttachmentInterface[];
}) => {
  const { t } = useTranslation('translations');
  const navigate = useNavigate();
  const { isCommentsOpen, toggleComments } = useCommentsSidebar();

  const [isCollaboratorsModalOpen, setIsCollaboratorsModalOpen] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false);
  const [tooltipInstance, setTooltipInstance] = useState<Instance | null>(null);

  const { updateDump } = useUpdateDump();

  useDocumentTitle(dump?.text ? shortenString(dump.text, 20) : 'Your Dump');

  useHotkeys('left', () => navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' } }), []);

  const handleGoBack = () => {
    setShowUnsavedChangesDialog(false);
    navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' as IRoute } });
  };

  const location = useLocation();
  const isBackwards = location.state?.previousRoute === getUrlDumps();

  const handleToggleComments = () => {
    tooltipInstance?.hide();
    toggleComments();
  };

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <div className='flex flex-row align-top flex-1 h-screen'>
        <Formik
          onSubmit={async (formData, { resetForm }) => {
            await updateDump(dump, attachments, formData.text, formData.attachments);

            toast.info('Dump updated', {
              autoClose: 2000,
              icon: <DumpItIcon />,
            });

            resetForm();
          }}
          initialValues={{
            text: dump.text || '',
            attachments: attachments,
          }}
          enableReinitialize={true}
        >
          {({ submitForm, resetForm, dirty }) => (
            <ContentLayout hasHScreen={true}>
              <ContentLayout.Top>
                <div className='flex items-center justify-between'>
                  <Button
                    size='l'
                    theme='bare'
                    width='none'
                    onClick={() => {
                      if (dirty) {
                        setShowUnsavedChangesDialog(true);
                        return;
                      }

                      handleGoBack();
                    }}
                  >
                    <Button.Icon icon={<ChevronLeftIcon className='w-8' />} />
                  </Button>

                  <div className='flex flex-col items-center gap-2'>
                    <div className='cursor-pointer' onClick={() => setIsCollaboratorsModalOpen(true)}>
                      <DumpCollaboratorsFacepile dump={dump} />
                      {isCollaboratorsModalOpen && (
                        <EditDumpCollaboratorsModal dump={dump} onDismiss={() => setIsCollaboratorsModalOpen(false)} />
                      )}
                    </div>
                    {dump.last_edited_at && (
                      <Text color='gray-500' textAlign='center' size='s'>
                        Last edited {formatDistanceToNowInUsersTimezone(dump.last_edited_at)}
                      </Text>
                    )}
                  </div>

                  <div className='flex items-center gap-5'>
                    <Tooltip
                      width='full'
                      size='m'
                      spacing='none'
                      target={
                        <Button size='l' theme='bare' width='none' color='white'>
                          <Button.Icon icon={<EllipsisVerticalIcon className='h-8 text-cyan' />} />
                        </Button>
                      }
                      isInteractive={true}
                      scope='global'
                      trigger='click'
                      onMount={(instance) => setTooltipInstance(instance)}
                    >
                      <Menu>
                        <Menu.Item
                          onClick={() => setIsCollaboratorsModalOpen(true)}
                          icon={<UserPlusIcon className='h-5' />}
                        >
                          <Text color='inherit'>{t('screens.list.contextMenu.share')}</Text>
                        </Menu.Item>
                        <Menu.Item onClick={handleToggleComments} icon={<ChatBubbleLeftIcon className='h-5' />}>
                          <Text color='inherit'>{t('screens.list.contextMenu.comments')}</Text>
                        </Menu.Item>
                        <DumpPinAction dump={dump}>
                          {({ pinDump }: { pinDump: () => void }) => (
                            <Menu.Item onClick={pinDump} icon={<PinIcon className='h-5' />}>
                              <Text color='inherit'>
                                {dump.is_pinned
                                  ? t('screens.list.contextMenu.unpin')
                                  : t('screens.list.contextMenu.pin')}
                              </Text>
                            </Menu.Item>
                          )}
                        </DumpPinAction>
                        <DumpCopyAction dump={dump}>
                          {({ copyDump }: { copyDump: () => void }) => (
                            <Menu.Item onClick={copyDump} icon={<DocumentDuplicateIcon className='h-5' />}>
                              <Text color='inherit'>{t('screens.list.contextMenu.copy')}</Text>
                            </Menu.Item>
                          )}
                        </DumpCopyAction>
                        <DumpDeleteAction dump={dump}>
                          {({ deleteDump }: { deleteDump: () => void }) => (
                            <Menu.Item onClick={deleteDump} icon={<TrashIcon className='w-5' />}>
                              <Text color='inherit'>{t('screens.list.contextMenu.delete')}</Text>
                            </Menu.Item>
                          )}
                        </DumpDeleteAction>
                        <Menu.Item>
                          <Text color='gray-500' textAlign='center' size='s'>
                            {t('screens.list.contextMenu.created')}{' '}
                            {formatDistanceToNowInUsersTimezone(dump.created_at)}
                          </Text>
                        </Menu.Item>
                      </Menu>
                    </Tooltip>
                  </div>

                  {showUnsavedChangesDialog && dump && (
                    <BackdropComponent dismiss={() => setShowUnsavedChangesDialog(false)}>
                      <ConfirmationDialog
                        title={t('screens.list.saveChanges.title')}
                        leftItem={{
                          title: t('screens.list.saveChanges.no'),
                          action: handleGoBack,
                          className: 'hover:bg-red/10 text-red',
                        }}
                        rightItem={{
                          title: t('screens.list.saveChanges.yes'),
                          action: async () => {
                            await submitForm();
                            handleGoBack();
                          },
                        }}
                      />
                    </BackdropComponent>
                  )}
                </div>
              </ContentLayout.Top>
              <ContentLayout.Content>
                <div className='flex flex-col flex-1'>
                  <DumpEditForm dump={dump} onSave={submitForm} onCancel={() => resetForm()} />
                </div>
              </ContentLayout.Content>
            </ContentLayout>
          )}
        </Formik>
        {isCommentsOpen && <CommentsSidebar dump={dump} isOpen={isCommentsOpen} onClose={() => toggleComments()} />}
      </div>
    </motion.div>
  );
};

export default DumpDetailEditModeScreen;

import React from 'react';
import Image from './subcomponents/Image';
import Content from './subcomponents/Content';
import Action from './subcomponents/Action';

interface IPersonItem {
  children: React.ReactNode;
  onClick?: () => void;
}

const PersonItem = ({ children, onClick }: IPersonItem) => (
  <div className={`flex gap-3 items-center ${onClick ? 'cursor-pointer' : ''} group`} onClick={onClick || undefined}>
    {children}
  </div>
);

PersonItem.displayName = 'PersonItem';

export default Object.assign(PersonItem, { Image, Content, Action });

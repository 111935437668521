import PouchDB from 'pouchdb';
import { SettingInterface } from 'models/settings/interfaces/SettingInterface';

const settingsDB = new PouchDB('settings', { auto_compaction: true });

export async function getCurrentSetting(): Promise<SettingInterface | null> {
  try {
    const result = await settingsDB.find({
      selector: {},
      limit: 1,
    });
    if (!result.docs.length) {
      return null;
    }

    const { ...userData } = result.docs[0];
    return userData as SettingInterface;
  } catch (e) {
    return null;
  }
}

export async function upsertSetting(setting: Partial<SettingInterface>): Promise<SettingInterface> {
  if (!setting.id) {
    throw new Error('Setting ID is required for upsert');
  }

  try {
    const existing = await settingsDB.get(setting.id);
    const updatedSetting = {
      ...existing,
      ...setting,
      _rev: existing._rev,
    };
    const response = await settingsDB.put(updatedSetting);
    const finalSetting = {
      ...updatedSetting,
      _rev: response.rev,
    };
    const { ...settingData } = finalSetting;
    return settingData as SettingInterface;
  } catch (error: any) {
    if (error.status === 404) {
      const newSetting = {
        _id: setting.id,
        ...setting,
      };
      const response = await settingsDB.put(newSetting);
      const finalSetting = {
        ...newSetting,
        _rev: response.rev,
      };
      const { ...settingData } = finalSetting;
      return settingData as SettingInterface;
    } else if (error.status === 409) {
      return upsertSetting(setting);
    }
    throw error;
  }
}

export async function getAllUnsyncedSettings(): Promise<SettingInterface[] | null> {
  try {
    const result = await settingsDB.find({
      selector: {
        is_synced: false,
      },
    });
    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => {
      const { ...settingData } = doc;
      return settingData as SettingInterface;
    });
  } catch (e) {
    return null;
  }
}

export async function markSettingSynced(settingId: string, additionalData: Partial<SettingInterface>) {
  const existingSetting = await settingsDB.get(settingId);
  if (existingSetting) {
    return await settingsDB.put({
      ...existingSetting,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function clearSettingsDB(): Promise<void> {
  await settingsDB.destroy();
}

import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import {
  getLocalStorageLastSyncedDumpCollaborators,
  setLocalStorageLastSyncedDumpCollaborator,
} from 'models/application/services/LocalStorageService';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { SupabaseDumpCollaboratorService } from './supabase/SupabaseDumpCollaboratorService';
import { DumpCollaboratorInterface } from '../interfaces/DumpCollaboratorInterface';
import { getDumpCollaboratorsWithProfiles } from './supabase/SupabaseRPCDumpCollaboratorService';
import { deleteDump } from 'models/dumps/services/DumpService';
import { UserProfileInterface } from '../interfaces/UserProfileInterface';
import { CollaboratorInterface } from '../interfaces/CollaboratorInterface';
import { DumpCollaboratorWithProfileInterface } from '../interfaces/DumpCollaboratorWithProfileInterface';
import { pullLatestDumps } from 'models/dumps/services/DumpSyncService';
import { pullLatestAttachments } from 'models/attachments/services/AttachmentSyncService';
import { SupabaseCollaboratorProfileService } from './supabase/SupabaseCollaboratorProfileService';
import { getDumpById } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { upsertUserProfile } from './pouchDb/PouchDbUserProfileService';
import {
  getAllUnsyncedDumpCollaborators,
  markDumpCollaboratorSynced,
  upsertDumpCollaborator,
} from './pouchDb/PouchDbDumpCollaboratorService';
import { upsertCollaborator } from './pouchDb/PouchDbCollaboratorService';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';

export async function pushUnsyncedDumpCollaborators(): Promise<boolean> {
  const currentProfileId = await getCurrentAuthenticatedProfileId();
  if (!currentProfileId) {
    return false;
  }

  const unsyncedDumpCollaborators = await getAllUnsyncedDumpCollaborators();
  if (unsyncedDumpCollaborators && unsyncedDumpCollaborators.length > 0) {
    const service = new SupabaseDumpCollaboratorService(supabase);
    await Promise.all(
      unsyncedDumpCollaborators.map(async (unsyncedCollaborator: DumpCollaboratorInterface) => {
        try {
          const asDumpCollaborator = {
            ...unsyncedCollaborator,
            _deleted: unsyncedCollaborator.is_to_be_deleted || false,
          };

          const isDumpCollaborator = asDumpCollaborator.user_id === currentProfileId;

          delete asDumpCollaborator._id;
          delete asDumpCollaborator._rev;
          delete asDumpCollaborator.is_synced;
          delete asDumpCollaborator.is_to_be_deleted;
          delete asDumpCollaborator.user_id;

          if (isDumpCollaborator) {
            const result = await service.update(
              asDumpCollaborator.dump_id,
              asDumpCollaborator.collaborator_id,
              asDumpCollaborator,
            );
            if (!result) {
              return;
            }
          } else {
            const result = await service.upsert(asDumpCollaborator);
            if (!result) {
              return;
            }
          }

          await markDumpCollaboratorSynced(
            asDumpCollaborator.dump_id,
            asDumpCollaborator.collaborator_id,
            asDumpCollaborator,
          );
        } catch (e) {
          captureMessage(
            `pushUnsyncedDumpCollaborators: dumpId[${unsyncedCollaborator.dump_id}], collaboratorId[${unsyncedCollaborator.collaborator_id}].`,
          );
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestDumpCollaboratorsWithProfiles(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const dumpCollaboratorsWithProfiles = await getDumpCollaboratorsWithProfiles(
      getLocalStorageLastSyncedDumpCollaborators(),
    );
    if (!dumpCollaboratorsWithProfiles || dumpCollaboratorsWithProfiles.length === 0) {
      return false;
    }

    await pullLatestDumps();

    const userProfiles = new Set<UserProfileInterface>();
    const userProfilesToFetch = new Set<string>();

    // Create or update collaborator for each dump collaborator
    await Promise.all(
      dumpCollaboratorsWithProfiles.map(async (_dumpCollaboratorWithProfile: DumpCollaboratorWithProfileInterface) => {
        const collaborator: CollaboratorInterface = {
          id: _dumpCollaboratorWithProfile.collaborator_id,
          user_id: _dumpCollaboratorWithProfile.collaborator_user_id,
          owner_user_id: _dumpCollaboratorWithProfile.collaborator_owner_user_id,
          email: _dumpCollaboratorWithProfile.collaborator_email,
          created_at: _dumpCollaboratorWithProfile.collaborator_created_at,
          updated_at: _dumpCollaboratorWithProfile.collaborator_updated_at,
          _deleted: _dumpCollaboratorWithProfile.is_collaborator_deleted,
          is_synced: true,
        };

        await upsertCollaborator(collaborator);

        const dumpCollaborator: DumpCollaboratorInterface = {
          dump_id: _dumpCollaboratorWithProfile.dump_id,
          collaborator_id: _dumpCollaboratorWithProfile.collaborator_id,
          created_at: _dumpCollaboratorWithProfile.relationship_created_at,
          updated_at: _dumpCollaboratorWithProfile.relationship_updated_at,
          access: _dumpCollaboratorWithProfile.access,
          user_id: _dumpCollaboratorWithProfile.collaborator_user_id,
          _deleted: _dumpCollaboratorWithProfile?.is_relationship_deleted,
          is_synced: true,
        };

        if (_dumpCollaboratorWithProfile.collaborator_user_id) {
          userProfiles.add({
            user_id: _dumpCollaboratorWithProfile.collaborator_user_id,
            first_name: _dumpCollaboratorWithProfile.first_name,
            last_name: _dumpCollaboratorWithProfile.last_name,
            image_path: _dumpCollaboratorWithProfile.image_path,
          });
        }

        await upsertDumpCollaborator(dumpCollaborator);

        if (
          _dumpCollaboratorWithProfile?.is_relationship_deleted &&
          _dumpCollaboratorWithProfile.collaborator_user_id === currentProfileId
        ) {
          await deleteDump(_dumpCollaboratorWithProfile.dump_id);
        }

        if (_dumpCollaboratorWithProfile.dump_id) {
          const dump = await getDumpById(_dumpCollaboratorWithProfile.dump_id);
          if (dump && dump.user_id !== currentProfileId) {
            userProfilesToFetch.add(dump.user_id!);
          }
        }

        const timestamps = [
          _dumpCollaboratorWithProfile.relationship_updated_at,
          _dumpCollaboratorWithProfile.collaborator_updated_at,
          _dumpCollaboratorWithProfile.profile_updated_at,
        ];
        const lastSyncTimestamp = timestamps.reduce((latest, current) => (current > latest ? current : latest));
        setLocalStorageLastSyncedDumpCollaborator(lastSyncTimestamp);
      }),
    );

    // Filter out profiles that we already have
    const profilesToFetch = new Set(
      Array.from(userProfilesToFetch).filter(
        (id) => !Array.from(userProfiles).some((profile) => profile.user_id === id),
      ),
    );

    if (profilesToFetch.size > 0) {
      const supabaseCollaboratorProfileService = new SupabaseCollaboratorProfileService(supabase);
      const profileIds = Array.from(profilesToFetch);

      // Process profiles in batches of SUPABASE_MAX_PER_PAGE
      for (let i = 0; i < profileIds.length; i += SUPABASE_MAX_PER_PAGE) {
        const batch = profileIds.slice(i, i + SUPABASE_MAX_PER_PAGE);
        const fetchedProfiles = await supabaseCollaboratorProfileService.getByIds(batch);
        for (const profile of fetchedProfiles) {
          userProfiles.add({
            user_id: profile.user_id,
            first_name: profile.first_name,
            last_name: profile.last_name,
            image_path: profile.image_path,
          });
        }
      }
    }

    if (userProfiles.size > 0) {
      await Promise.all(Array.from(userProfiles).map((userProfile) => upsertUserProfile(userProfile)));
    }

    await Promise.all(
      dumpCollaboratorsWithProfiles.map(async (_dumpCollaboratorWithProfile: DumpCollaboratorWithProfileInterface) => {
        if (
          _dumpCollaboratorWithProfile?.is_relationship_deleted &&
          _dumpCollaboratorWithProfile.collaborator_user_id === currentProfileId
        ) {
          await deleteDump(_dumpCollaboratorWithProfile.dump_id);
        }
      }),
    );

    // @todo-phil Clean up orphaned collaborators, user_profiles and dumps

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

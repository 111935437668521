import React from 'react';
import { Provider } from '@supabase/supabase-js';
import { GoogleIcon } from 'components/common/SpecialIcons';
import supabase, { PROVIDER_GOOGLE } from 'store/supabase/supabase';
import { Button } from 'components/common/ComponentLibrary';
import DividerText from 'components/common/DividerText';
import { Login } from 'components/application/Login';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { getRootUrl } from 'utilities/urls.utils';
import SignUpForm from './SignUpForm';

const SignUp = ({ showLoginAction, email }: { showLoginAction: boolean; email?: string }) => {
  const handleAuthenticateWith = (provider: Provider) => {
    supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: getRootUrl(),
      },
    });

    trackEvent(TRACKING_EVENT_ENUM.SIGN_UP, {
      provider,
    });
  };

  return (
    <div className='flex flex-col space-y-10'>
      <Button size='l' color='white' onClick={() => handleAuthenticateWith(PROVIDER_GOOGLE)}>
        <Button.Icon icon={<GoogleIcon className='h-7 aspect-square' />} />
        Sign up with Google
      </Button>

      <DividerText>Or sign up with email</DividerText>

      <SignUpForm email={email} />

      {showLoginAction && <Login />}
    </div>
  );
};

export default SignUp;

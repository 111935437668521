import { SupabaseClient } from '@supabase/supabase-js';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';

const TABLE_NAME_DUMPS = 'dumps';

export class SupabaseDumpService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getAll(since?: string): Promise<DumpInterface[]> {
    let supabaseQuery = this.supabaseClient.from(TABLE_NAME_DUMPS).select('*');

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }

  async upsert(dump: DumpInterface): Promise<DumpInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_DUMPS)
      .upsert({
        ...dump,
      })
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
}

export enum TAG_STATE {
  SELECTED = 'selected',
  UNSELECTED = 'unselected',
  DEFAULT = 'default',
}

export enum OWNERSHIP_FILTER {
  ALL = 'all',
  YOURS = 'yours',
  SHARED = 'shared',
}

import SearchInput from 'components/search/SearchInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OWNERSHIP_FILTER } from 'constants/tag.constants';
import { EyeIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/solid';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import DumpFilterOwnership from './DumpFilterOwnership';
import DumpFilterList from './DumpFilterList';
import { useSearchStore } from 'store/search.store';

interface IDumpsFilter {
  searchTerm?: string | undefined;
  searchBarRef: React.MutableRefObject<HTMLInputElement | null>;
  onSearch: (query: string) => void;
  dumps: DumpInterface[];
}

const DumpsFilter = ({ searchTerm, onSearch, searchBarRef, dumps }: IDumpsFilter) => {
  const { t } = useTranslation('translations');

  const [ownershipFilter, setOwnershipFilter] = useSearchStore((state) => [
    state.ownershipFilter,
    state.setOwnershipFilter,
  ]);

  const handleOwnershipClick = () => {
    switch (ownershipFilter) {
      case OWNERSHIP_FILTER.ALL:
        setOwnershipFilter(OWNERSHIP_FILTER.YOURS);
        break;
      case OWNERSHIP_FILTER.YOURS:
        setOwnershipFilter(OWNERSHIP_FILTER.SHARED);
        break;
      case OWNERSHIP_FILTER.SHARED:
        setOwnershipFilter(OWNERSHIP_FILTER.ALL);
        break;
    }
  };

  const getOwnershipTagText = () => {
    switch (ownershipFilter) {
      case OWNERSHIP_FILTER.ALL:
        return (
          <span className='flex gap-2 items-center'>
            <EyeIcon className='size-5' /> All
          </span>
        );
      case OWNERSHIP_FILTER.YOURS:
        return (
          <span className='flex gap-2 items-center'>
            <UserIcon className='size-5' /> Yours
          </span>
        );
      case OWNERSHIP_FILTER.SHARED:
        return (
          <span className='flex gap-2 items-center'>
            <UserGroupIcon className='size-5' /> Shared
          </span>
        );
    }
  };

  return (
    <div className='mb-5'>
      <div className='mb-5'>
        <SearchInput
          defaultValue={searchTerm || ''}
          placeholder={t('searchPlaceholder')}
          searchRef={searchBarRef}
          onChange={onSearch}
        />
      </div>
      <div className='flex gap-2'>
        <div>
          <DumpFilterOwnership onClick={handleOwnershipClick} ownershipFilter={ownershipFilter}>
            {getOwnershipTagText()}
          </DumpFilterOwnership>
        </div>
        <DumpFilterList dumps={dumps} ownershipFilter={ownershipFilter} />
      </div>
    </div>
  );
};

export default DumpsFilter;

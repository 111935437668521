import { SupabaseClient } from '@supabase/supabase-js';
import { SettingInterface } from 'models/settings/interfaces/SettingInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';

export const TABLE_NAME = 'settings';

export class SupabaseSettingService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async getCurrentUsersSettings(): Promise<SettingInterface | null> {
    const {
      data: { session },
    } = await this.supabaseClient.auth.getSession();
    if (!session || !session?.user?.id) {
      return null;
    }

    return this.getById(session.user.id);
  }

  private async getById(userId: string): Promise<SettingInterface | null> {
    const { data, error } = await this.supabaseClient.from(TABLE_NAME).select().eq('id', userId).single();
    if (!data || error) {
      return null;
    }

    return data as SettingInterface;
  }

  async updateSettings(userId: string, newData: Partial<SettingInterface>): Promise<SettingInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME)
      .update({
        ...newData,
        updated_at: getCurrentUTCDateFormatted(),
      })
      .eq('id', userId)
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async getSettings(since?: string, page = 1): Promise<SettingInterface[]> {
    const fromIndex = (page - 1) * 1;
    const toIndex = fromIndex + 1 - 1;

    let supabaseQuery = this.supabaseClient.from(TABLE_NAME).select();

    if (since) {
      supabaseQuery = supabaseQuery.gt('updated_at', since);
    }

    supabaseQuery = supabaseQuery.range(fromIndex, toIndex).order('updated_at', { ascending: true });

    const { data, error } = await supabaseQuery;
    if (error) {
      return [];
    }

    return data;
  }
}

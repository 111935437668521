import React from 'react';
import { Text } from 'components/common/ComponentLibrary';

interface IHighlightedText {
  text: string;
  highlight: string;
}

export const HighlightedText = ({ text, highlight }: IHighlightedText) => {
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const escapedHighlight = escapeRegExp(highlight);
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return (
    <Text lineClamp={4} tag='span' size='l'>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <b key={`${part}${index}`} className='text-cyan'>
            {part}
          </b>
        ) : (
          part
        ),
      )}
    </Text>
  );
};

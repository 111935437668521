import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useSyncDumpsAndCollaborators from 'hooks/dumps/useSyncDumpsAndCollaborators';
import { Text } from 'components/common/ComponentLibrary';

const FirstSync: React.FC<{ onDone: () => void }> = ({ onDone }) => {
  const { processingStep, SYNC_STEPS } = useSyncDumpsAndCollaborators();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (processingStep === SYNC_STEPS.DONE) {
      onDone();
    }
  }, [processingStep, SYNC_STEPS.DONE, onDone]);

  const loadingItems = [
    {
      step: SYNC_STEPS.LOADING_DUMPS,
      title: 'Loading Dumps...',
    },
    {
      step: SYNC_STEPS.LOADING_COLLABORATORS,
      title: 'Loading collaborators...',
    },
    { step: SYNC_STEPS.LOADING_COMMENTS, title: 'Loading comments...' },
  ];

  useEffect(() => {
    // Find the current step in our loadingItems
    const stepIndex = loadingItems.findIndex((item) => item.step === processingStep);
    if (stepIndex !== -1) {
      setCurrentIndex(stepIndex);
    }
  }, [processingStep]);

  // Keep the rotation going if we're in IDLE state
  useEffect(() => {
    if (processingStep === SYNC_STEPS.IDLE) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % loadingItems.length);
      }, 2500);

      return () => clearInterval(interval);
    }
  }, [processingStep, loadingItems.length]);

  const currentItem = loadingItems[currentIndex];

  return (
    <div className='relative flex flex-1 flex-col h-screen items-center justify-center'>
      <motion.div
        className='-mt-20'
        animate={{ opacity: 0.25 }}
        transition={{ ease: 'linear', duration: 1, repeat: Infinity, repeatType: 'reverse' }}
      >
        <img
          alt='loading...'
          src={`${process.env.PUBLIC_URL}/logo/logo1024-transparent_big.png`}
          className='w-[200px] aspect-square object-contain'
        />
      </motion.div>
      <h1 className='flex flex-col justify-center items-center'>
        <span className='font-sans text-5xl leading-tight tracking-tighter font-normal text-zinc-600 dark:text-white line-clamp-none'>
          Think it.
        </span>
        <span className='font-sans text-6xl leading-tight tracking-tighter text-cyan line-clamp-none'>Dump it!</span>
      </h1>
    </div>
  );
};

export default FirstSync;

import { useEffect, useState } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDumpAttachmentsListener from './useDumpAttachmentsListener';
import { listenToDump } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { captureException } from '@sentry/react';

const useDump = (id: string) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dump, setDump] = useState<DumpInterface | undefined>(undefined);

  const { attachments } = useDumpAttachmentsListener(id);

  useEffect(() => {
    if (!id) return;

    let isMounted = true;
    const dumpPromise = listenToDump(id);

    // Handle initial load
    dumpPromise
      .then((initialDump) => {
        if (isMounted) {
          setDump(initialDump || undefined);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        captureException(err);
        if (isMounted) {
          setError(err);
          setIsLoading(false);
        }
      });

    // Handle subsequent changes
    dumpPromise.onChange((updatedDump) => {
      if (isMounted) {
        setDump(updatedDump || undefined);
      }
    });

    return () => {
      isMounted = false;
      dumpPromise.cleanup();
    };
  }, [id]);

  return {
    dump,
    attachments,
    isLoading,
    error,
  };
};

export default useDump;

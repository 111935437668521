import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { listenToDumpCollaborators } from 'models/collaborators/services/pouchDb/PouchDbDumpCollaboratorService';
import { DumpCollaboratorInterface } from 'models/collaborators/interfaces/DumpCollaboratorInterface';
import { enrichedDumpCollaborators } from 'models/collaborators/services/DumpCollaboratorHelperService';

const useDumpCollaboratorsListener = () => {
  const [dumpCollaborators, setDumpCollaborators] = useState<DumpCollaboratorInterface[]>([]);
  const [groupedCollaborators, setGroupedCollaborators] = useState<Record<string, DumpCollaboratorInterface[]>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  const groupCollaboratorsByDumpId = (collaborators: DumpCollaboratorInterface[]) => {
    return collaborators.reduce(
      (acc, collaborator) => {
        if (!acc[collaborator.dump_id]) {
          acc[collaborator.dump_id] = [];
        }
        acc[collaborator.dump_id].push(collaborator);
        return acc;
      },
      {} as Record<string, DumpCollaboratorInterface[]>,
    );
  };

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    const listener = listenToDumpCollaborators();

    // Handle initial load
    listener
      .then(async (initialDumpCollaborators: DumpCollaboratorInterface[]) => {
        if (isMounted) {
          const filteredDumps = initialDumpCollaborators.filter(
            (dump: DumpCollaboratorInterface) => dump.is_to_be_deleted !== true,
          );

          const enrichedCollaborators = await enrichedDumpCollaborators(filteredDumps);
          setDumpCollaborators(enrichedCollaborators);
          setGroupedCollaborators(groupCollaboratorsByDumpId(enrichedCollaborators));
          setIsLoading(false);
        }
      })
      .catch((err: unknown) => {
        captureException(err);
        if (isMounted) {
          setError(err as Error);
          setIsLoading(false);
        }
      });

    // Handle subsequent changes
    listener.onChange(async (updatedDumps: DumpCollaboratorInterface[]) => {
      if (isMounted) {
        const filteredDumps = updatedDumps.filter((dump: DumpCollaboratorInterface) => dump.is_to_be_deleted !== true);
        const enrichedCollaborators = await enrichedDumpCollaborators(filteredDumps);
        setDumpCollaborators(enrichedCollaborators);
        setGroupedCollaborators(groupCollaboratorsByDumpId(enrichedCollaborators));
      }
    });

    return () => {
      isMounted = false;
      listener.cleanup();
    };
  }, []);

  return {
    dumpCollaborators,
    groupedCollaborators,
    isLoading,
    error,
  };
};

export default useDumpCollaboratorsListener;

import { createClient } from '@supabase/supabase-js';
import { getEnvironmentVariable } from 'utilities/env.utils';

export const PROVIDER_GOOGLE = 'google';
export const PROVIDER_APPLE = 'apple';

const supabase = createClient(
  getEnvironmentVariable('SUPABASE_PROJECT_URL'),
  getEnvironmentVariable('SUPABASE_ANON_KEY'),
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
  },
);

export default supabase;

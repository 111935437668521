import { useEffect, useState } from 'react';
import { useVisibilityChange } from 'use-visibility-change';
import { captureException } from '@sentry/react';
import { pushUnsyncedCollaborators } from 'models/collaborators/services/CollaboratorSyncService';
import {
  pullLatestDumpCollaboratorsWithProfiles,
  pushUnsyncedDumpCollaborators,
} from 'models/collaborators/services/DumpCollaboratorSyncService';
import { pushUnsyncedComments, pullLatestComments } from 'models/comments/services/CommentSyncService';
import { pushUnsyncedDumps, pullLatestDumps } from 'models/dumps/services/DumpSyncService';
import { pushUnsyncedAttachments } from 'models/attachments/services/AttachmentSyncService';
import { pullLatestAttachments } from 'models/attachments/services/AttachmentSyncService';

// Define sync step constants
export const SYNC_STEPS = {
  IDLE: 'IDLE',
  LOADING_DUMPS: 'LOADING_DUMPS',
  LOADING_COLLABORATORS: 'LOADING_COLLABORATORS',
  LOADING_COMMENTS: 'LOADING_COMMENTS',
  LOADING_ATTACHMENTS: 'LOADING_ATTACHMENTS',
  DONE: 'DONE',
} as const;

type SyncStep = (typeof SYNC_STEPS)[keyof typeof SYNC_STEPS];

const useSyncDumpsAndCollaborators = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [processingStep, setProcessingStep] = useState<SyncStep>(SYNC_STEPS.IDLE);
  const isVisible = useVisibilityChange();

  useEffect(() => {
    if (isVisible) {
      (async () => {
        if (isBusy) {
          return;
        }

        try {
          // Process dumps
          setProcessingStep(SYNC_STEPS.LOADING_DUMPS);
          await pushUnsyncedDumps();
          await pullLatestDumps();
        } catch (error) {
          captureException(error);
          setProcessingStep(SYNC_STEPS.DONE);
          setIsBusy(false);
          return;
        }

        try {
          // Process collaborators
          setProcessingStep(SYNC_STEPS.LOADING_COLLABORATORS);
          await pushUnsyncedCollaborators();
          await pushUnsyncedDumpCollaborators();
          await pullLatestDumpCollaboratorsWithProfiles();
        } catch (error) {
          captureException(error);
        }

        try {
          // Process comments
          setProcessingStep(SYNC_STEPS.LOADING_COMMENTS);
          await pushUnsyncedComments();
          await pullLatestComments();
        } catch (error) {
          captureException(error);
        }

        try {
          // Process attachments
          setProcessingStep(SYNC_STEPS.LOADING_ATTACHMENTS);
          await pushUnsyncedAttachments();
          await pullLatestAttachments();
        } catch (error) {
          captureException(error);
        }

        setProcessingStep(SYNC_STEPS.DONE);
        setIsBusy(false);
      })();
    }
  }, [isVisible]);

  return { isBusy, processingStep, SYNC_STEPS };
};

export default useSyncDumpsAndCollaborators;

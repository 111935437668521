import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { uploadAttachment } from './AttachmentService';
import {
  getLocalStorageLastSyncedAttachments,
  setLocalStorageLastSyncedAttachments,
} from 'models/application/services/LocalStorageService';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { SupabaseAttachmentService } from './supabase/SupabaseAttachmentService';
import { getAllUnsyncedAttachments, upsertAttachment } from './pouchDb/PouchDbAttachmentService';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';

export async function pushUnsyncedAttachments(): Promise<boolean> {
  const unsyncedAttachments = await getAllUnsyncedAttachments();
  if (unsyncedAttachments && unsyncedAttachments.length > 0) {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    await Promise.all(
      unsyncedAttachments.map(async (unsyncedAttachment) => {
        try {
          const asAttachment = {
            ...unsyncedAttachment,
            _deleted: unsyncedAttachment.is_to_be_deleted,
          };
          delete asAttachment.is_synced;
          delete asAttachment.is_to_be_deleted;
          delete asAttachment._id;
          delete asAttachment._rev;

          await uploadAttachment(asAttachment);
        } catch (e) {
          captureMessage(
            `pushUnsyncedAttachments: userId[${currentProfileId}], attachmentId[${unsyncedAttachment.id}].`,
          );
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestAttachments(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseAttachmentService = new SupabaseAttachmentService(supabase);
    let hasMoreAttachments = true;
    let lastSyncedAt = getLocalStorageLastSyncedAttachments();

    while (hasMoreAttachments) {
      const attachments = await supabaseAttachmentService.getAttachments(lastSyncedAt);
      if (!attachments || attachments.length === 0) {
        break;
      }

      await Promise.all(
        attachments.map(async (_attachment) => {
          const attachment: AttachmentInterface = {
            id: _attachment.id,
            dump_id: _attachment.dump_id,
            duration: _attachment.duration,
            filename: _attachment.filename,
            user_id: _attachment.user_id,
            created_at: _attachment.created_at,
            updated_at: _attachment.updated_at,
            _deleted: _attachment._deleted,
            is_synced: true,
            file_data: undefined,
          };

          await upsertAttachment(attachment);

          // Only update lastSyncedAt if this attachment's updated_at is more recent
          if (!lastSyncedAt || attachment.updated_at > lastSyncedAt) {
            lastSyncedAt = attachment.updated_at;
            setLocalStorageLastSyncedAttachments(attachment.updated_at);
          }
        }),
      );

      hasMoreAttachments = attachments.length >= SUPABASE_MAX_PER_PAGE;
    }

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

export async function pullAttachmentsForDump(dumpId: string): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseAttachmentService = new SupabaseAttachmentService(supabase);
    const attachments = await supabaseAttachmentService.getByDumpId(dumpId);
    if (!attachments || attachments.length === 0) {
      return false;
    }

    await Promise.all(
      attachments.map(async (_attachment) => {
        const attachment: AttachmentInterface = {
          id: _attachment.id,
          dump_id: _attachment.dump_id,
          duration: _attachment.duration,
          filename: _attachment.filename,
          user_id: _attachment.user_id,
          created_at: _attachment.created_at,
          updated_at: _attachment.updated_at,
          _deleted: _attachment._deleted,
          is_synced: true,
          file_data: undefined,
        };

        await upsertAttachment(attachment);
      }),
    );

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

import PersonItem from './PersonItem';
import Image from './subcomponents/Image';
import Content from './subcomponents/Content';
import Action from './subcomponents/Action';

PersonItem.Image = Image;
PersonItem.Content = Content;
PersonItem.Action = Action;

export { PersonItem };

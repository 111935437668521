import { Suspense, useEffect, useState } from 'react';
import GlobalSearch from 'components/search/GlobalSearch.component';
import useTheme from 'hooks/application/useTheme';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import AnimatedRouter from 'screens/AnimatedRouter.component';
import { useAuthStore } from 'store/auth.store';
import { useSettingsStore } from 'store/setting.store';
import { getUrlDumps } from 'utilities/urls.utils';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import { KeyboardIcon } from 'components/common/SpecialIcons';
import LoadingScreenIndicator from 'components/common/LoadingScreenIndicator';
import UtmTracker from './UtmTracker';
import useAuthState from 'hooks/application/useAuthState';
import { DumpsProvider } from 'components/dumps/DumpsContext';
import useNetworkStatus from 'hooks/application/useNetworkStatus';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const App = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showKeyboardShortcutsModal, setShowKeyboardShortcutsModal] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  useTheme();
  useAuthState();
  const { isOnline } = useNetworkStatus();

  const [isSearchVisible, setIsSearchVisible] = useSettingsStore((state) => [
    state.isGlobalSearchVisible,
    state.setGlobalSearchVisibility,
  ]);

  useHotkeys('esc', () => setIsSearchVisible(false), [], {
    enableOnFormTags: true,
  });

  useHotkeys(
    'meta+k',
    (event) => {
      event.preventDefault();
      setIsSearchVisible(!isSearchVisible);
    },
    [isSearchVisible],
    {
      enableOnFormTags: true,
    },
  );

  useHotkeys(
    'meta+/',
    (event) => {
      event.preventDefault();
      setShowKeyboardShortcutsModal(!showKeyboardShortcutsModal);
    },
    [showKeyboardShortcutsModal],
    {
      enableOnFormTags: true,
      preventDefault: true,
    },
  );

  useEffect(() => {
    setIsSearchVisible(false);
    setSearchTerm('');
  }, [location]);

  return (
    <Suspense fallback={<LoadingScreenIndicator />}>
      {!isOnline && (
        <div className='fixed px-10 bottom-0 left-0 w-full md:h-10 h-12 bg-red flex items-center justify-center text-white text-sm font-medium z-20'>
          <span className='flex items-center gap-2'>
            You're currently offline. Some features may be limited until your connection is restored.
          </span>
        </div>
      )}
      <DumpsProvider>
        <AnimatedRouter />
      </DumpsProvider>

      {isSearchVisible && location.pathname !== getUrlDumps() && (
        <GlobalSearch dismiss={() => setIsSearchVisible(false)} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      {showKeyboardShortcutsModal && <KeyboardShortcutsModal onDismiss={() => setShowKeyboardShortcutsModal(false)} />}

      {currentUser && (
        <div
          className='rounded-2xl p-1 fixed hidden md:block right-8 bottom-5 cursor-pointer'
          onClick={() => setShowKeyboardShortcutsModal(!showKeyboardShortcutsModal)}
        >
          <KeyboardIcon className='w-12 dark:text-zinc-600 text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-500' />
        </div>
      )}

      <UtmTracker />
    </Suspense>
  );
};

export default App;

import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';

PouchDB.plugin(PouchDBFind);

const attachmentsDB = new PouchDB('attachments', { auto_compaction: true });

attachmentsDB.createIndex({
  index: {
    fields: ['dump_id', 'created_at'],
  },
});

export async function deleteAttachment(attachmentId: string): Promise<void> {
  const attachment = await getAttachmentById(attachmentId);
  if (!attachment) {
    return;
  }

  await attachmentsDB.put({
    ...attachment,
    is_synced: false,
    is_to_be_deleted: true,
    updated_at: getCurrentUTCDateFormatted(),
  });
}

export async function getAllAttachments(): Promise<AttachmentInterface[]> {
  const result = await attachmentsDB.find({
    selector: {
      id: { $exists: true },
    },
    limit: 1500, // @todo-phil fix
  });
  return result.docs?.map((doc) => {
    const { ...attachmentData } = doc;
    return attachmentData as AttachmentInterface;
  });
}

export async function getAttachmentsByDumpId(dumpId: string): Promise<AttachmentInterface[]> {
  try {
    const result = await attachmentsDB.find({
      selector: {
        dump_id: dumpId,
        _id: {
          $regex: '^(?!_design/)', // Exclude docs that start with '_design/ the index file. @todo-phil find better solution'
        },
        id: { $exists: true },
        is_to_be_deleted: { $ne: true },
        _deleted: { $ne: true },
      },
    });

    return (
      result.docs
        ?.map((doc) => {
          const { ...attachmentData } = doc;
          return attachmentData as AttachmentInterface;
        })
        .sort((a, b) => {
          if (!a.created_at || !b.created_at) return 0;
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        }) || []
    );
  } catch (error) {
    return [];
  }
}

export async function getAllUnsyncedAttachments(): Promise<AttachmentInterface[] | null> {
  try {
    const result = await attachmentsDB.find({
      selector: {
        is_synced: false,
      },
    });
    if (!result.docs.length) {
      return null;
    }

    return result.docs.map((doc) => {
      const { ...attachmentData } = doc;
      return attachmentData as AttachmentInterface;
    });
  } catch (e) {
    return null;
  }
}

export async function getAttachmentById(attachmentId: string): Promise<AttachmentInterface | null> {
  try {
    const doc = await attachmentsDB.get(attachmentId);
    const { ...attachmentData } = doc;
    return attachmentData as AttachmentInterface;
  } catch (e) {
    return null;
  }
}

export async function markAttachmentSynced(attachmentId: string, additionalData?: Partial<AttachmentInterface>) {
  const existing = await attachmentsDB.get(attachmentId);
  if (existing) {
    return await attachmentsDB.put({
      ...existing,
      ...additionalData,
      is_synced: true,
    });
  }
}

export async function upsertAttachment(attachment: Partial<AttachmentInterface>): Promise<void> {
  if (!attachment.id) {
    throw new Error('Attachment ID is required');
  }

  try {
    const doc = await attachmentsDB.get(attachment.id);
    // Document exists, update it
    await attachmentsDB.put({
      ...doc,
      ...attachment,
    });
  } catch (e) {
    // Document doesn't exist, create new one
    await attachmentsDB.put({
      _id: attachment.id,
      ...attachment,
    });
  }
}

export function listenToDumpAttachments(dumpId: string): Promise<AttachmentInterface[]> & {
  onChange: (callback: (attachments: AttachmentInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getAttachmentsByDumpId(dumpId);
  const listeners: ((attachments: AttachmentInterface[]) => void)[] = [];

  const changes = attachmentsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
    })
    .on('change', async (change) => {
      if ((change.doc as any as AttachmentInterface)?.dump_id === dumpId) {
        const attachments = await getAttachmentsByDumpId(dumpId);
        listeners.forEach((listener) => listener(attachments));
      }
    });

  return Object.assign(promise, {
    onChange: (callback: (attachments: AttachmentInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export function listenToAllAttachments(): Promise<AttachmentInterface[]> & {
  onChange: (callback: (attachments: AttachmentInterface[]) => void) => void;
  cleanup: () => void;
} {
  const promise = getAllAttachments();
  const listeners: ((attachments: AttachmentInterface[]) => void)[] = [];

  const changes = attachmentsDB
    .changes({
      since: 'now',
      live: true,
      include_docs: true,
    })
    .on('change', async () => {
      const attachments = await getAllAttachments();
      listeners.forEach((listener) => listener(attachments));
    });

  return Object.assign(promise, {
    onChange: (callback: (attachments: AttachmentInterface[]) => void) => {
      listeners.push(callback);
    },
    cleanup: () => {
      changes.cancel();
      listeners.length = 0;
    },
  });
}

export async function clearAttachmentsDB(): Promise<void> {
  await attachmentsDB.destroy();
}

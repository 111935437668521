import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';
import { deleteAttachment } from 'models/attachments/services/pouchDb/PouchDbAttachmentService';
import { uniquifyArray } from 'utilities/array.utils';
import { getUserProfilesByUserIds } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';
import { getCurrentProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';

export const processRemovedAttachments = async (
  initialAttachments?: AttachmentInterface[],
  attachments?: AttachmentInterface[] | IDraftAttachment[],
) => {
  let removedAttachments: AttachmentInterface[] | IDraftAttachment[] = [];

  if (initialAttachments && initialAttachments.length > 0) {
    if (attachments && attachments.length > 0) {
      removedAttachments = initialAttachments.filter(
        (initialAttachment) =>
          !attachments.some(
            (attachment: AttachmentInterface | IDraftAttachment) =>
              'id' in attachment && attachment.id === initialAttachment.id,
          ),
      );
    } else {
      removedAttachments = initialAttachments; // All initial attachments have been removed since attachments is empty
    }
  } else {
    removedAttachments = []; // initialAttachments is empty, so there are no removed attachments
  }
  if (removedAttachments.length === 0) {
    return;
  }

  await Promise.all(
    removedAttachments?.map(async (removedAttachment: AttachmentInterface | IDraftAttachment) => {
      if ('id' in removedAttachment) {
        await deleteAttachment(removedAttachment.id);
      }
    }),
  );
};

export const sortDumps = (dumps: DumpInterface[]): DumpInterface[] => {
  return dumps.sort((a: DumpInterface, b: DumpInterface) => {
    // First sort by pinned status
    if (a.is_pinned && !b.is_pinned) return -1;
    if (!a.is_pinned && b.is_pinned) return 1;

    // If pinned status is the same, sort by dates
    const datesA = [a.sort_date, a.last_edited_at, a.latest_comment_at].filter((d): d is string => Boolean(d));
    const datesB = [b.sort_date, b.last_edited_at, b.latest_comment_at].filter((d): d is string => Boolean(d));

    const dateA = new Date(Math.max(...datesA.map((date) => new Date(date).getTime())));
    const dateB = new Date(Math.max(...datesB.map((date) => new Date(date).getTime())));

    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;

    const textA = a.text || '';
    const textB = b.text || '';

    return textA.localeCompare(textB, undefined, { numeric: true });
  });
};

export const getSharedDumpUserProfiles = async (dumps: DumpInterface[]): Promise<UserProfileInterface[]> => {
  const currentProfile = await getCurrentProfile();
  if (!currentProfile) {
    return [];
  }
  const sharedDumps = dumps.filter((dump) => dump.user_id !== currentProfile.id);
  const userProfileIds = sharedDumps.map((dump) => dump.user_id).filter((id): id is string => !!id);
  const userProfiles = await getUserProfilesByUserIds(userProfileIds);
  if (!userProfiles || userProfiles.length === 0) {
    return [];
  }

  return userProfiles;
};

export const extractTags = (text?: string) => {
  if (!text || text.length <= 1) {
    return [];
  }

  const hashtagRegex = /(?<=^|\s)#([\p{L}\p{N}]{1,29})(?=\s|$|\W)/gu;
  const matches = text.match(hashtagRegex);

  if (!matches) {
    return [];
  }

  return uniquifyArray(matches.map((tag) => tag.slice(1)));
};

import React from 'react';
import classNames from 'classnames';
import { TAG_STATE } from 'constants/tag.constants';

interface ITagItem {
  children: React.ReactNode;
  onClick: () => void;
  state?: TAG_STATE;
}

const Tag = ({ children, onClick, state }: ITagItem) => {
  const className = classNames('px-4 py-2 transition-all duration-200 ease-in-out rounded-xl', {
    'text-dark dark:text-white bg-dark/10 dark:bg-white/10 hover:bg-dark/25 hover:dark:bg-white/25':
      state === TAG_STATE.DEFAULT,
    'bg-cyan/10 hover:bg-cyan/25 text-cyan': state === TAG_STATE.SELECTED,
    'text-red bg-red/10 dark:bg-red/10 line-through': state === TAG_STATE.UNSELECTED,
  });

  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  );
};

export default Tag;

import { isValidDate } from 'utilities/date.utils';

const LOCAL_STORAGE_LAST_SYNCED_DUMPS = 'lsd3';
const LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS = 'lsa2';
const LOCAL_STORAGE_LAST_SYNCED_DUMP_COLLABORATORS = 'lsdc2';
const LOCAL_STORAGE_LAST_SYNCED_COMMENTS = 'lsc2';
const LOCAL_STORAGE_LAST_SYNCED_PROFILE = 'lsp2';
const LOCAL_STORAGE_LAST_SYNCED_SETTING = 'lss2';

const LOCAL_STORAGE_IS_FIRST_SYNC_DONE = 'lsifsd';

export function setLocalStorageIsFirstSyncDone(value: boolean): void {
  localStorage.setItem(LOCAL_STORAGE_IS_FIRST_SYNC_DONE, value.toString());
}

export function getLocalStorageIsFirstSyncDone(): boolean | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_IS_FIRST_SYNC_DONE);
  if (!value) {
    return;
  }

  return value === 'true';
}

export function setLocalStorageLastSyncedDumps(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS, value);
}

export function getLocalStorageLastSyncedDumps(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedProfile(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_PROFILE, value);
}

export function getLocalStorageLastSyncedProfile(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_PROFILE);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedSetting(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_SETTING, value);
}

export function getLocalStorageLastSyncedSetting(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_SETTING);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedComments(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_COMMENTS, value);
}

export function getLocalStorageLastSyncedComments(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_COMMENTS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedAttachments(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS, value);
}

export function getLocalStorageLastSyncedAttachments(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedDumpCollaborator(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_DUMP_COLLABORATORS, value);
}

export function getLocalStorageLastSyncedDumpCollaborators(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_DUMP_COLLABORATORS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function clearLocalStorageItems(): void {
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_COMMENTS);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_DUMP_COLLABORATORS);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_PROFILE);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_SETTING);
  localStorage.removeItem(LOCAL_STORAGE_IS_FIRST_SYNC_DONE);
}

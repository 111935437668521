import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

export type IDraftAttachment = {
  src: string | null;
  file: File;
};

export type IDumpDraftStore = {
  text: string;
  audio: File[];
  attachments: IDraftAttachment[];
  collaborators: {
    selectedCollaborators: CollaboratorInterface[];
    invitedEmails: string[];
  };

  setText: (text: string) => void;
  setAudio: (audio: File[]) => void;
  setAttachments: (attachments: IDraftAttachment[]) => void;
  setCollaborators: (collaborators: {
    selectedCollaborators: CollaboratorInterface[];
    invitedEmails: string[];
  }) => void;
  resetStore: () => void;
  isEmpty: () => boolean;
};

export const useDumpDraftStore = create<IDumpDraftStore>((set, getState) => ({
  text: '',
  audio: [],
  attachments: [],
  collaborators: {
    selectedCollaborators: [],
    invitedEmails: [],
  },

  setText: (text) =>
    set(() => ({
      text,
    })),

  setAudio: (audio) =>
    set(() => ({
      audio,
    })),

  setAttachments: (attachments) =>
    set(() => ({
      attachments,
    })),

  setCollaborators: (collaborators) =>
    set(() => ({
      collaborators,
    })),

  resetStore: () =>
    set(() => ({
      text: '',
      audio: [],
      attachments: [],
      collaborators: {
        selectedCollaborators: [],
        invitedEmails: [],
      },
    })),

  isEmpty: () =>
    getState().text?.trim().length === 0 && getState().attachments.length === 0 && getState().audio.length === 0,
}));

mountStoreDevtool('DumpDraftStore', useDumpDraftStore);

import Resizer from 'react-image-file-resizer';
import { captureException } from '@sentry/react';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';

export function getFileExtension(filename: string): string {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return filename.substring(lastDotIndex + 1);
}

export const resizeFile = (file: File): Promise<File | null> => {
  try {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        getFileExtension(file.name),
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file',
      );
    });
  } catch (error) {
    captureException(error);
    return Promise.reject(error);
  }
};

export const getFileSizeMB = (size: number): number => {
  return size / 1000 / 1000;
};

const checkType = (file: File, types: string[]): boolean => {
  const extension: string = file.name.split('.').pop() as string;
  const loweredTypes = types.map((type) => type.toLowerCase());
  return loweredTypes.includes(extension.toLowerCase());
};

export const validateFile = (file: File, options: { allowedTypes: string[]; maxSize: number }) => {
  if (options.allowedTypes && !checkType(file, options.allowedTypes)) {
    return { name: 'FileTypeError' };
  }

  if (options.maxSize && file.size > options.maxSize) {
    return { name: 'FileSizeError' };
  }

  return;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const base64ToFile = (base64: string, filename: string): File => {
  const mimeType = getFileMimeType(filename);
  if (!mimeType) {
    throw new Error('Incorrect file type');
  }

  const withoutMimetype = base64.split(',')[1];
  const byteCharacters = atob(withoutMimetype);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};

export const getFileMimeType = (filename: string): string | null => {
  const extension = filename.split('.').pop()?.toLowerCase();

  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    m4a: 'audio/mp4',
    wav: 'audio/wav',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    txt: 'text/plain',
    csv: 'text/csv',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  };

  return mimeTypes[extension!] || null;
};

export const arrayBufferToFile = (arrayBuffer: ArrayBuffer, fileName: string, mimeType: string): File => {
  const blob = new Blob([arrayBuffer], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
};
export function getAttachmentFilePath(
  attachment: { user_id: string; dump_id: string; id: string; filename: string } | AttachmentInterface,
  postfix = '',
) {
  return `${attachment.user_id}/${attachment.dump_id}/${attachment.id}${postfix}.${getFileExtension(
    attachment.filename,
  )}`;
}

import React, { forwardRef, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

interface ITextarea {
  rows?: number;
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: (arg: any) => any;
  onKeyDown?: (arg: any) => any;
  status?: '' | 'disabled' | 'invalid';
  autoFocus?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  ({ name, placeholder, rows = 4, value, onChange, onKeyDown, status, autoFocus = false }: ITextarea, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [isManuallyResized, setIsManuallyResized] = useState(false);
    const [manualHeight, setManualHeight] = useState<number | null>(null);

    useEffect(() => {
      if (value === '') {
        setIsManuallyResized(false);
        setManualHeight(null);
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      }
    }, [value]);

    const inputClassNames = classNames(
      'text-dark dark:text-white w-full min-h-[56px] rounded-xl p-4 tracking-wider dark:bg-paperdark ring-1 ring-gray-300 dark:ring-bright/25',
      {
        'ring-transparent focus-within:outline-none focus-within:border-none focus:ring-cyan': status !== 'invalid',
        'ring-transparent border-1 border-amber-600 focus-within:outline-none ring-0': status === 'invalid',
        'opacity-50': status === 'disabled',
      },
    );

    useEffect(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const height = entry.contentRect.height;
          if (!isManuallyResized && height !== manualHeight) {
            setManualHeight(height);
            setIsManuallyResized(true);
          }
        }
      });

      resizeObserver.observe(textarea);
      return () => resizeObserver.disconnect();
    }, [isManuallyResized, manualHeight]);

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = e.target;

      // Reset manual height if textarea is empty
      if (textarea.value === '') {
        setIsManuallyResized(false);
        setManualHeight(null);
        textarea.style.height = 'auto';
      } else if (!isManuallyResized) {
        // Only auto-resize if not manually resized
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else if (manualHeight) {
        // When manually resized, only grow if content exceeds current height
        if (textarea.scrollHeight > manualHeight) {
          textarea.style.height = `${textarea.scrollHeight}px`;
          setManualHeight(textarea.scrollHeight);
        } else {
          // Maintain manual height even if content is less
          textarea.style.height = `${manualHeight}px`;
        }
      }

      onChange && onChange(e);
    };

    return (
      <textarea
        ref={(element) => {
          // Handle both forwardRef and local ref
          if (typeof ref === 'function') {
            ref(element);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = element;
          }
          (textareaRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = element;
        }}
        id={name}
        name={name}
        placeholder={placeholder}
        className={inputClassNames}
        aria-multiline={true}
        onChange={handleInput}
        onKeyDown={(e) => {
          onKeyDown && onKeyDown(e);
        }}
        value={value}
        rows={rows}
        autoFocus={autoFocus}
        style={{
          resize: 'vertical',
          overflow: 'hidden',
          height: manualHeight ? `${manualHeight}px` : 'auto',
        }}
      ></textarea>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;

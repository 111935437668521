import { dumpTextContains, IDump } from 'models/dump.model';
import { OWNERSHIP_FILTER, TAG_STATE } from 'constants/tag.constants';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { extractTags } from './dumps.utils';

export const filterDumpsBySearchQuery = (query: string) => (dump: DumpInterface) => {
  if (!query || query.length === 0) {
    return true;
  }

  return dumpTextContains(dump, query);
};

export const filterDumpsByTags = (tags: any) => (dump: DumpInterface) => {
  const tagsToInclude = Object.entries(tags)
    .filter(([_, status]) => status === TAG_STATE.SELECTED)
    .map(([tag]) => tag);

  const tagsToExclude = Object.entries(tags)
    .filter(([_, status]) => status === TAG_STATE.UNSELECTED)
    .map(([tag]) => tag);
  if (tagsToInclude.length === 0 && tagsToExclude.length === 0) {
    return true;
  }

  const dumpTags = extractTags(dump.text);
  if (!dumpTags) {
    return false;
  }

  const includesAllSelectedTags = tagsToInclude.length === 0 || tagsToInclude.every((tag) => dumpTags.includes(tag));
  const doesNotIncludeExcludedTags =
    tagsToExclude.length === 0 || dumpTags.every((tag) => !tagsToExclude.includes(tag));

  return includesAllSelectedTags && doesNotIncludeExcludedTags;
};

export const filterDumpsByUsers = (users: any, ownershipFilter: OWNERSHIP_FILTER) => (dump: DumpInterface) => {
  if (ownershipFilter !== OWNERSHIP_FILTER.SHARED) {
    return true;
  }

  const usersToInclude = Object.entries(users)
    .filter(([_, status]) => status === TAG_STATE.SELECTED)
    .map(([user]) => user);

  const usersToExclude = Object.entries(users)
    .filter(([_, status]) => status === TAG_STATE.UNSELECTED)
    .map(([user]) => user);

  if (usersToInclude.length === 0 && usersToExclude.length === 0) {
    return true;
  }

  if (!dump.user_id) {
    return false;
  }

  const includesSelectedUser = usersToInclude.length === 0 || usersToInclude.includes(dump.user_id);
  const isNotExcludedUser = usersToExclude.length === 0 || !usersToExclude.includes(dump.user_id);

  return includesSelectedUser && isNotExcludedUser;
};

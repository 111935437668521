import { useEffect, useState } from 'react';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { listenToDumpAttachments } from 'models/attachments/services/pouchDb/PouchDbAttachmentService';
import { captureException } from '@sentry/react';

const useDumpAttachmentsListener = (dumpId: string) => {
  const [attachments, setAttachments] = useState<AttachmentInterface[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!dumpId) return;

    setIsLoading(true);
    let isMounted = true;

    const attachmentsPromise = listenToDumpAttachments(dumpId);

    // Handle initial load
    attachmentsPromise
      .then((initialAttachments) => {
        if (isMounted) {
          setAttachments(initialAttachments.sort((a, b) => a.created_at.localeCompare(b.created_at)));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        captureException(err);
        if (isMounted) {
          setIsLoading(false);
        }
      });

    // Handle subsequent changes
    attachmentsPromise.onChange((updatedAttachments) => {
      if (isMounted) {
        setAttachments(updatedAttachments.sort((a, b) => a.created_at.localeCompare(b.created_at)));
      }
    });

    return () => {
      isMounted = false;
      attachmentsPromise.cleanup();
    };
  }, [dumpId]);

  return {
    attachments,
    isLoading,
  };
};

export default useDumpAttachmentsListener;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrlDumps } from 'utilities/urls.utils';
import useDump from 'hooks/dumps/useDump';
import { useAuthStore } from 'store/auth.store';
import PageNotFound from 'screens/not-found/PageNotFoundScreen';
import DumpDetailEditModeScreen from './DumpDetailEditModeScreen';
import DumpDetailReadModeScreen from './DumpDetailReadModeScreen';
import { CommentsProvider } from 'components/comments/CommentsContext';
import { isMobile } from 'react-device-detect';

const DumpScreenSelectorScreen = () => {
  const navigate = useNavigate();

  const { dumpId } = useParams();
  if (!dumpId) {
    navigate(getUrlDumps());
    return;
  }

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const { dump, attachments, error, isLoading } = useDump(dumpId);
  if (error) {
    return <PageNotFound dumpId={dumpId} />;
  }

  if (!dump || isLoading || !currentUser) {
    if (!dump && !isLoading) {
      return <PageNotFound dumpId={dumpId} />;
    }

    return null;
  }

  if (currentUser.id === dump.user_id) {
    return (
      <CommentsProvider dumpId={dump.id} isCommentsOpen={!isMobile && !!dump.latest_comment_at}>
        <DumpDetailEditModeScreen dump={dump} attachments={attachments} />
      </CommentsProvider>
    );
  }

  return (
    <CommentsProvider dumpId={dump.id} isCommentsOpen={!isMobile && !!dump.latest_comment_at}>
      <DumpDetailReadModeScreen dump={dump} attachments={attachments} />
    </CommentsProvider>
  );
};

export default DumpScreenSelectorScreen;

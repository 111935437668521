import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import useCollaborators from 'hooks/collaborators/useCollaborators';
import CollaboratorsModal from './CollaboratorsModal';

const CreateDumpCollaboratorsModal = ({
  onDismiss,
  onShare,
  selectedCollaborators,
  invitedEmails,
}: {
  onDismiss: () => void;
  onShare: (selectedCollaborators: CollaboratorInterface[], invitedEmails: string[]) => void;
  selectedCollaborators: CollaboratorInterface[];
  invitedEmails: string[];
}) => {
  const { collaborators } = useCollaborators();

  const handleShare = async (selectedCollaborators: CollaboratorInterface[], invitedEmails: string[]) => {
    try {
      onShare(selectedCollaborators, invitedEmails);

      onDismiss();
    } catch (error) {
      notifyAboutError(error);
    }
  };

  return (
    <CollaboratorsModal
      collaborators={collaborators}
      onDismiss={onDismiss}
      onShare={handleShare}
      selectedCollaborators={selectedCollaborators}
      invitedEmails={invitedEmails}
    />
  );
};

export default CreateDumpCollaboratorsModal;

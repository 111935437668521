import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { captureException } from '@sentry/react';
import { getCurrentProfile, upsertProfile } from 'models/profiles/services/pouchDb/PouchDbProfileService';
import { pushUnsyncedProfile } from 'models/profiles/services/ProfileSyncService';

export type IProfile = {
  profile: ProfileInterface | null;
  getProfile: () => Promise<ProfileInterface | null>;
  setProfile: (profile: ProfileInterface | null) => void;
  updateProfile: (data: Partial<ProfileInterface>) => Promise<void>;
};

export const useProfileStore = create<IProfile>((set, get) => ({
  profile: null,

  getProfile: async () => {
    try {
      const gotProfile = await getCurrentProfile();
      if (!gotProfile) {
        return null;
      }

      set((state) => ({
        ...state,
        profile: gotProfile,
      }));

      return gotProfile;
    } catch (e) {
      captureException(e);
      return null;
    }
  },

  setProfile: (profile) =>
    set((state) => ({
      ...state,
      profile,
    })),

  updateProfile: async (profile: Partial<ProfileInterface>) => {
    let currentProfile = get().profile;
    if (!currentProfile || !currentProfile.id) {
      currentProfile = await get().getProfile();
    }
    if (!currentProfile) {
      throw new Error('Profile not found');
    }

    try {
      const updatedProfile = await upsertProfile({ ...currentProfile, ...profile, is_synced: false });

      set((state) => ({
        ...state,
        profile: updatedProfile,
      }));

      pushUnsyncedProfile();
    } catch (e) {
      captureException(e);
    }
  },
}));

mountStoreDevtool('ProfileStore', useProfileStore);

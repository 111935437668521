import { useEffect, useState } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { listenToDumps } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { sortDumps } from 'utilities/dumps/dumps.utils';
import { captureException } from '@sentry/react';

const useDumps = () => {
  const [dumps, setDumps] = useState<DumpInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    const dumpsPromise = listenToDumps(0, 2000);

    // Handle initial load
    dumpsPromise
      .then(async (initialDumps) => {
        if (isMounted) {
          const filteredDumps = initialDumps.filter((dump) => dump.is_to_be_deleted !== true);

          setDumps(sortDumps(filteredDumps));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        captureException(err);
        if (isMounted) {
          setError(err as Error);
          setIsLoading(false);
        }
      });

    // Handle subsequent changes
    dumpsPromise.onChange(async (updatedDumps) => {
      if (isMounted) {
        const filteredDumps = updatedDumps.filter((dump) => dump.is_to_be_deleted !== true);
        setDumps(sortDumps(filteredDumps));
      }
    });

    return () => {
      isMounted = false;
      dumpsPromise.cleanup();
    };
  }, []);

  return {
    dumps,
    isLoading,
    error,
  };
};

export default useDumps;

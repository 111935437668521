import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { DumpInterface } from '../interfaces/DumpInterface';
import {
  getLocalStorageLastSyncedDumps,
  setLocalStorageLastSyncedDumps,
} from 'models/application/services/LocalStorageService';
import { getCurrentAuthenticatedProfileId } from 'models/profiles/services/ProfileService';
import { SupabaseDumpService } from './supabase/SupabaseDumpService';
import { getAllUnsyncedDumps, markDumpSynced, upsertDump } from './pouchDb/PouchDbDumpService';
import { toast } from 'react-toastify';
import { SUPABASE_MAX_PER_PAGE } from 'constants/supabase.constants';

export async function pushUnsyncedDumps(): Promise<boolean> {
  const currentProfileId = await getCurrentAuthenticatedProfileId();
  if (!currentProfileId) {
    return false;
  }

  const unsyncedDumps = await getAllUnsyncedDumps();
  if (unsyncedDumps && unsyncedDumps.length > 0) {
    const supabaseDumpService = new SupabaseDumpService(supabase);
    await Promise.all(
      unsyncedDumps.map(async (dump) => {
        try {
          const asDump = { ...dump, _deleted: dump.is_to_be_deleted };
          delete asDump.is_synced;
          delete asDump.is_to_be_deleted;
          delete asDump.tags;
          delete asDump._id;
          delete asDump._rev;
          delete asDump.sort_date;
          delete asDump.latest_comment_at;
          const result = await supabaseDumpService.upsert(asDump);
          if (!result) {
            return;
          }

          await markDumpSynced(dump.id, {});
        } catch (e) {
          captureMessage(`pushUnsyncedDumps: userId[${currentProfileId}], dumpId[${dump.id}].`);
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestDumps(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentAuthenticatedProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseDumpService = new SupabaseDumpService(supabase);
    let hasMoreDumps = true;
    let newDumpsCount = 0;
    let lastSyncedAt = getLocalStorageLastSyncedDumps();

    while (hasMoreDumps) {
      const dumps = await supabaseDumpService.getAll(lastSyncedAt);
      if (!dumps || dumps.length === 0) {
        break;
      }

      await Promise.all(
        dumps.map(async (_dump) => {
          const dump: DumpInterface = {
            id: _dump.id,
            text: _dump?.text || '',
            user_id: _dump.user_id,
            created_at: _dump.created_at,
            updated_at: _dump.updated_at,
            last_edited_at: _dump.last_edited_at,
            is_synced: true,
            sort_date: _dump.last_edited_at || _dump.created_at,
            is_pinned: _dump.is_pinned,
            _deleted: _dump._deleted,
          };

          const isNewDump = await upsertDump(dump);
          if (isNewDump && !dump._deleted) {
            newDumpsCount++;
          }

          // Only update lastSyncedAt if this dump's updated_at is more recent
          if (!lastSyncedAt || dump.updated_at > lastSyncedAt) {
            lastSyncedAt = dump.updated_at;
            setLocalStorageLastSyncedDumps(dump.updated_at);
          }
        }),
      );

      hasMoreDumps = dumps.length >= SUPABASE_MAX_PER_PAGE;
    }

    if (newDumpsCount > 0) {
      toast.info(`${newDumpsCount} ${newDumpsCount === 1 ? 'Dump' : 'Dumps'} synced`);
    }

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}

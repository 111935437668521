import { IDump } from 'models/dump.model';
import { isDevEnv, isFeatEnv, isLocalhostEnv, isProductionEnv, isStagingEnv } from './env.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';

export const SEARCH_PARAM = 'search';

export function getRootUrl(): string {
  if (isLocalhostEnv()) {
    return `http://localhost:3000`;
  }

  if (isFeatEnv()) {
    return `https://feat.dumpit.app`;
  }

  if (isDevEnv()) {
    return `https://dev.dumpit.app`;
  }

  if (isStagingEnv()) {
    return `https://staging.dumpit.app`;
  }

  return `https://dumpit.app`;
}

export function getUrlTerms(): string {
  return `/terms`;
}

export function getUrlPrivacy(): string {
  return `/privacy`;
}

export function getUrlCookiePolicy(): string {
  return `/cookies`;
}

export function getUrlImprint(): string {
  return `/impressum`;
}

export function getUrlCookies(): string {
  return `/cookies`;
}

export function getUrlHome(): string {
  return `/`;
}

export function getUrlDumps(): string {
  return `/dumps`;
}

export function getUrlResetPassword(): string {
  return `/reset-password`;
}

export function getUrlIOSAppStore(): string {
  return `https://apps.apple.com/app/dump-it/id6448620477`;
}

export function getUrlSharedDump(dumpId: string): string {
  return `/share/${dumpId.toLowerCase()}`;
}

export function getUrlAndroidAppStore(): string {
  return `https://play.google.com/store/apps/details?id=app.dump.it`;
}

export function getUrlLostPassword(email?: string): string {
  return `/lost-password?email=${email}`;
}

export function getUrlDump(dump: IDump | DumpInterface): string {
  return `/dumps/${dump.id}`;
}

export function getMobileApp(path: 'dumps' | 'invitation', params: Record<string, string>) {
  return `app.dumpit://${path}?${new URLSearchParams(params).toString()}`;
}

export function getUrlCollaboratorInvitationApp(collaboratorId: string): string {
  return `app.dumpit://invitation?collaborator_id=${collaboratorId}`;
}

export function getUrlAccount(): string {
  return `/account`;
}

export function getUrlSettings(): string {
  return `/settings`;
}

export function getUrlVerifyEmail(email: string): string {
  return `/verify-email?email=${encodeURIComponent(email.toLowerCase())}`;
}

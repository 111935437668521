import { useState } from 'react';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { useAuthStore } from 'store/auth.store';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { processRemovedAttachments } from 'utilities/dumps/dumps.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import { createDumpAudioAttachment, createDumpGenericAttachment } from 'utilities/dumps/dumpAttachment.utils';
import { pushUnsyncedDumps } from 'models/dumps/services/DumpSyncService';
import { pushUnsyncedAttachments } from 'models/attachments/services/AttachmentSyncService';
import { updateDump } from 'models/dumps/services/pouchDb/PouchDbDumpService';

const useUpdateDump = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const handleUpdateDump = async (
    dump: DumpInterface,
    initialAttachments?: AttachmentInterface[],
    text?: string,
    attachments?: AttachmentInterface[] | IDraftAttachment[],
  ) => {
    if (!currentUser) {
      return;
    }

    setIsBusy(true);

    await updateDump(dump.id, { ...dump, text, is_synced: false }, true);

    await processRemovedAttachments(initialAttachments, attachments);

    const newAttachmentFiles = attachments?.filter((attachment) => 'file' in attachment) as IDraftAttachment[];
    if (newAttachmentFiles && newAttachmentFiles.length > 0) {
      await Promise.all(
        newAttachmentFiles?.map(async (newAttachmentFile: IDraftAttachment) => {
          if (isFilePathImage(newAttachmentFile.file.name)) {
            await createDumpGenericAttachment(dump.id, newAttachmentFile.file, currentUser.id);
          } else if (isFilePathAudio(newAttachmentFile.file.name)) {
            await createDumpAudioAttachment(dump.id, newAttachmentFile.file, currentUser.id);
          } else if (isFilePathGeneric(newAttachmentFile.file.name)) {
            await createDumpGenericAttachment(dump.id, newAttachmentFile.file, currentUser.id);
          }
        }),
      );
    }

    pushUnsyncedDumps();
    pushUnsyncedAttachments();

    trackEvent(TRACKING_EVENT_ENUM.DUMP_UPDATED);

    setIsBusy(false);
  };

  return {
    updateDump: handleUpdateDump,
    isBusy,
  };
};

export default useUpdateDump;

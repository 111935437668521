import React from 'react';
import { Text } from 'components/common/ComponentLibrary';
import { getEnvironmentVariable, isProductionEnv } from 'utilities/env.utils';

const CommitDisplay = () => {
  const commitHash = process.env.REACT_APP_COMMIT_SHA;

  if (!commitHash || isProductionEnv()) {
    return null;
  }

  return (
    <Text color='gray-500' size='s'>
      {getEnvironmentVariable('ENV')}/{commitHash.slice(0, 7)}
    </Text>
  );
};

export default CommitDisplay;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useProfileStore } from 'store/profile.store';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import Spinner from 'components/common/Spinner';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import CommentItem from './CommentItem';
import { format, isEqual, startOfDay } from 'date-fns';
import { pushUnsyncedComments } from 'models/comments/services/CommentSyncService';
import CommentReply from './CommentReply';
import { deleteByDumpId, getByDumpId } from 'models/comments/services/pouchDb/PouchDbCommentDraftService';
import { createComment } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { useCommentsSidebar } from './CommentsContext';

const Comments = ({ dump }: { dump: DumpInterface }) => {
  const { comments, isLoading } = useCommentsSidebar();

  const [replyTo, setReplyTo] = useState<string | null>();
  const [newComment, setNewComment] = useState('');
  const profile = useProfileStore((state) => state.profile);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  // Add this ref to track comment elements
  const commentRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  // Add this function to scroll to comment
  const scrollToComment = useCallback((commentId: string) => {
    const commentEl = commentRefs.current[commentId];
    if (commentEl) {
      commentEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      // Trigger highlight animation
      commentEl.classList.remove('animate-highlight');
      void commentEl.offsetWidth; // Force reflow
      commentEl.classList.add('animate-highlight');
    }
  }, []);

  // Add useEffect to load draft comment
  useEffect(() => {
    const loadDraft = async () => {
      try {
        const draftText = await getByDumpId(dump.id);
        if (draftText) {
          setNewComment(draftText);
        }
      } catch (error) {
        // Do nothing
      }
    };
    loadDraft();
  }, [dump.id]);

  useEffect(() => {
    if (replyTo) {
      textareaRef.current?.focus();
    }
  }, [replyTo]);

  if (comments.length === 0 && isLoading) {
    return (
      <div className='flex items-center justify-center h-full -mt-10'>
        <Spinner size='xl' />
      </div>
    );
  }

  const handleReply = (commentId: string) => {
    setReplyTo(commentId);
  };

  const handleSendComment = async () => {
    setNewComment('');
    setReplyTo(null);

    try {
      await createComment(newComment, dump.id, profile!.id, replyTo || undefined);

      await pushUnsyncedComments();
      await deleteByDumpId(dump.id);
    } catch (err) {
      notifyAboutError(err, true, 'Your last comment could not be sent');
    }
  };

  const renderCommentsWithDividers = () => {
    let currentDate: Date | null = null;

    return comments.map((comment) => {
      const commentDate = startOfDay(new Date(comment.created_at));
      const showDivider = !currentDate || !isEqual(currentDate, commentDate);
      const replyToComment = comment.comment_ref ? comments.find((c) => c.id === comment.comment_ref) : undefined;

      const commentElement = (
        <CommentItem
          key={comment.id}
          comment={comment}
          currentProfile={profile!}
          onReply={() => handleReply(comment.id)}
          replyToComment={replyToComment}
          ref={(el) => {
            if (el) commentRefs.current[comment.id] = el;
          }}
          onReplyClick={scrollToComment}
        />
      );

      if (showDivider) {
        currentDate = commentDate;
        return (
          <React.Fragment key={`${comment.id}-group`}>
            <div className='sticky top-0 bg-bright dark:bg-paperdark z-10'>
              <div className='text-center text-dark/25 dark:text-bright/25'>{format(commentDate, 'EEE dd. MMM')}</div>
            </div>
            {commentElement}
          </React.Fragment>
        );
      }

      return commentElement;
    });
  };

  return (
    <div className='flex flex-col max-h-[calc(100%-60px)]'>
      <CommentReply
        newComment={newComment}
        setNewComment={setNewComment}
        replyToComment={comments.find((c) => c.id === replyTo)!}
        setReplyTo={setReplyTo}
        onSubmitComment={handleSendComment}
        dumpId={dump.id}
      />

      <div className='flex flex-col gap-4 flex-1 overflow-y-auto dumpit-scrollbar pr-6 pb-10'>
        {renderCommentsWithDividers()}
      </div>
    </div>
  );
};

export default Comments;

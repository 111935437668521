import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMobileApp, getUrlDump, getUrlHome } from 'utilities/urls.utils';
import PageNotFound from 'screens/not-found/PageNotFoundScreen';
import { isMobile } from 'react-device-detect';
import { Hyperlink, Text, Heading } from 'components/common/ComponentLibrary';
import LoadingIndicator from 'components/common/LoadingIndicator';
import Footer from 'components/application/Footer';
import LoadingScreenIndicator from 'components/common/LoadingScreenIndicator';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getDumpById } from 'models/dumps/services/pouchDb/PouchDbDumpService';

const CollaboratorInvitationRedirect = () => {
  const navigate = useNavigate();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [dump, setDump] = useState<DumpInterface | null>(null);

  const { dumpId } = useParams();
  if (!dumpId) {
    navigate(getUrlHome());
    return;
  }

  useEffect(() => {
    const fetchDumpWithRetry = async () => {
      const delays = [1500, 2500]; // Delays in milliseconds between retries
      let attempt = 0;

      while (attempt < 3) {
        const result = await getDumpById(dumpId);
        if (result) {
          setDump(result);
          setIsReady(true);
          return;
        }

        if (attempt < 2) {
          await new Promise((resolve) => setTimeout(resolve, delays[attempt]));
        }

        attempt++;
      }

      setIsReady(true);
    };

    fetchDumpWithRetry();
  }, [dumpId]);

  useEffect(() => {
    if (isReady) {
      if (isMobile && dump) {
        window.location.replace(getMobileApp('invitation', { id: dump.id }));
      } else if (dump) {
        navigate(getUrlDump(dump));
      }
    }
  }, [isMobile, isReady, dump]);

  if (!dump && !isReady) {
    return <LoadingScreenIndicator />;
  }

  if (!dump && isReady) {
    return <PageNotFound dumpId={dumpId} />;
  }

  if (isMobile && dump) {
    return (
      <div className='m-auto flex min-h-screen flex-col'>
        <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32' />
        <div className='m-auto px-4 md:px-0 flex-1'>
          <div className='flex flex-col justify-center items-center'>
            <LoadingIndicator />
            <Heading level={0} textAlign='center' size='xxl' spacing='m'>
              Redirecting
            </Heading>

            <Text textAlign='center' size='m'>
              Click{' '}
              <Hyperlink href={getMobileApp('dumps', { id: dump.id })} theme='decorated' color='default'>
                here
              </Hyperlink>{' '}
              if you're not being redirected.
            </Text>
          </div>
        </div>
        <footer className='m-auto w-full max-w-7xl'>
          <Footer />
        </footer>
      </div>
    );
  }

  return null;
};

export default CollaboratorInvitationRedirect;

import React, { useEffect, useState } from 'react';
import { mixpanelInit } from 'models/application/services/TrackingService';
import useIOSVhFix from 'hooks/application/useIOSVhFix';
import App from 'components/application/App';
import LoadingScreenIndicator from 'components/common/LoadingScreenIndicator';
import useInitSession from 'hooks/application/useInitSession';
import {
  getLocalStorageIsFirstSyncDone,
  getLocalStorageLastSyncedDumps,
} from 'models/application/services/LocalStorageService';
import FirstSync from 'components/common/FirstSync';

const AppContainer = () => {
  useIOSVhFix();

  useEffect(() => {
    mixpanelInit();
  }, []);

  const [isFirstSyncDone, setIsFirstSyncDone] = useState(
    getLocalStorageIsFirstSyncDone() || getLocalStorageLastSyncedDumps() !== undefined,
  );

  const { isInitialising } = useInitSession();
  if (isInitialising) {
    return <LoadingScreenIndicator />;
  }

  if (!isFirstSyncDone) {
    return <FirstSync onDone={() => setIsFirstSyncDone(true)} />;
  }

  return <App />;
};

export default AppContainer;

import supabase from 'store/supabase/supabase';
import { Session } from '@supabase/supabase-js';
import { mixpanelReset, trackEvent } from './TrackingService';
import { TRACKING_EVENT_ENUM } from '../enums/trackingEventEnum';
import { clearLocalStorageItems } from './LocalStorageService';
import { captureException } from '@sentry/react';
import { clearAttachmentsDB } from 'models/attachments/services/pouchDb/PouchDbAttachmentService';
import { clearCommentsDB } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { clearTagsDB } from 'models/tags/services/pouchDb/PouchDbTagService';
import { clearDumpsDB } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { clearCollaboratorsDB } from 'models/collaborators/services/pouchDb/PouchDbCollaboratorService';
import { clearDumpCollaboratorsDB } from 'models/collaborators/services/pouchDb/PouchDbDumpCollaboratorService';
import { clearProfilesDB } from 'models/profiles/services/pouchDb/PouchDbProfileService';
import { clearSettingsDB } from 'models/settings/services/pouchDb/PouchDbSettingService';
import { clearUserProfilesDB } from 'models/collaborators/services/pouchDb/PouchDbUserProfileService';

export async function getSession(): Promise<Session | null> {
  const {
    data: { session: authSession },
  } = await supabase.auth.getSession();

  return authSession;
}

export async function logout(): Promise<void> {
  try {
    // First clear all PouchDB databases
    // Wait for all clear operations to complete or fail
    await Promise.allSettled([
      clearDumpsDB(),
      clearAttachmentsDB(),
      clearCommentsDB(),
      clearTagsDB(),
      clearCollaboratorsDB(),
      clearDumpCollaboratorsDB(),
      clearProfilesDB(),
      clearSettingsDB(),
      clearUserProfilesDB(),
    ]);

    // Then sign out from Supabase
    await supabase.auth.signOut();
  } catch (error) {
    captureException(error);
  } finally {
    // Clear Supabase-related localStorage items
    for (const key of Object.keys(localStorage)) {
      if (key.startsWith('sb-') || key.includes('supabase')) {
        localStorage.removeItem(key);
      }
    }

    // Clear other localStorage items and reset tracking
    clearLocalStorageItems();
    trackEvent(TRACKING_EVENT_ENUM.SIGN_OUT);
    mixpanelReset();
  }
}

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrlDumps } from 'utilities/urls.utils';
import SharedDumpDeviceChoice from 'components/dumps/SharedDump/SharedDumpDeviceChoice';
import SharedDumpRedirect from 'components/dumps/SharedDump/SharedDumpRedirect';
import { isMobile } from 'react-device-detect';
import { useAuthStore } from 'store/auth.store';
import SharedDumpLogin from 'components/dumps/SharedDump/SharedDumpLogin';
import GeneralPageContainer from 'components/common/GeneralPageContainer';

const DumpShareScreen = () => {
  const navigate = useNavigate();

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const [shouldViewInBrowser, setShouldViewInBrowser] = useState<boolean>(!isMobile);

  const { dumpId } = useParams();
  if (!dumpId) {
    navigate(getUrlDumps());
    return;
  }

  if (shouldViewInBrowser && !currentUser) {
    return (
      <GeneralPageContainer>
        <SharedDumpLogin onLoggedIn={() => window.location.reload()} />
      </GeneralPageContainer>
    );
  }

  if (shouldViewInBrowser) {
    return <SharedDumpRedirect />;
  }

  return (
    <GeneralPageContainer>
      <SharedDumpDeviceChoice onContinue={() => setShouldViewInBrowser(true)} />
    </GeneralPageContainer>
  );
};

export default DumpShareScreen;

import React, { useEffect, useRef, useState } from 'react';
import { useSearchStore } from 'store/search.store';
import { useDraggable } from 'react-use-draggable-scroll';
import Tag from 'components/dumps/DumpsFilter/Tag';
import { OWNERSHIP_FILTER, TAG_STATE } from 'constants/tag.constants';
import useTags from 'hooks/tags/useTags';
import { UserProfileInterface } from 'models/collaborators/interfaces/UserProfileInterface';
import { getSharedDumpUserProfiles } from 'utilities/dumps/dumps.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getInitials } from 'utilities/common/strings.utils';
import { Avatar } from 'components/common/ComponentLibrary';
import { getPublicUrl } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { Color } from 'utilities/common/colors.utils';

const DumpFilterList = ({ dumps, ownershipFilter }: { dumps: DumpInterface[]; ownershipFilter: OWNERSHIP_FILTER }) => {
  const { tags } = useTags();

  const [sharedDumpUserProfiles, setSharedDumpUserProfiles] = useState<UserProfileInterface[]>([]);

  const [tagsStore, toggleTag, users, toggleUser, setUsers, setTags] = useSearchStore((state) => [
    state.tags,
    state.toggleTag,
    state.users,
    state.toggleUser,
    state.setUsers,
    state.setTags,
  ]);

  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = await getSharedDumpUserProfiles(dumps);
      setSharedDumpUserProfiles(profiles);
      setUsers(profiles.map((profile) => profile.user_id));
    };
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (tags && Object.keys(tags).length > 0) {
      setTags(tags);
    }
  }, [tags]);

  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable(ref);

  const handleTagClick = (tag: string) => {
    toggleTag(tag);
  };

  return (
    <div
      className='max-w-full flex pb-3 select-none overflow-y-hidden overflow-x-auto dumpit-scrollbar gap-x-2 text-base text-dark dark:text-bright'
      {...events}
      ref={ref}
    >
      <div className='flex gap-2'>
        {ownershipFilter === OWNERSHIP_FILTER.SHARED &&
          users &&
          Object.keys(users).length > 1 &&
          sharedDumpUserProfiles.map((profile) => (
            <div key={profile.user_id}>
              <Tag onClick={() => toggleUser(profile.user_id)} state={users[profile.user_id] || TAG_STATE.DEFAULT}>
                <span className='flex gap-2 items-center'>
                  <Avatar
                    src={profile.image_path ? getPublicUrl(profile.image_path, STORAGE_BUCKET_ENUM.AVATARS) : null}
                    alt={profile.first_name || 'Dumper'}
                    size='xs'
                    initials={
                      profile.first_name && profile.last_name
                        ? getInitials(`${profile.first_name} ${profile.last_name}`)
                        : undefined
                    }
                    backgroundColor={Color.flatModernColorFrom(profile.user_id || '')}
                  />
                  <span className='whitespace-nowrap'>
                    {profile.first_name} {profile.last_name}
                  </span>
                </span>
              </Tag>
            </div>
          ))}
      </div>
      {Object.entries(tagsStore).map((tag) => (
        <Tag key={tag[0]} onClick={() => handleTagClick(tag[0])} state={tag[1]}>
          #{tag[0]}
        </Tag>
      ))}
    </div>
  );
};

export default DumpFilterList;

import React, { useEffect, useState } from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Heading, Button, Text } from 'components/common/ComponentLibrary';
import CollaboratorsList from './CollaboratorsList';
import { CollaboratorInterface } from 'models/collaborators/interfaces/CollaboratorInterface';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { useProfileStore } from 'store/profile.store';
import ProfileNameForm from 'components/profiles/ProfileName/ProfileNameForm';
import { UserPlusIcon } from '@heroicons/react/24/solid';
import CollaboratorsManageListPreview from './CollaboratorsManageListPreview';
import CollaboratorsManageList from './CollaboratorsManageList';

const getButtonStatus = (hasChanges: boolean, isBusy: boolean) => {
  if (isBusy) {
    return 'busy';
  }

  if (hasChanges) {
    return '';
  }

  return 'disabled';
};

const MODE_SHARE = 'share';
const MODE_MANAGE = 'manage';

const CollaboratorsModal = ({
  collaborators,
  selectedCollaborators: upstreamSelectedCollaborators,
  invitedEmails: upstreamInvitedEmails,
  onDismiss,
  onShare,
}: {
  collaborators: CollaboratorInterface[];
  selectedCollaborators: CollaboratorInterface[];
  invitedEmails: string[];
  onDismiss: () => void;
  onShare: (selectedCollaborators: CollaboratorInterface[], invitedEmails: string[]) => void;
}) => {
  const profile = useProfileStore((state) => state.profile);

  const [isProfileComplete, setIsProfileComplete] = useState(!!profile?.first_name && !!profile?.last_name);
  const [isBusy, setIsBusy] = useState(false);
  const [mode, setMode] = useState(MODE_SHARE);
  const [initialInvitedEmails] = useState<string[]>([]);
  const [selectedCollaborators, setSelectedCollaborators] =
    useState<CollaboratorInterface[]>(upstreamSelectedCollaborators);
  const [invitedEmails, setInvitedEmails] = useState<string[]>(upstreamInvitedEmails);

  useEffect(() => {
    setSelectedCollaborators(upstreamSelectedCollaborators);
    setInvitedEmails(upstreamInvitedEmails);
  }, [upstreamSelectedCollaborators, upstreamInvitedEmails]);

  const hasChanges = React.useMemo(() => {
    const selectedCollaboratorsChanged =
      selectedCollaborators.length !== upstreamSelectedCollaborators.length ||
      selectedCollaborators.some(
        (selected) => !upstreamSelectedCollaborators.find((initial) => initial.id === selected.id),
      );

    const invitedEmailsChanged =
      invitedEmails.length !== initialInvitedEmails.length ||
      invitedEmails.some((email) => !initialInvitedEmails.includes(email));

    return selectedCollaboratorsChanged || invitedEmailsChanged;
  }, [selectedCollaborators, upstreamSelectedCollaborators, invitedEmails, initialInvitedEmails]);

  const handleToggleCollaborator = (collaborator: CollaboratorInterface) => {
    setSelectedCollaborators((prev) => {
      const isSelected = prev.some((c) => c.id === collaborator.id);
      if (isSelected) {
        return prev.filter((c) => c.id !== collaborator.id);
      }
      return [...prev, collaborator];
    });
  };

  const handleRemoveInvitedEmail = (emailToRemove: string) => {
    setInvitedEmails((prev) => prev.filter((email) => email !== emailToRemove));
  };

  const handleInviteEmail = (email: string) => {
    if (!invitedEmails.includes(email)) {
      setInvitedEmails((prev) => [...prev, email]);
    }
  };

  const handleShare = async () => {
    try {
      setIsBusy(true);

      await onShare(selectedCollaborators, invitedEmails);
    } catch (error) {
      notifyAboutError(error);
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6 w-[500px]'>
        {!isProfileComplete ? (
          <div>
            <Heading size='l' textAlign='left' spacing='l'>
              Complete Your Profile to Collaborate
            </Heading>
            <ProfileNameForm onChanged={() => setIsProfileComplete(true)} onCancel={onDismiss} />
          </div>
        ) : (
          <>
            {mode === MODE_SHARE ? (
              <>
                <div className='flex w-full items-center justify-between h-10'>
                  <div className='flex items-center gap-4'>
                    <UserPlusIcon className='w-8' />
                    <Heading size='xl' textAlign='left'>
                      Share
                    </Heading>
                  </div>
                  {hasChanges && (
                    <div className='flex items-center justify-between gap-4'>
                      <Button onClick={onDismiss} theme='bare' size='s'>
                        Cancel
                      </Button>
                      <Button size='s' status={getButtonStatus(hasChanges, isBusy)} onClick={handleShare}>
                        Save
                      </Button>
                    </div>
                  )}
                </div>
                <Text color='gray-400' size='s'>
                  People you share this Dump with will only have view access.
                </Text>

                <div className='mb-7 mt-4'>
                  <CollaboratorsList
                    collaborators={collaborators}
                    selectedCollaborators={selectedCollaborators}
                    onToggleCollaborator={handleToggleCollaborator}
                    onInvite={handleInviteEmail}
                  />
                </div>

                <div className='flex cursor-pointer' onClick={() => setMode(MODE_MANAGE)}>
                  <CollaboratorsManageListPreview
                    selectedCollaborators={selectedCollaborators}
                    invitedEmails={invitedEmails}
                  />
                </div>
              </>
            ) : (
              <CollaboratorsManageList
                selectedCollaborators={selectedCollaborators}
                invitedEmails={invitedEmails}
                onBack={() => setMode(MODE_SHARE)}
                onToggleCollaborator={handleToggleCollaborator}
                onRemoveInvitedEmail={handleRemoveInvitedEmail}
              />
            )}
          </>
        )}
      </div>
    </BackdropComponent>
  );
};

export default CollaboratorsModal;

import { captureException } from '@sentry/react';
import { SUPABASE_FUNCTION_GET_DUMP_COLLABORATORS_WITH_PROFILES } from 'constants/application.constants';
import supabase from 'store/supabase/supabase';
import { DumpCollaboratorWithProfileInterface } from '../../interfaces/DumpCollaboratorWithProfileInterface';

export const getDumpCollaboratorsWithProfiles = async (
  updatedAt?: string,
): Promise<DumpCollaboratorWithProfileInterface[]> => {
  const { data, error } = await supabase.rpc(SUPABASE_FUNCTION_GET_DUMP_COLLABORATORS_WITH_PROFILES, {
    modified_after: updatedAt,
  });

  if (error) {
    captureException(new Error(`RPC Error: ${error.message}`), {
      extra: {
        functionName: 'getDumpCollaboratorsWithProfiles',
        rpcFunction: SUPABASE_FUNCTION_GET_DUMP_COLLABORATORS_WITH_PROFILES,
        modifiedAfter: updatedAt,
        supabaseError: error,
      },
    });
    return [];
  }

  return data;
};

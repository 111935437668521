import { ArrowUturnRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Menu, Text } from 'components/common/ComponentLibrary';
import { CommentInterface } from 'models/comments/interfaces/CommentInterface';
import { differenceInMinutes } from 'date-fns';
import { pushUnsyncedComments } from 'models/comments/services/CommentSyncService';
import { deleteComment } from 'models/comments/services/pouchDb/PouchDbCommentService';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';

const CommentContextMenu = ({
  comment,
  isOwnComment,
  onEdit,
  onDelete,
  onReply,
}: {
  comment: CommentInterface;
  isOwnComment: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onReply: (commentId: string) => void;
}) => {
  const canEdit =
    isOwnComment && differenceInMinutes(new Date(getCurrentUTCDateFormatted()), new Date(comment.updated_at)) < 5;

  const handleDelete = async () => {
    onDelete();
    await deleteComment(comment.id);
    pushUnsyncedComments();
  };

  return (
    <div className='flex fixed z-2 bg-bright shadow-xl text-dark dark:text-white dark:bg-paperdark rounded-xl border-1 border-dark/25 dark:border-white/25 overflow-hidden'>
      <Menu>
        <Menu.Item onClick={() => onReply(comment.id)} icon={<ArrowUturnRightIcon className='h-5' />}>
          <Text color='inherit'>Reply</Text>
        </Menu.Item>
        {canEdit && (
          <Menu.Item onClick={onEdit} icon={<PencilIcon className='h-5' />}>
            <Text color='inherit'>Edit</Text>
          </Menu.Item>
        )}
        {isOwnComment && (
          <Menu.Item onClick={handleDelete} icon={<TrashIcon className='h-5' />}>
            <Text color='inherit'>Delete</Text>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default CommentContextMenu;

import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EmptyDumpsList from 'components/dumps/DumpList/EmptyDumpsList';
import DumpList from 'components/dumps/DumpList/DumpList';
import NoDumpSearchMatches from 'components/dumps/NoDumpSearchMatches';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import { isSameDay } from 'date-fns';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button, ContentLayout, Text } from 'components/common/ComponentLibrary';
import { SEARCH_PARAM, getUrlHome, getUrlSettings, getUrlDumps } from 'utilities/urls.utils';
import { ChevronLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import DumpsFilter from 'components/dumps/DumpsFilter/DumpsFilter';
import { filterDumpsBySearchQuery, filterDumpsByTags, filterDumpsByUsers } from 'utilities/dumps/dumpFilters.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { useSearchStore } from 'store/search.store';
import { useTranslation } from 'react-i18next';
import { OWNERSHIP_FILTER } from 'constants/tag.constants';
import { useAuthStore } from 'store/auth.store';
import { getTotalDumpsCount } from 'models/dumps/services/pouchDb/PouchDbDumpService';
import { useDumpsContext } from 'components/dumps/DumpsContext';
import './DumpListScreen.css';

const ITEMS_PER_PAGE = 25;

const DumpListScreen = () => {
  useDocumentTitle('Dumps');

  const { dumps, isLoading } = useDumpsContext();

  const { t } = useTranslation('translations');

  const searchBarRef = useRef<HTMLInputElement>(null);
  const [currentUser] = useAuthStore((state) => [state.currentUser]);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  useHotkeys(
    'left',
    (event) => {
      event.preventDefault();
      navigate(getUrlHome(), { state: { previousRoute: getUrlDumps() } });
    },
    [],
  );
  useHotkeys('right', () => navigate(getUrlSettings(), { state: { previousRoute: getUrlDumps() } }), []);
  useHotkeys('meta+k', () => searchBarRef.current?.focus(), []);

  const [sectionedDumps, setSectionedDumps] = useState<{ [section: string]: DumpInterface[] }>({});
  const [visibleDumps, setVisibleDumps] = useState<DumpInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const { tags, users, ownershipFilter } = useSearchStore();
  const sections = (index: string) => {
    if (searchTerm.length > 0 && index === '0') return searchTerm.startsWith('#') ? searchTerm : 'top hits';

    // For month-based sections (index starts with 'm_')
    if (index.startsWith('m_')) {
      const monthKey = index.substring(2).toLowerCase(); // Remove 'm_' prefix and convert to lowercase
      return t(`screens.list.months.${monthKey}`);
    }

    // For year-based sections (index starts with 'y_')
    if (index.startsWith('y_')) {
      const year = index.substring(2); // Remove 'y_' prefix
      return year;
    }

    switch (index) {
      case '0':
        return t('screens.list.pinned');
      case '1':
        return t('screens.list.today');
      case '2':
        return t('screens.list.last7Days');
      case '3':
        return t('screens.list.thisMonth');
      default:
        return t('screens.list.earlier');
    }
  };

  const filterDumpsByOwnership = (dump: DumpInterface) => {
    switch (ownershipFilter) {
      case OWNERSHIP_FILTER.YOURS:
        return dump.user_id === currentUser?.id;
      case OWNERSHIP_FILTER.SHARED:
        return dump.user_id !== currentUser?.id;
      default:
        return true;
    }
  };

  // Filter and process all dumps
  const processAllDumps = useCallback(() => {
    const result: { [section: string]: DumpInterface[] } = {};
    const allFilteredDumps: DumpInterface[] = [];

    const enrichAndSortDumps = async () => {
      const enrichedDumps = await Promise.all(
        dumps
          .filter(filterDumpsByTags(tags))
          .filter(filterDumpsByUsers(users, ownershipFilter))
          .filter(filterDumpsBySearchQuery(searchTerm))
          .filter(filterDumpsByOwnership),
      );

      enrichedDumps.forEach((dump) => {
        allFilteredDumps.push(dump);

        const mostRecentDate = new Date(dump.sort_date || dump.last_edited_at || dump.created_at);
        const today = new Date();
        const daysDiff = Math.floor((today.getTime() - mostRecentDate.getTime()) / (1000 * 60 * 60 * 24));
        const currentMonth = today.getMonth();
        const dumpMonth = mostRecentDate.getMonth();
        const currentYear = today.getFullYear();
        const dumpYear = mostRecentDate.getFullYear();

        if (dump.is_pinned && dump.user_id === currentUser?.id) {
          result['0'] = [...(result['0'] ?? []), dump];
        } else if (isSameDay(today, mostRecentDate)) {
          result['1'] = [...(result['1'] ?? []), dump];
        } else if (daysDiff <= 7) {
          result['2'] = [...(result['2'] ?? []), dump];
        } else if (dumpYear === currentYear && dumpMonth === currentMonth) {
          result['3'] = [...(result['3'] ?? []), dump];
        } else if (dumpYear === currentYear) {
          // Group by month for current year
          const monthNames = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
          ];
          const monthKey = `m_${monthNames[dumpMonth]}`;
          result[monthKey] = [...(result[monthKey] ?? []), dump];
        } else {
          // Group by year for previous years
          const yearKey = `y_${dumpYear}`;
          result[yearKey] = [...(result[yearKey] ?? []), dump];
        }
      });

      setSectionedDumps(result);

      // Set initial visible dumps
      const initialVisibleDumps = allFilteredDumps.slice(0, ITEMS_PER_PAGE);
      setVisibleDumps(initialVisibleDumps);
      setHasMore(allFilteredDumps.length > ITEMS_PER_PAGE);
      setPage(1);
    };

    enrichAndSortDumps();
  }, [searchTerm, tags, users, dumps, ownershipFilter, currentUser?.id]);

  // Load more dumps when user reaches the bottom
  const loadMoreDumps = useCallback(() => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);

    // Simulate loading delay
    setTimeout(() => {
      const filteredDumps = dumps
        .filter(filterDumpsByTags(tags))
        .filter(filterDumpsByUsers(users, ownershipFilter))
        .filter(filterDumpsBySearchQuery(searchTerm))
        .filter(filterDumpsByOwnership);

      const nextPage = page + 1;
      const startIndex = (nextPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;

      const newDumps = filteredDumps.slice(startIndex, endIndex);

      if (newDumps.length > 0) {
        setVisibleDumps((prev) => [...prev, ...newDumps]);
        setPage(nextPage);
        setHasMore(endIndex < filteredDumps.length);
      } else {
        setHasMore(false);
      }

      setIsLoadingMore(false);
    }, 500);
  }, [page, hasMore, isLoadingMore, dumps, tags, users, searchTerm, ownershipFilter, currentUser?.id]);

  // Set up intersection observer
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          loadMoreDumps();
        }
      },
      { threshold: 0.1 },
    );

    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [loadMoreDumps, hasMore, isLoadingMore]);

  // Process dumps when filters change
  useEffect(() => {
    processAllDumps();
  }, [processAllDumps]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchTerm = (query: string) => {
    setSearchTerm(query);
  };

  const debouncedResults = useMemo(() => debounce(handleSearchTerm, 300), []);

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAM)) {
      setSearchTerm(searchParams.get(SEARCH_PARAM)!);
    }

    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length > 0) {
      setSearchParams({ [SEARCH_PARAM]: searchTerm });
    } else {
      searchParams.delete(SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  }, [searchTerm, searchParams]);

  const location = useLocation();
  const isBackwards =
    location.state?.previousRoute === getUrlSettings() || location.state?.previousRoute === 'dump_detail';

  const [dumpsCount, setDumpsCount] = useState<number>(0);

  useEffect(() => {
    const fetchDumpsCount = async () => {
      const count = await getTotalDumpsCount();
      setDumpsCount(count);
    };
    fetchDumpsCount();
  }, [dumps]);

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout hasHScreen={Object.keys(sectionedDumps).length === 0}>
        <ContentLayout.Top>
          <div className='flex items-center justify-between'>
            <Button size='l' theme='bare' width='none' href={getUrlHome()}>
              <Button.Icon icon={<ChevronLeftIcon className='h-8' />} />
            </Button>
            <Text color='gray-500'>{dumpsCount} Dumps</Text>
            <Button width='none' size='l' theme='bare' href={getUrlSettings()}>
              <Button.Icon icon={<Cog6ToothIcon className='h-8' />} />
            </Button>
          </div>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='flex flex-col w-full h-full'>
            {Object.keys(dumps).length > 0 && (
              <div className='w-full'>
                <DumpsFilter
                  searchBarRef={searchBarRef}
                  onSearch={handleSearchTerm}
                  searchTerm={searchTerm}
                  dumps={dumps}
                />
              </div>
            )}
            {!isLoading &&
              Object.keys(sectionedDumps).length === 0 &&
              ((searchTerm.length === 0 && <EmptyDumpsList currentOwnershipFilter={ownershipFilter} />) || (
                <NoDumpSearchMatches searchTerm={searchTerm} />
              ))}

            {Object.keys(sectionedDumps).map((section) => {
              // Filter the sectioned dumps to only show visible dumps
              const visibleSectionDumps = sectionedDumps[section].filter((dump) =>
                visibleDumps.some((visibleDump) => visibleDump.id === dump.id),
              );

              if (visibleSectionDumps.length === 0) return null;

              return (
                <div key={section} className='mb-10'>
                  <DumpList title={sections(section)} dumps={visibleSectionDumps} searchTerm={searchTerm} />
                </div>
              );
            })}

            {/* Loading indicator for initial load */}
            {isLoading && (
              <div className='h-10 flex items-center justify-center'>
                <LoadingIndicator className='w-20' />
              </div>
            )}

            {/* Load more trigger */}
            {hasMore && (
              <div ref={loadMoreRef} className='h-20 flex items-center justify-center'>
                {isLoadingMore ? <LoadingIndicator className='w-10' /> : <div className='h-10'></div>}
              </div>
            )}
          </div>
        </ContentLayout.Content>
      </ContentLayout>
    </motion.div>
  );
};

export default DumpListScreen;
